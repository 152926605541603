const hex2ascii = require('hex2ascii');
const cptable = require('codepage');
const tcLib = require('../../lib/timecode.js');
const convertToPlainTextCustom = require("../quill/convertToPlainTextCustom.js");

module.exports = {
    codePageMapping: {
        "United States" : 437,
        "Multilingual": 850,
        "Portuguese": 860,
        "Canada-French": 863,
        "Nordic": 865,
        "Latin": 20269,
        "Cyrillic": 28595,
        "Arabic": 28596,
        "Greek": 28597,
        "Hebrew": 28598
    },
    charCodeTblMap: {
        "3030": "Latin",
        "3031": "Cyrillic",
        "3032": "Arabic",
        "3033": "Greek",
        "3034": "Hebrew",
        "Latin": "3030",
        "Cyrillic": "3031",
        "Arabic": "3032",
        "Greek": "3033",
        "Hebrew": "3034",
        "United States" : "343337",
        "Multilingual": "383530",
        "Portuguese": "383630",
        "Canada-French": "383633",
        "Nordic": "383635",
    },
    frameRateMapping: {
        "25"    : "53544c32352e3031",
        "29.97" : "53544c33302e3031",
        "53544c32352e3031": 25,
        "53544c33302e3031": 29.97
    },
    dscMapping : {
        "Undefined" : "20",
        "Open subtitling" : "30",
        "Level-1 teletext" : "31",
        "Level-2 teletext" : "32"
    },
    controlCodeMapping: {
        "80": "<i>",
        "81": "</i>",
        "82": "<u>",
        "83": "</u>",
        "84": "<b>",
        "85": "</b>"
    },
    textJustificationMapping: {
        "00": "start",
        "01": "start",
        "02": "center",
        "03": "end"
    },
    textJustificationReverseMapping: {
        "start": "01",
        "center": "02",
        "end": "02"
    },
    cntrlCodes : {
        "00" : "#000000", //black
        "01" : "#FF0000", //red
        "02" : "#008000", //green
        "03" : "#FFFF00", //yellow
        "04" : "#0000FF", //blue
        "05" : "#FF00FF", //megenta
        "06" : "#00FFFF", //cyan
        "07" : "#FFFFFF"  //white
    },
    // Decode from Hex
    decodeChar: function (charCode, codePage) {
        return cptable[codePage].dec[parseInt(charCode, 16)]; 
    },
    // Encode to Hex
    encodeChar: function (char, codePage) {
        try {            
            let encodedChar = cptable[codePage].enc[char];
            return encodedChar.toString(16);
        } catch(e){            
            let encodedChar = cptable[codePage].enc["#"];
            return encodedChar.toString(16);
        }       
        
    },
    encodeText : function(text, codePage){
        if (text.length === 0){return ""}
        let encodedText = "", self = this;
        text = convertToPlainTextCustom(text);
        text.split("\n").forEach((textLine,index) => {
            index > 0 ? encodedText += "8a" : null;
            let characters = textLine.split("");
            while (characters.length > 0){
                let char = characters.shift();
                if (char === "<"){
                    if (characters.slice(0, 8).join("") === "/strong>"){
                        encodedText += "85";
                        characters.splice(0, 8);
                    } else if  (characters.slice(0, 4).join("") === "/em>"){
                        encodedText += "81";
                        characters.splice(0, 4);
                    } else if  (characters.slice(0, 3).join("") === "/u>"){
                        encodedText += "83";
                        characters.splice(0, 3);
                    } else if (characters.slice(0, 7).join("") === "strong>"){
                        encodedText += "84";
                        characters.splice(0, 7);
                    } else if  (characters.slice(0, 3).join("") === "em>"){
                        encodedText += "80";
                        characters.splice(0, 3);
                    } else if  (characters.slice(0, 2).join("") === "u>"){
                        encodedText += "82";
                        characters.splice(0, 2);
                    } else {
                        /* treat it as a normal character I guess */
                        encodedText += self.encodeChar(char, codePage);
                    }
                } else if (this.accentReverseMapping[char]){
                    encodedText += this.accentReverseMapping[char];
                } else {
                    encodedText += self.encodeChar(char, codePage);
                }
            }
        });

        return encodedText;
    },
    decodeGsiBlock: function (gsiBytes) {
        return {
            cpn : hex2ascii(gsiBytes.substring(0, 6)),
            frameRate: this.frameRateMapping[gsiBytes.substring(6, 22)],
            dsc : gsiBytes.substring(22, 24),
            alphabet: this.charCodeTblMap[gsiBytes.substring(24, 28)],
            program: hex2ascii(gsiBytes.substring(32, 96)).trim(),
            episode: hex2ascii(gsiBytes.substring(96, 160)).trim(),
            ttiBlocks: parseInt(hex2ascii(gsiBytes.substring(476, 486))),
            subtitles: parseInt(hex2ascii(gsiBytes.substring(476, 486))),
            subtitleGroups: parseInt(hex2ascii(gsiBytes.substring(486, 496))),
            maxCharPerLine: hex2ascii(gsiBytes.substring(502, 506)),
            maxLines: hex2ascii(gsiBytes.substring(506, 510)),
            som: hex2ascii(gsiBytes.substring(512, 528)),
            firstInCue: hex2ascii(gsiBytes.substring(528, 544)),
            publisher: hex2ascii(gsiBytes.substring(554, 618)).trim(),
            editor: hex2ascii(gsiBytes.substring(618, 650)).trim()
        }
    },
    decodeTtiBlock: function (ttiBytes) {
        return {
            subtitleGroup: parseInt(ttiBytes.substring(0, 2), 16),
            subtitleId: ttiBytes.substring(2, 6),
            extBlock: parseInt(ttiBytes.substring(6, 8), 16),
            cumStatus: ttiBytes.substring(8, 10),
            incode: ttiBytes.substring(10, 18),
            outcode: ttiBytes.substring(18, 26),
            yPos: (parseInt(ttiBytes.substring(26, 28), 16) / 23) * 100,
            xPos: this.textJustificationMapping[ttiBytes.substring(28, 30)],
            commentFlag: ttiBytes.substring(30, 32),
            text: ttiBytes.substring(32)
        }
    },
    encodeGsiBlock: function (events, encodingOptions, options) {
        let codePage = this.codePageMapping[encodingOptions["GSI Character Set"]] || 850; //default to multilanguage
        return {
            cpn : this.charCodeTblMap[encodingOptions["GSI Character Set"]],
            dfc : this.frameRateMapping[options.frameRate.toString()] || "53544c32352e3031", 
            dsc : this.dscMapping[encodingOptions["Display Standard Code"]] || '30',
            cct : this.charCodeTblMap[encodingOptions["Character Code Table"]],
            lc : "3030",
            opt : this.encodeText(encodingOptions["Program Title"] ? encodingOptions["Program Title"].substring(0,32) : "", codePage).padEnd(64, "20"),
            oet : this.encodeText(encodingOptions["Episode Title"] ? encodingOptions["Episode Title"].substring(0,32) : "", codePage).padEnd(64, "20"),
            tpt : this.encodeText(encodingOptions["Translated Program Title"] ? encodingOptions["Translated Program Title"].substring(0,32) : "", codePage).padEnd(64, "20"),
            tet : this.encodeText(encodingOptions["Translated Episode Title"] ? encodingOptions["Translated Episode Title"].substring(0,32) : "", codePage).padEnd(64, "20"),
            tn : this.encodeText(encodingOptions["Translator's Name"] ? encodingOptions["Translator's Name"].substring(0,32) : "", codePage).padEnd(64, "20"),
            tcd : this.encodeText(encodingOptions["Translator's Contact Details"] ? encodingOptions["Translator's Contact Details"].substring(0,32) : "", codePage).padEnd(64, "20"),
            slr : this.encodeText(encodingOptions["Subtitle List Ref Code"] ? encodingOptions["Subtitle List Ref Code"].substring(0,16) : "", codePage).padEnd(32, "20"),
            cd  : this.encodeText(this.getCurrentDate(), codePage),
            rd : this.encodeText(this.getCurrentDate(), codePage),
            rn : "3131",
            tnb : this.encodeText(events.length, codePage).padStart(10,"30"),
            tns : this.encodeText(events.length, codePage).padStart(10,"30"),
            tng: "303031",
            mnc : "3430",
            mnr : "3233",
            tcs : "31",
            tcp : this.encodeText(encodingOptions["SOM"] ? encodingOptions["SOM"].replace(/:|;/g, "") : "00000000", codePage),
            tcf : this.encodeHexTcFromSec(events[0].start, options.frameRate, codePage),
            tnd : "31",
            dsn : "34",
            co : this.encodeText("CAN", codePage),
            pub : this.encodeText(encodingOptions["Publisher"] ? encodingOptions["Publisher"].substring(0,32) : "", codePage).padEnd(64, "20"),
            en : this.encodeText(encodingOptions["Editor's Name"] ? encodingOptions["Editor's Name"].substring(0,32) : "", codePage).padEnd(64, "20"),
            ecd : this.encodeText(encodingOptions["Editor's Contact Details"] ? encodingOptions["Editor's Contact Details"].substring(0,32) : "", codePage).padEnd(64, "20"),
            concat : function(){
                return (this.cpn + this.dfc + this.dsc + this.cct + this.lc + this.opt + this.oet + this.tpt + this.tet + this.tn + this.tcd + this.slr + this.cd + this.rd + this.rn + this.tnb + this.tns + this.tng + this.mnc + this.mnr + this.tcs + this.tcp + this.tcf + this.tnd + this.dsn + this.co + this.pub + this.en + this.ecd).padEnd(2048, "20").toLowerCase();
            }
        }
    },
    encodeTtiBlock: function (event, index, encodingOptions, options) {
        let codePage = this.codePageMapping[encodingOptions["Character Code Table"]] || 20269;
        let encodedText = "20" + this.encodeText(event.text, codePage);
    
        return {
            sgn : "00",
            sn : index.toString(16).padStart(4, "0"),
            ebn: "FF",
            cs: "00",
            tci: this.encodeTc(event.start, options.frameRate),
            tco: this.encodeTc(event.end, options.frameRate),
            vp: this.encodeVerticalPosition(event.yPos),
            jc: this.textJustificationReverseMapping[event.alignment] || "02",
            cf: "00",
            tf: encodedText,
            concat : function(){
                return (this.sgn + this.sn + this.ebn + this.cs + this.tci + this.tco + this.vp + this.jc + this.cf + this.tf).padEnd(256, "8f").toLowerCase();
            }
        }
    },
    decodeTc: function (hexTc) {
        let hours = "00" + parseInt(hexTc.substring(0, 2), 16);
        let minutes = "00" + parseInt(hexTc.substring(2, 4), 16);
        let seconds = "00" + parseInt(hexTc.substring(4, 6), 16);
        let frames = "00" + parseInt(hexTc.substring(6, 8), 16);

        return `${
            hours.slice(-2)
        }:${
            minutes.slice(-2)
        }:${
            seconds.slice(-2)
        }:${
            frames.slice(-2)
        }`;
    },
    /* Used in TTI Blocks */
    encodeTc : function(sec, frameRate){
        let hex = "";
        let tc = tcLib.secToTc(sec, frameRate).replace(/;/g, ":");
        tc.split(":").forEach(tcVal=> {
            hex += ("00" + parseInt(tcVal).toString(16)).slice(-2);
        });       
        
        return hex;
    },
    /* Used in GSI encoding */
    encodeHexTcFromSec: function (sec, frameRate, codePage) {
        let tc = tcLib.secToTc(sec, frameRate).replace(/:|;/g, "");
        return this.encodeText(tc, codePage);        
    },
    encodeVerticalPosition : function(yPos){
        if (yPos === "start"){
            return parseInt(0).toString(16).padStart(2, '0');
        } else if (yPos === "center"){
            return parseInt(11).toString(16).padStart(2, '0');
        } else {
            return parseInt(21).toString(16).padStart(2, '0');
        }
    },
    positionToHex: function (position) { // console.log(position);
        let y = Math.floor(position.split("_")[0] / 15 * 23);
        return ccFunc.pad("00", 23 - y.toString(16), true);
    },
    getCurrentDate : function(){
        let x = new Date();
        let y = x.getFullYear().toString();
        let m = (x.getMonth() + 1).toString();
        let d = x.getDate().toString();
        (d.length == 1) && (d = '0' + d);
        (m.length == 1) && (m = '0' + m);
        let yyyymmdd = y + m + d;
        return yyyymmdd.substring(2);
    },
    accentMapping: {
        /* A */
        "41": {
            /* C1 */
            193: "À",
            /* C2 */
            194: "Á",
            /* C3 */
            195: "Â",
            /* C4 */
            196: "Ã",
            /* C5 */
            197: "Ā",
            /* C6 */
            198: "Ă",
            /* C7 */
            199: "A",
            /* C8 */
            200: "Ä",
            /* CA */
            202: "Å",
            /* CE */
            206: "Ą"

        },
        /* C */
        "43": {
            /* C1 */
            193: "C",
            /* C2 */
            194: "Ć",
            /* C3 */
            195: "Ĉ",
            /* C4 */
            196: "C",
            /* C5 */
            197: "C",
            /* C6 */
            198: "C",
            /* C7 */
            199: "Ċ",
            /* C8 */
            200: "C",
            /* CB */
            203: "Ç",
            /* CF */
            207: "Č"
        },
        /* D */
        "44": { /* CF */
            207: "Ď"
        },
        /* E */
        "45": {
            /* C1 */
            193: "È",
            /* C2 */
            194: "É",
            /* C3 */
            195: "Ê",
            /* C4 */
            196: "E",
            /* C5 */
            197: "Ē",
            /* C6 */
            198: "E",
            /* C7 */
            199: "Ė",
            /* C8 */
            200: "Ë",
            /* CE */
            206: "Ę",
            /* CF */
            207: "Ě"
        },
        /* G */
        "47": {
            /* C1 */
            193: "G",
            /* C2 */
            194: "G",
            /* C3 */
            195: "Ĝ",
            /* C4 */
            196: "G",
            /* C5 */
            197: "G",
            /* C6 */
            198: "Ğ",
            /* C7 */
            199: "Ġ",
            /* C8 */
            200: "G",
            /* CB */
            203: "Ģ"
        },
        /* H */
        "48": {
            /* C1 */
            193: "H",
            /* C2 */
            194: "H",
            /* C3 */
            195: "Ĥ",
            /* C4 */
            196: "H",
            /* C5 */
            197: "H",
            /* C6 */
            198: "H",
            /* C7 */
            199: "H",
            /* C8 */
            200: "H"
        },
        /* I */
        "49": {
            /* C1 */
            193: "Ì",
            /* C2 */
            194: "Í",
            /* C3 */
            195: "Î",
            /* C4 */
            196: "Ĩ",
            /* C5 */
            197: "Ī",
            /* C6 */
            198: "I",
            /* C7 */
            199: "İ",
            /* C8 */
            200: "Ï",
            /* CE */
            206: "Į"
        },
        /* J */
        "4a": {
            /* C1 */
            193: "J",
            /* C2 */
            194: "J",
            /* C3 */
            195: "Ĵ",
            /* C4 */
            196: "J",
            /* C5 */
            197: "J",
            /* C6 */
            198: "J",
            /* C7 */
            199: "J",
            /* C8 */
            200: "J"
        },
        /* K */
        "4b": { /* CB */
            203: "Ķ"
        },
        /* L */
        "4c": {
            /* C1 */
            193: "L",
            /* C2 */
            194: "Ĺ",
            /* C3 */
            195: "L",
            /* C4 */
            196: "L",
            /* C5 */
            197: "L",
            /* C6 */
            198: "L",
            /* C7 */
            199: "L",
            /* C8 */
            200: "L",
            /* CB */
            203: "Ļ",
            /* CF */
            207: "Ľ"
        },
        /* N */
        "4e": {
            /* C1 */
            193: "N",
            /* C2 */
            194: "Ń",
            /* C3 */
            195: "N",
            /* C4 */
            196: "Ñ",
            /* C5 */
            197: "N",
            /* C6 */
            198: "N",
            /* C7 */
            199: "N",
            /* C8 */
            200: "N",
            /* CB */
            203: "Ņ",
            /* CF */
            207: "Ň"
        },
        /* O */
        "4f": {
            /* C1 */
            193: "Ò",
            /* C2 */
            194: "Ó",
            /* C3 */
            195: "Ô",
            /* C4 */
            196: "Õ",
            /* C5 */
            197: "Ō",
            /* C6 */
            198: "O",
            /* C7 */
            199: "O",
            /* C8 */
            200: "Ö",
            /* CD */
            205: "Ő"
        },
        /* R */
        "52": {
            /* C1 */
            193: "R",
            /* C2 */
            194: "Ŕ",
            /* C3 */
            195: "R",
            /* C4 */
            196: "R",
            /* C5 */
            197: "R",
            /* C6 */
            198: "R",
            /* C7 */
            199: "R",
            /* C8 */
            200: "R",
            /* CB */
            203: "Ŗ",
            /* CF */
            207: "Ř"
        },
        /* S */
        "53": {
            /* C1 */
            193: "S",
            /* C2 */
            194: "Ś",
            /* C3 */
            195: "Ŝ",
            /* C4 */
            196: "S",
            /* C5 */
            197: "S",
            /* C6 */
            198: "S",
            /* C7 */
            199: "S",
            /* C8 */
            200: "S",
            /* CB */
            203: "Ş",
            /* CF */
            207: "Š"
        },
        /* T */
        "54": { /* CB */
            203: "Ţ",
            /* CF */
            207: "Ť"
        },
        /* U */
        "55": {
            /* C1 */
            193: "Ù",
            /* C2 */
            194: "Ú",
            /* C3 */
            195: "Û",
            /* C4 */
            196: "Ũ",
            /* C5 */
            197: "Ū",
            /* C6 */
            198: "Ŭ",
            /* C7 */
            199: "U",
            /* C8 */
            200: "Ü",
            /* CA */
            202: "Ů",
            /* CD */
            205: "Ű",
            /* CE */
            206: "Ų"
        },
        /* W */
        "57": {
            /* C1 */
            193: "W",
            /* C2 */
            194: "W",
            /* C3 */
            195: "Ŵ",
            /* C4 */
            196: "W",
            /* C5 */
            197: "W",
            /* C6 */
            198: "W",
            /* C7 */
            199: "W",
            /* C8 */
            200: "W"
        },
        /* Y */
        "59": {
            /* C1 */
            193: "Y",
            /* C2 */
            194: "Ý",
            /* C3 */
            195: "Ŷ",
            /* C4 */
            196: "Y",
            /* C5 */
            197: "Y",
            /* C6 */
            198: "Y",
            /* C7 */
            199: "Y",
            /* C8 */
            200: "Ÿ"
        },
        /* Z */
        "5a": {
            /* C1 */
            193: "Z",
            /* C2 */
            194: "Ź",
            /* C3 */
            195: "Z",
            /* C4 */
            196: "Z",
            /* C5 */
            197: "Z",
            /* C6 */
            198: "Z",
            /* C7 */
            199: "Ż",
            /* C8 */
            200: "Z",
            /* CF */
            207: "Ž"
        },
        /* a */
        "61": {
            /* C1 */
            193: "à",
            /* C2 */
            194: "á",
            /* C3 */
            195: "â",
            /* C4 */
            196: "ã",
            /* C5 */
            197: "ā",
            /* C6 */
            198: "ă",
            /* C7 */
            199: "a",
            /* C8 */
            200: "ä",
            /* CA */
            202: "å",
            /* CE */
            206: "ą"
        },
        /* c */
        "63": {
            /* C1 */
            193: "c",
            /* C2 */
            194: "ć",
            /* C3 */
            195: "ĉ",
            /* C4 */
            196: "c",
            /* C5 */
            197: "c",
            /* C6 */
            198: "c",
            /* C7 */
            199: "ċ",
            /* C8 */
            200: "c",
            /* CB */
            203: "ç",
            /* CF */
            207: "č"
        },
        /* d */
        "64": { /* CF */
            207: "ď"
        },
        /* e */
        "65": {
            /* C1 */
            193: "è",
            /* C2 */
            194: "é",
            /* C3 */
            195: "ê",
            /* C4 */
            196: "e",
            /* C5 */
            197: "ē",
            /* C6 */
            198: "e",
            /* C7 */
            199: "ė",
            /* C8 */
            200: "ë",
            /* CE */
            206: "ę",
            /* CF */
            207: "ě"
        },
        /* g */
        "67": {
            /* C1 */
            193: "g",
            /* C2 */
            194: "ģ",
            /* C3 */
            195: "ĝ",
            /* C4 */
            196: "g",
            /* C5 */
            197: "g",
            /* C6 */
            198: "ğ",
            /* C7 */
            199: "ġ",
            /* C8 */
            200: "g"
        },
        /* h */
        "68": {
            /* C1 */
            193: "h",
            /* C2 */
            194: "h",
            /* C3 */
            195: "ĥ",
            /* C4 */
            196: "h",
            /* C5 */
            197: "h",
            /* C6 */
            198: "h",
            /* C7 */
            199: "h",
            /* C8 */
            200: "h"
        },
        /* i */
        "69": {
            /* C1 */
            193: "ì",
            /* C2 */
            194: "í",
            /* C3 */
            195: "î",
            /* C4 */
            196: "ĩ",
            /* C5 */
            197: "ī",
            /* C6 */
            198: "i",
            /* C7 */
            199: "ı",
            /* C8 */
            200: "ï",
            /* CE */
            206: "į"
        },
        /* j */
        "6a": {
            /* C1 */
            193: "j",
            /* C2 */
            194: "j",
            /* C3 */
            195: "ĵ",
            /* C4 */
            196: "j",
            /* C5 */
            197: "j",
            /* C6 */
            198: "j",
            /* C7 */
            199: "j",
            /* C8 */
            200: "j"
        },
        /* k */
        "6b": { /* CB */
            203: "ķ"
        },
        /* l */
        "6c": {
            /* C1 */
            193: "l",
            /* C2 */
            194: "ĺ",
            /* C3 */
            195: "l",
            /* C4 */
            196: "l",
            /* C5 */
            197: "l",
            /* C6 */
            198: "l",
            /* C7 */
            199: "l",
            /* C8 */
            200: "l",
            /* CB */
            203: "ļ",
            /* CF */
            207: "ľ"
        },
        /* n */
        "6e": {
            /* C1 */
            193: "n",
            /* C2 */
            194: "ń",
            /* C3 */
            195: "n",
            /* C4 */
            196: "ñ",
            /* C5 */
            197: "n",
            /* C6 */
            198: "n",
            /* C7 */
            199: "n",
            /* C8 */
            200: "n",
            /* CB */
            203: "ņ",
            /* CF */
            207: "ň"
        },
        /* o */
        "6f": {
            /* C1 */
            193: "ò",
            /* C2 */
            194: "ó",
            /* C3 */
            195: "ô",
            /* C4 */
            196: "õ",
            /* C5 */
            197: "ō",
            /* C6 */
            198: "o",
            /* C7 */
            199: "o",
            /* C8 */
            200: "ö",
            /* CD */
            205: "ő"
        },
        /* r */
        "72": {
            /* C1 */
            193: "r",
            /* C2 */
            194: "ŕ",
            /* C3 */
            195: "r",
            /* C4 */
            196: "r",
            /* C5 */
            197: "r",
            /* C6 */
            198: "r",
            /* C7 */
            199: "r",
            /* C8 */
            200: "r",
            /* CB */
            203: "ŗ",
            /* CF */
            207: "ř"
        },
        /* s */
        "73": {
            /* C1 */
            193: "s",
            /* C2 */
            194: "ś",
            /* C3 */
            195: "ŝ",
            /* C4 */
            196: "s",
            /* C5 */
            197: "s",
            /* C6 */
            198: "s",
            /* C7 */
            199: "s",
            /* C8 */
            200: "s",
            /* CB */
            203: "ş",
            /* CF */
            207: "š"
        },
        /* t */
        "74": { /* CB */
            203: "ţ",
            /* CF */
            207: "ť"
        },
        /* u */
        "75": {
            /* C1 */
            193: "ù",
            /* C2 */
            194: "ú",
            /* C3 */
            195: "û",
            /* C4 */
            196: "ũ",
            /* C5 */
            197: "ū",
            /* C6 */
            198: "ŭ",
            /* C7 */
            199: "u",
            /* C8 */
            200: "ü",
            /* CA */
            202: "ů",
            /* CD */
            205: "ű",
            /* CE */
            206: "ų"
        },
        /* w */
        "77": {
            /* C1 */
            193: "w",
            /* C2 */
            194: "w",
            /* C3 */
            195: "ŵ",
            /* C4 */
            196: "w",
            /* C5 */
            197: "w",
            /* C6 */
            198: "w",
            /* C7 */
            199: "w",
            /* C8 */
            200: "w"
        },
        /* y */
        "79": {
            /* C1 */
            193: "y",
            /* C2 */
            194: "ý",
            /* C3 */
            195: "ŷ",
            /* C4 */
            196: "y",
            /* C5 */
            197: "y",
            /* C6 */
            198: "y",
            /* C7 */
            199: "y",
            /* C8 */
            200: "ÿ"
        },
        /* z */
        "7a": {
            /* C1 */
            193: "z",
            /* C2 */
            194: "ź",
            /* C3 */
            195: "z",
            /* C4 */
            196: "z",
            /* C5 */
            197: "z",
            /* C6 */
            198: "z",
            /* C7 */
            199: "ż",
            /* C8 */
            200: "z",
            /* CF */
            207: "ž"
        }
    },
    accentReverseMapping : { 
        "À" : "c141",
        "Á" : "c241",
        "Â" : "c341",
        "Ã" : "c441",
        "Ā" : "c541",
        "Ă" : "c641",
        "Ä" : "c841",
        "Å" : "ca41",
        "Ą" : "cb41",
        "Ć" : "c243",
        "Ĉ" : "c343",    
        "Ċ" : "c743",
        "Ç" : "cb43",
        "Č" : "cf43",
        "Ď" : "cf44",
        "È" : "c145",
        "É" : "c245",
        "Ê" : "c345",    
        "Ē" : "c545",
        "Ė" : "c745",
        "Ë" : "c845",
        "Ę" : "cb45",
        "Ě" : "cf45",       
        "Ĝ" : "c347",        
        "Ğ" : "cf47",
        "Ġ" : "c747",        
        "Ģ" : "cb47",        
        "Ĥ" : "c348",
        "Ì" : "c149",
        "Í" : "c249",
        "Î" : "c349",
        "Ĩ" : "c449",
        "Ī" : "c549",
        "İ" : "c749",
        "Ï" : "c849",
        "Į" : "ce49",
        "Ĵ" : "c34a",
        "Ķ" : "cb4b",
        "Ĺ" : "c24c",
        "Ļ" : "cb4c",
        "Ľ" : "c24c",
        "Ń" : "c24e",
        "Ñ" : "c44e",
        "Ņ" : "cb4e",
        "Ň" : "c64e",
        "Ò" : "c14f",
        "Ó" : "c24f",
        "Ô" : "c34f",
        "Õ" : "c44f",
        "Ō" : "c54f",
        "Ö" : "c84f",
        "Ő" : "cd4f",
        "Ŕ" : "c252",
        "Ŗ" : "cb52",
        "Ř" : "c652",
        "Ś" : "c253",
        "Ŝ" : "c353",
        "Ş" : "cb53",
        "Š" : "c653",
        "Ť" : "c654",
        "Ù" : "c155",
        "Ú" : "c255",
        "Û" : "c355",
        "Ũ" : "c455",
        "Ū" : "c555",
        "Ŭ" : "c655",
        "Ü" : "c855",
        "Ů" : "ca55",
        "Ű" : "cd55",
        "Ų" : "ce55",
        "Ŵ" : "c357",
        "Ý" : "c229",
        "Ŷ" : "c359",
        "Ÿ" : "c859",
        "Ź" : "c25a",
        "Ż" : "c75a",
        "Ž" : "c65a",
        "à" : "c161",
        "á" : "c261",
        "â" : "c361",
        "ã" : "c461",
        "ā" : "c561",
        "ă" : "c661",
        "ä" : "c861",
        "å" : "ca61",
        "ą" : "cb61",
        "ć" : "c263",
        "ĉ" : "c363",
        "ċ" : "c763",
        "ç" : "cb63",
        "č" : "cf63",
        "ď" : "cf64",
        "è" : "c165",
        "é" : "c265",
        "ê" : "c365",
        "ē" : "c565",
        "ė" : "c765",
        "ë" : "c865",
        "ę" : "cb65",
        "ě" : "cf65",
        "ģ" : "c367",
        "ĝ" : "cf67",
        "ğ" : "c767",
        "ġ" : "cb67",
        "ĥ" : "c368",
        "ì" : "c169",
        "í" : "c269",
        "î" : "c369",
        "ĩ" : "c469",
        "ī" : "c569",
        "ï" : "c869",
        "į" : "ce69",
        "ĵ" : "c36a",
        "ķ" : "cb6b",
        "ĺ" : "c26c",
        "ļ" : "cb6c",
        "ľ" : "c26c",
        "ń" : "c26e",
        "ñ" : "c46e",
        "ņ" : "cb6e",
        "ň" : "c66e",
        "ò" : "c16f",
        "ó" : "c26f",
        "ô" : "c36f",
        "õ" : "c46f",
        "ō" : "c56f",
        "ö" : "c86f",
        "ő" : "cd6f",
        "ŕ" : "c272",
        "ŗ" : "cb72",
        "ř" : "c672",
        "ś" : "c273",
        "ŝ" : "c373",
        "ş" : "cb73",
        "š" : "c673",
        "ţ" : "c674",
        "ť" : "c674",
        "ù" : "c175",
        "ú" : "c275",
        "û" : "c375",
        "ũ" : "c475",
        "ū" : "c575",
        "ŭ" : "c675",
        "ü" : "c875",
        "ů" : "ca75",
        "ű" : "cd75",
        "ų" : "ce75",
        "ŵ" : "c377",
        "ý" : "c279",
        "ŷ" : "c379",
        "ÿ" : "c879",
        "ź" : "c27a",
        "ż" : "c77a",
        "ž" : "c67a"
    }
}
