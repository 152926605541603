const uuidv4 = require('uuid').v4;
module.exports = class Event {
    constructor(options = {
        start : false,
        end : false,
        duration : false,
        rate : 1, //Playback rate for audio description
        xPos : "center", //start, center, end
        yPos : "end", //start, center, end
        xOffset : 0, //% value -100 to +100
        yOffset : 0, //% value -100 to +100
        alignment : "center", //left, center, right
        original : "", //Original Language for translation
        text : "",
        style : "Pop-On",
        speaker : {},
        audioFile : "",
        background: "#000000",
        color : "#FFFFFF",
    }) {
        this.id = uuidv4(),
        this.start = options.start,
        this.end = options.end,
        this.rate = options.rate || 1,
        this.xPos = options.xPos || "center",
        this.yPos = options.yPos ||"end",
        this.xOffset = options.xOffset ||0,
        this.yOffset = options.yOffset || 0,
        this.alignment = options.alignment || "center",
        this.original = options.original || "",
        this.text = options.text || "",
        this.style = options.style || "Pop-On",
        this.speaker = options.speaker || {},
        this.audioFile = options.audioFile || "",
        this.background = options.background || "#000000",
        this.color = options.color || "#ffffff"
    }
}
