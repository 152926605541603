const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const htmlEntities = require('html-entities'); //Encode
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const flexbox = require("../dict/flexbox.js");
const quillClasses = require("../dict/quillClasses.js");

module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent;
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"]
        });

        //console.log(JSON.stringify(fileJson, null, 4));
        fileJson.tt.body.div.p.forEach(paragraph => {
            ccEvent = new Event();
            ccEvent.start = tcLib.tcToSec(paragraph["@_begin"]);
            ccEvent.end = tcLib.tcToSec(paragraph["@_end"]);

            /* Positioning */
            ccEvent.alignment = paragraph["@_tts:textAlign"] || "center";

            if (paragraph["@_tts:textAlign"]) {
                ccEvent.yPos = flexbox.alignmentMap[paragraph["@_tts:textAlign"].toLowerCase()];
            }

            ccEvent.text = htmlEntities.decode(convertToHtml(ttmlFunc.multiLine.formatText(paragraph["#text"]), [quillClasses.align[ccEvent.alignment]]));

            events.push(ccEvent);
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let output = `<?xml version="1.0" encoding="UTF-8"?>`;
        output += eol.after(`<tt xml:lang="en"
        xmlns="http://www.w3.org/ns/ttml"
        xmlns:tts="http://www.w3.org/ns/ttml#styling"
        xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
        xmlns:smpte="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt"
        xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
        xmlns:ttp="http://www.w3.org/ns/ttml#parameter"
        ttp:profile="http://www.netflix.com/ns/ttml/profile/NFTT-SDH"
        ttp:timeBase="media" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}">`);

        output += eol.after(`<head>
        <metadata>
         <ttm:title>Untitled</ttm:title>
         <ttm:desc>Closed Caption Converter - Netflix TT Subtitles</ttm:desc>
         <smpte:information
          xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
          origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608"
          mode="Preserved" m608:channel="CC1" m608:programName="Untitled" m608:captionService="F1C1CC"
         />
        </metadata>
        <styling>
         <style xml:id='basic' tts:color='white' tts:backgroundColor='transparent' tts:fontSize='100%' tts:fontWeight='normal' tts:fontStyle='normal'/>
        </styling>
        <layout>
         <region xml:id='top' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='before'></region>
         <region xml:id='bottom' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='after'></region>
         <region xml:id='center' tts:backgroundColor='transparent' tts:showBackground='whenActive' tts:origin='10% 10%' tts:extent='80% 80%' tts:displayAlign='after'></region>
        </layout>
       </head>`);

        output += eol.after(`<body>`);
        output += eol.after(`<div>`)

        eventGroup.events.forEach(event => {
            let start = tcLib.secToTc(event.start, options.frameRate);
            let end = tcLib.secToTc(event.end, options.frameRate);
            let region = flexbox.flexMap.vertical[event.yPos];
            let text = ttmlFunc.multiLine.convertToTtml(convertToPlainTextCustom(event.text));
            output += eol.after(`<p begin='${start}' end='${end}' region='${region}' style='basic' tts:textAlign='${event.alignment}'>${text}</p>`);
        });

        output += eol.after(`</body>`);
        output += eol.after(`</div>`);
        output += eol.after(`</tt>`);
        return xmlFormat(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input.trim());
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}