const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const formatTags = require("../dict/formatTags.js");
const eol = require("eol");

const pacFunc = require('../functions/profiles/ssPoliscript.js');

module.exports = {
    decode: function (input, options) {
        /* Code Page Decode */
        /* pacFunc.codePages.forEach(codePage =>{
            console.log(codePage + " --> " +pacFunc.decodeChar("87", codePage));
        }); */
        let events = [],
            tcFlag = false,
            subtitleFlag = false,
            seperator = "fe",
            codePage = 28591,
            ccEvent,
            incodeHex,
            outcodeHex,
            decodingOptions = getFormatOptions(options.formatOptions);

        if (decodingOptions["Code Page"]) {
            codePage = pacFunc.codePageMapping[decodingOptions["Code Page"]];
        }

        let pacLine = [],
            pacLines = [],
            tcMarkerIndex;
        input.match(/(..?)/g).forEach(hexCode => {
            if (hexCode === seperator) {
                /* Check if the seperator is part of a tc block */
                tcMarkerIndex = pacLine.findIndex(pacCode => {
                    return pacCode === "60";
                });                

                if (tcMarkerIndex > -1 && !pacLine[tcMarkerIndex+8]){
                    /* This is part of a TC block */
                    pacLine.push(hexCode);
                } else {
                    /* This is not part of a TC block and is used to separate a line */
                    pacLines.push(pacLine.join(""));
                    pacLine = [];
                }

            } else {
                pacLine.push(hexCode);
            }
        });

        /* push last pac Line */
        if (pacLine.length > 0){
            pacLines.push(pacLine.join(""));
        }       
        //console.log(pacLines);
        pacLines.forEach(function (pacLine) {
            //console.log(pacLine);
            pacLine = pacLine.match(/(..?)/g);
            //console.log(pacLine);

            /* Debug Logger: */
            //console.log(pacLine.join(", ") + "\n");
            ccEvent ? ccEvent.text += "\n" : "";

            /* Alignment Start*/
            if (ccEvent && pacLine[0] === "01"){
                ccEvent.alignment = "left";
            } else if (ccEvent && pacLine[0] === "02"){
                ccEvent.alignment = "center";
            } else if (ccEvent && pacLine[0] === "00"){
                ccEvent.alignment = "right";
            }
            /* Alignment End */

            while (pacLine.length > 0) {
                let char = pacLine.shift();

                if (char === "60" && pacLine[9] === "00" && pacLine.length < 15) {
                    tcFlag = true;
                    subtitleFlag = true;
                    if (ccEvent) {
                        ccEvent.text = convertToHtml(ccEvent.text);
                        events.push(ccEvent);
                    }

                    ccEvent = new Event();
                    
                    incodeHex = pacLine.splice(0, 4);
                    outcodeHex = pacLine.splice(0, 4);

                    /* Timecode Debug */
                    //console.log(incodeHex + "-->" + pacFunc.decodeTc(incodeHex));
                    //console.log(outcodeHex + "-->" + pacFunc.decodeTc(outcodeHex));

                    ccEvent.start = tcLib.tcToSec(pacFunc.decodeTc(incodeHex), options.frameRate);
                    ccEvent.end = tcLib.tcToSec(pacFunc.decodeTc(outcodeHex), options.frameRate);

                    /* Vertical Position Start */
                    if (pacLine[2]){
                        let vertPosValue = parseInt(pacLine[2], 16);
                        if (vertPosValue < 4){
                            ccEvent.yPos = "start";
                        } else if (vertPosValue >= 4  && vertPosValue <= 8){
                            ccEvent.yPos = "center";
                        }   
                    }
                    /* Vertical Position End */


                    /* remove end parts */
                    pacLine = [];

                } else if (tcFlag) {
                    if (parseInt(char, 16) >= 240 && parseInt(char, 16) <= 255) {
                        /* control codes */
                        //console.log(char);

                    } else if (parseInt(char, 16) >= 0 && parseInt(char, 16) <= 31) {
                        // console.log("Garbage: " + char);
                        if (tcFlag && char === "00" && ccEvent.text) {
                            subtitleFlag = false;
                        }
                    } else if (subtitleFlag) {
                        if (parseInt(char, 16) === 128) {
                            ccEvent.text += "#";
                        } else if (parseInt(char, 16) >= 129 && parseInt(char, 16) < 160) {
                            /* Do Nothing? */
                        } else {
                            if (pacLine[0] && pacFunc.specialLatinChars[char + pacLine[0]]) {
                                ccEvent.text += pacFunc.specialLatinChars[char + pacLine.shift()];
                            } else if (char === "3c" || char === "3e") {
                                ccEvent.text += "<em>";
                            } else {
                                //console.log(char, pacFunc.decodeChar(char, codePage));
                                //console.log(pacLine);
                                ccEvent.text += pacFunc.decodeChar(char, codePage);
                            }
                        }
                    }
                }
            }
        });
        /* Push Last ccEvent */
        ccEvent.text = convertToHtml(ccEvent.text);
        events.push(ccEvent);

        if (decodingOptions["Ignore Metadata"]) {
            try {
                if (tcLib.secToTc(events[0].start, options.frameRate) === "00:00:00:00" && tcLib.secToTc(events[0].end, options.frameRate) === "00:00:00:08") {
                    events.shift();
                }
            } catch (e) {
                console.log("Unable to detect metadata in PAC source");
            }
        }
        //console.log(events);
        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("PAC files are not supported for encoding by Closed Caption Covnerter.");
    },

    preProcess: {
        encode: function (eventGroup) {
            return events;
        },

        decode: function (input) {
            return input.toLowerCase();
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {           
            eventGroup.events = eventGroup.events.filter(event => {
                event.text = event.text.replace(/äo/g, "ô").replace(/sº/g, "soe");
                return event.text != "dummy end of file" && event.start != "" && event.end != "" && event.text != "";
            });
            return eventGroup;
        }
    }

}
