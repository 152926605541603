<template>
<!-- Modal -->
<div class="modal fade" id="ApiKeyModal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <i class="fas fa-key"></i> API Key
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="small text-danger">
          Your API key is unique to you and should never be shared.
        </p>
        <p>
          To get started using the Closed Caption Converter API please contact
          support or read our API documentation available online: <u>https://tinyurl.com/y7k47ypx</u>
        </p>

        <form>
          <div class="row">
            <div class="col-8 pr-0">
              <input readonly class="form-control p-1" placeholder="API KEY" v-model="apiKey" onclick="select()" />
            </div>
            <div class="col">
              <button type="button" class="btn btn-success" @click="generateAPIKey">
                Generate Key <i v-if="loadingKey" class="fas fa-spinner fa-spin"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import firebase from '@/firebase/config'
export default {
  name: "ApiKeyModal",
  data: function () {
    return {
      loadingKey: false,
      apiKey: ""
    }
  },
  mounted: function () {
    let self = this;
    var email = firebase.auth().currentUser.email;
    this.loadingKey = true;
    firebase.functions().httpsCallable('getApiKey')(email).then(res => {
      self.apiKey = res.data;
    }).catch(function (error) {
      console.log(error);
    }).finally(function () {
      self.loadingKey = false;
    });
  },
  methods: {
    generateAPIKey: function () {
      let self = this;
      var email = firebase.auth().currentUser.email;
      this.loadingKey = true;
      firebase.functions().httpsCallable('generateApiKey')(email).then(res => {
        self.apiKey = res.data;
        self.$notify({
          title: 'API Key',
          text: 'API Key successfully created',
          type: 'success' /* warn, error, success */
        });
      }).catch(function (error) {
        console.log(error);
        self.$notify({
          title: 'API Key',
          text: 'Error generating API key. Please contact support.',
          type: 'error' /* warn, error, success */
        });
      }).finally(function () {
        self.loadingKey = false;
      });

    }
  }
};
</script>
