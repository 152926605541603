const encoder = require("../profiles/index.js");
const defaults = require("../lib/defaults.js");
const applyWindowOffsets = require("./special/applyWindowOffsets.js");
module.exports = function(eventGroup, options = new defaults.options()) {
    if (encoder[options.profile]){
        eventGroup = applyWindowOffsets(eventGroup, options.window);
        eventGroup = encoder[options.profile].preProcess.encode(eventGroup, options);
        let output = encoder[options.profile].encode(eventGroup, options);
        return encoder[options.profile].postProcess.encode(output, options);
    } else {
        throw new Error(`Target profile [${options.profile}] does not exist. Please ensure name matches a supported encoding profile. Profile names are case-sensitive.`);
    }
}