const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const eol = require("eol");

module.exports = {
    decode: function (input, options) {
        let events = [];
        let fileLines = eol.split(input);
        fileLines.forEach(fileLine => {
            let ccEvent = new Event();
            if (new RegExp(/TC:\W\W\d\d:\d\d:\d\d/).test(fileLine)) {
                let parts = fileLine.split("  ");
                let header = parts.shift();
                let tc = parts.shift();
                let text = parts.shift();

                ccEvent.start = tcLib.tcToSec(tc, options.frameRate);
                if (events.length > 0) {
                    events[events.length - 1].end = tcLib.tcToSec(tc, options.frameRate);
                }

                let textArray = text.split("");
                while (textArray.length > 0) {
                    let char = textArray.shift();
                    let charCode = char.charCodeAt();
                    if (charCode === 65533) {
                        if (textArray.length > 2) {
                            if (ccEvent.text.length > 0) {
                                ccEvent.text = ccEvent.text.trim() + "\n";
                            }

                            if (textArray[2].charCodeAt() === 65533) {
                                textArray.splice(0, 2);
                            } else if (textArray[0].charCodeAt() === 65533) {
                                textArray.splice(0, 5);
                            } else {
                                textArray.splice(0, 4);
                            }
                        }

                    } else {
                        ccEvent.text += char;
                    }
                }

                ccEvent.text = convertToHtml(ccEvent.text);
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        throw new Error("Closed Caption Converter does not support the encoding of ProCap Transfer files.")
    },

    preProcess: {
        encode: function (eventGroup) {
            return eventGroup;
        },

        decode: function (input) {
            return eol.lf(input);
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }
}