import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import firebase from './firebase/config'
import Notifications from 'vue-notification'
import Clipboard from 'v-clipboard'

Vue.config.productionTip = false

Vue.use(Notifications);
Vue.use(Clipboard);

window.$ = require('jquery');
require('bootstrap');

router.beforeEach((to, from, next) => {
  if (!to.meta.protected) {
    //route is public, don't check for authentication
    next()
  } else {
    //route is protected, if authenticated, proceed. Else, login
     firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        next()
      } else {
        router.push({
          path: '/login',
          query: {
            prevPath: window.location.href
          }
        })
      }
    }) 
  }
});


export default new Vue({
  store,
  router,
  render: h => h(App),
  created() {
    // Prevent blank screen in Electron builds
    this.$router.push('/');
  }
}).$mount('#app')
