const eol = require("eol");
const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
module.exports = {
    decode : function(input, options){
        let events = [], 
            ccEvent, 
            blockInfo, 
            blockItem, 
            tcFlag = false,
            subtitleBlocks = input.split("\n");

        subtitleBlocks.forEach(block => {
            /* Replace any tabs for spaces so that we can support all versions of encore files */
            tcFlag = false;
            blockInfo = block.replace(/\t/g, " ").split(" ");
            while (blockInfo.length > 0){
                blockItem = blockInfo.shift();
                if (/\d\d:\d\d:\d\d:\d\d/.test(blockItem)){
                    tcFlag = true;
                    ccEvent = new Event();
                    ccEvent.start = tcLib.tcToSec(blockItem, options.frameRate);
                    ccEvent.end = tcLib.tcToSec(blockInfo.shift(), options.frameRate);
                    ccEvent.text = blockInfo.splice(0).join(" ").trim().replace(/⏎/g, "\n");
                    events.push(ccEvent);
                }
            }   
            
            if (!tcFlag && events[events.length-1]){
                events[events.length-1].text += "\n"+block;
            }
        });

        return events;
    },

    encode : function(eventGroup, options){
        let output = "";
        eventGroup.events.forEach(event=>{
            output += tcLib.secToTc(event.start, options.frameRate);
            output += "\t";
            output += tcLib.secToTc(event.end, options.frameRate);
            output += "\t";
            output += convertToPlainText(event.text) + "\n";
        });

        return output.trim();
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return eol.lf(input.trim());
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            eventGroup.events.forEach((event, index, events) =>{
                events[index].text = convertToHtml(event.text);
            });

            return eventGroup;
        }
    }
}