const stripTags = require("./stripTags.js");
const replaceBoldTags = require("./replaceBoldTags.js");
const replaceItalicTags = require("./replaceItalicTags.js");
const replaceUnderlineTags = require("./replaceUnderlineTags.js");

module.exports = function convertToPlainTextCustom(text, divider = "\n", removeTags = false, boldTag = "strong", italicTag = "em", underlineTag = "u"){
    text = text.toString().replace(/<p\b[^>]*>/gmi, "").replace(/<\/p>/gmi, divider);
    text = replaceBoldTags(text, "<"+boldTag+">", "</"+boldTag+">");
    text = replaceItalicTags(text, "<"+italicTag+">", "</"+italicTag+">");
    text = replaceUnderlineTags(text, "<"+underlineTag+">", "</"+underlineTag+">");

    if (removeTags){
        return stripTags(text.trim());
    } else {
        return text.trim();
    }
}