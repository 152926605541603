module.exports = {
    bold : {
        open : "<strong>",
        close : "</strong>"
    },
    italics : {
        open : "<em>",
        close : "</em>"
    },
    underline : {
        open : "<u>",
        close : "</u>"
    },
    paragraph  : {
        open : "<p>",
        close : "</p>"
    }
}