module.exports = {
    cmds : {
        /* C0 table */
        /*Always clear the buffer whenever a command code is found*/
        "00": "{NUL}", // End of Line
        "03": "{ETX}", // Clear Screen (end of text line?)
        "08": "{BS}", // Backspace previous character
        "0c": "{FF}", // Clear Screen and SetPenLocation
        "0d": "{CR}", // Carriage-Return - go to start of line
        "0e": "{HCR}", // Clear Line and go to start of line
        "10": "{EXT}", // Use secondary/external table lookup
        "11": "{SKIP-1}",
        "12": "{SKIP-1}",
        "13": "{SKIP-1}",
        "14": "",
        "15": "{SKIP-1}",
        "16": "{SKIP-1}",
        "17": "{SKIP-1}",
        "18": "{P16}",
        "19": "",
        "1a": "",
        "1b": "",
        "1c": "",
        "1d": "",
        "1e": "",
        "1f": "",
        /* C1 table */
        "80": "{CW-0}", // Set current window
        "81": "{CW-1}", // Set current window
        "82": "{CW-2}", // Set current window
        "83": "{CW-3}", // Set current window
        "84": "{CW-4}", // Set current window
        "85": "{CW-5}", // Set current window
        "86": "{CW-6}", // Set current window
        "87": "{CW-7}", // Set current window
        "88": "{CLW}", // Clear Window**
        "89": "{DSW}", // Display the Window!**
        "8a": "{HDW}", // Hide Windows**
        "8b": "{TGW}", // Hides window and displays the next window
        "8c": "{DLW}", // Deletes windows**
        "8d": "{DLY}", // Delay in 1/10s increments
        "8e": "{DLC}", // Cancel Delay
        "8f": "{RST}", // Reset clears buffers and windows
        "90": "{SPA}", // Set Pen Attributes
        "91": "{SPC}", // Set Pen Color
        "92": "{SPL}", // Set Pen Location
        "97": "{SWA}", // Set Window Attributes
        "98": "{DF-0}", // Define Window
        "99": "{DF-1}", // Define Window
        "9a": "{DF-2}", // Define Window
        "9b": "{DF-3}", // Define Window
        "9c": "{DF-4}", // Define Window
        "9d": "{DF-5}", // Define Window
        "9e": "{DF-6}", // Define Window
        "9f": "{DF-7}", // Define Window
        /* G0 table */
        "20": " ",
        "21": "!",
        "22": '"',
        "23": "#",
        "24": "$",
        "25": "%",
        "26": "&",
        "27": "'",
        "27": "'",
        "27": "’",
        "27": "’",
        "27": "‘",
        "28": "(",
        "29": ")",
        "2a": "*",
        "2b": "+",
        "2c": ",",
        "2d": "-",
        "2e": ".",
        "2f": "/",
        "30": "0",
        "31": "1",
        "32": '2',
        "33": "3",
        "34": "4",
        "35": "5",
        "36": "6",
        "37": "7",
        "38": "8",
        "39": "9",
        "3a": ":",
        "3b": ";",
        "3c": "<",
        "3d": "=",
        "3e": ">",
        "3f": "?",
        "40": "@",
        "41": "A",
        "42": 'B',
        "43": "C",
        "44": "D",
        "45": "E",
        "46": "F",
        "47": "G",
        "48": "H",
        "49": "I",
        "4a": "J",
        "4b": "K",
        "4c": "L",
        "4d": "M",
        "4e": "N",
        "4f": "O",
        "50": "P",
        "51": "Q",
        "52": 'R',
        "53": "S",
        "54": "T",
        "55": "U",
        "56": "V",
        "57": "W",
        "58": "X",
        "59": "Y",
        "5a": "Z",
        "5b": "[",
        "5c": '\\',
        "5d": "]",
        "5e": "^",
        "5f": "_",
        "60": "'",
        "60": "’",
        "61": "a",
        "62": 'b',
        "63": "c",
        "64": "d",
        "65": "e",
        "66": "f",
        "67": "g",
        "68": "h",
        "69": "i",
        "6a": "j",
        "6b": "k",
        "6c": "l",
        "6d": "m",
        "6e": "n",
        "6f": "o",
        "70": "p",
        "71": "q",
        "72": 'r',
        "73": "s",
        "74": "t",
        "75": "u",
        "76": "v",
        "77": "w",
        "78": "x",
        "79": "y",
        "7a": "z",
        "7b": "{",
        "7c": "|",
        "7d": "}",
        "7e": "`",
        "7f": "♪",
        /* G1 table */
        "a0": " ",
        "a1": "¡",
        "a2": "￠",
        "a3": "￡",
        "a4": "¤",
        "a5": "￥",
        "a6": "¦",
        "a7": "§",
        "a8": "¨",
        "a9": "©",
        "aa": "ª",
        "ab": "«",
        "ac": "￢",
        "ad": "-",
        "ae": "®",
        "af": "￣",
        "b0": "°",
        "b1": "±",
        "b2": "²",
        "b3": "³",
        "b4": "´",
        "b5": "µ",
        "b6": "¶",
        "b7": "·",
        "b8": "¸",
        "b9": "¹",
        "ba": "º",
        "bb": "»",
        "bc": "¼",
        "bd": "½",
        "be": "¾",
        "bf": "¿",
        "c0": "À",
        "c1": "Á",
        "c2": "Â",
        "c3": "Ã",
        "c4": "Ä",
        "c5": "Å",
        "c6": "Æ",
        "c7": "Ç",
        "c8": "È",
        "c9": "É",
        "ca": "Ê",
        "cb": "Ë",
        "cc": "Ì",
        "cd": "Í",
        "ce": "Î",
        "cf": "Ï",
        "d0": "Ð",
        "d1": "Ñ",
        "d2": "Ò",
        "d3": "Ó",
        "d4": "Ô",
        "d5": "Õ",
        "d6": "Ö",
        "d7": "×",
        "d8": "",
        "d9": "Ù",
        "da": "Ú",
        "db": "Û",
        "dc": "Ü",
        "dd": "Ý",
        "de": "Þ",
        "df": "ß",
        "e0": "à",
        "e1": "á",
        "e2": "â",
        "e3": "ã",
        "e4": "ä",
        "e5": "å",
        "e6": "æ",
        "e7": "ç",
        "e8": "è",
        "e9": "é",
        "ea": "ê",
        "eb": "ë",
        "ec": "ì",
        "ed": "í",
        "ee": "î",
        "ef": "ï",
        "f0": "ð",
        "f1": "ñ",
        "f2": "ò",
        "f3": "ó",
        "f4": "ô",
        "f5": "õ",
        "f6": "ö",
        "f7": "÷",
        "f8": "ø",
        "f9": "ù",
        "fa": "ú",
        "fb": "û",
        "fc": "ü",
        "fd": "ý",
        "fe": "þ",
        "ff": "ÿ",

        EXT: {
            /* C2 table */
            "00": "{NUL}",
            "01": "{NUL}",
            "02": "{NUL}",
            "03": "{NUL}",
            "04": "{NUL}",
            "05": "{NUL}",
            "06": "{NUL}",
            "07": "{NUL}",
            "08": "{SKIP-1}",
            "09": "{SKIP-1}",
            "0a": "{SKIP-1}",
            "0b": "{SKIP-1}",
            "0c": "{SKIP-1}",
            "0d": "{SKIP-1}",
            "0e": "{SKIP-1}",
            "0f": "{SKIP-1}",
            "10": "",
            "11": "",
            "12": "",
            "13": "",
            "14": "",
            "15": "",
            "16": "",
            "17": "",
            "18": "{SKIP-3}",
            "19": "{SKIP-3}",
            "1a": "{SKIP-3}",
            "1b": "{SKIP-3}",
            "1c": "{SKIP-3}",
            "1d": "{SKIP-3}",
            "1e": "{SKIP-3}",
            "1f": "{SKIP-3}",
            /* C3 table */
            "80": "{SKIP-4}",
            "81": "{SKIP-4}",
            "82": "{SKIP-4}",
            "83": "{SKIP-4}",
            "84": "{SKIP-4}",
            "85": "{SKIP-4}",
            "86": "{SKIP-4}",
            "87": "{SKIP-4}",
            "88": "{SKIP-5}",
            "89": "{SKIP-5}",
            "8a": "{SKIP-5}",
            "8b": "{SKIP-5}",
            "8c": "{SKIP-5}",
            "8d": "{SKIP-5}",
            "8e": "{SKIP-5}",
            "8f": "{SKIP-5}",
            /* G2 table */
            "20": " ",
            "21": " ",
            "25": "…",
            "2a": "Š",
            "2c": "Œ",
            "30": "█",
            "31": "'",
            "32": "'",
            "33": "“",
            "34": "”",
            "35": "•",
            "39": "™",
            "3a": "š",
            "3c": "œ",
            "3d": "℠",
            "3f": "Ÿ",
            "76": "⅛",
            "77": "⅜",
            "78": "⅝",
            "79": "⅞",
            "7a": "│",
            "7b": "┐",
            "7c": "└",
            "7d": "─",
            "7e": "┘",
            "7f": "♪",
            /* G3 table */
            "a0": "[CC]"
        }
    }
}
