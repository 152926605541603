const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const eol = require("eol");
const convertToHtml = require("../functions/quill/convertToHtml.js");
module.exports = {
    decode : function(input, options){
        let events = [], spaces = "    ", tc, fileParts;
        let fileLines = input.split("\n").filter((fileLine) => {
            return /^\d\d:\d\d:\d\d:\d\d|^\d\d:\d\d:\d\d;\d\d/g.test(
              fileLine.split(spaces)[0]
            );
        });

        fileLines.forEach(fileLine => {
            lineData = fileLine.split(" ").filter(part => {return part;});
            tc = lineData.shift();
            text = lineData.join(" ").trim();
            if (text){
                if (events.length > 0 && !events[events.length-1].end) {
                    events[events.length-1].end = tcLib.tcToSec(tc, options.frameRate); 
                }

                let ccEvent = new Event()
                ccEvent.style = "Roll-Up";
                ccEvent.start = tcLib.tcToSec(tc, options.frameRate);
                ccEvent.text = convertToHtml(text);

                events.push(ccEvent);

            } else {
                events[events.length-1].end = tcLib.tcToSec(tc, options.frameRate); 
            }            
        });

        return events;
    },

    encode : function(eventGroup, options){
        throw new Error("Eclipse Caption Files are not supported for encoding by Closed Caption Converter.");
    },

    preProcess : {
        encode : function(eventGroup){
            return eventGroup;
        },

        decode : function(input){
            return eol.lf(input.trim());
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },
        decode : function(eventGroup){
            return eventGroup;
        }
    },

}