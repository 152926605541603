const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const formatTags = require("../dict/formatTags.js");
const eol = require("eol");
const htmlEntities = require('html-entities'); //Encode
const format = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode

module.exports = {
    decode : function(input, options){
        let events = [];
        let subtitleBlocks = input.split("\n\n");
        subtitleBlocks.forEach(block => {
            let ccEvent = new Event();
            let blockInfo = block.split("\n").filter(blockLine => {
                return blockLine !== "";
            });

            if (blockInfo.length > 1){
                tcInfo = blockInfo.shift();
                ccEvent.start = tcLib.tcToSec(tcInfo.split("\t")[0].replace(".", ":"), options.frameRate);
                ccEvent.end = tcLib.tcToSec(tcInfo.split("\t")[1].replace(".", ":"), options.frameRate);
                ccEvent.text = convertToHtml(blockInfo.join("\n"));
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode : function(eventGroup, options){
        let output = "", encodingOptions = getFormatOptions(options.formatOptions);
        eventGroup.events.forEach(event => {
            let start = tcLib.secToTc(event.start, options.frameRate);
            let end = tcLib.secToTc(event.end, options.frameRate);
            let text = convertToPlainText(event.text);
            output += start.substring(0,8) + "." + start.substring(9) + "\t" + end.substring(0,8) + "." + end.substring(9) + "\n";
            output += text + "\n\n";
        });

        return output.trim();
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return (eol.lf(input).trim()).replace(/'(\n){3,}'/gim, "\n\n");
        }
    },

    postProcess : {
        encode : function(output){
            return output.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    }
}