const tcLib = require("../../lib/timecode.js");
module.exports = {
    //HH:MM:SS:TTT A “tick” is defined as 4 msec and has a range of 0 to 249. 
    timeColonFormatTicks_ : /^(?:(\d{2,}):)?(\d{2}):(\d{2}\:\d{0,3})$/,
    //HH = hours, MM = minutes, SS = seconds, and sss = decimal fractions of a second.  
    timeColonFormatFrames_ : /^(\d{2,}):(\d{2}):(\d{2})\.?(\d+)?$/, 
    calcTc : function (tc) {
        if (this.timeColonFormatTicks_.test(tc)) {
            return tcLib.tcTicksToSec(tc);
        } else if (this.timeColonFormatFrames_.test(tc)) {
            return tcLib.tcFractionToSec(tc);
        } else {
            return;
        }
    }
}