<template>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <h1 class="navbar-brand pl-3 pt-3"><img src="@/assets/logo.png" width="30px" /> Converter {{this.$store.state.version}}</h1>
</nav>
</template>

<script>
export default {
    name: "ConverterToolbar"
}
</script>

<style>
nav {
    margin-bottom: 25px;
    padding: 0px !important;
}
</style>
