module.exports = {
    alignmentMap : {
        "left" : "start",
        "center" : "center",
        "right" : "end",
        "top" : "start",
        "bottom" : "end"
    },
    alignmentNormalize : {
        "left" : "left",
        "center" : "center",
        "right" : "right",
        "start" : "left",
        "end" : "right"
    },
    flexMap : {
        vertical : {
            "start" : "top",
            "center" : "center",
            "end" : "bottom"
        },
        horizontal : {
            "start" : "left",
            "center" : "center",
            "end" : "right"
        }
    }
    
}