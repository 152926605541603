/* Creating a single TTML functions file for managing all of the different functions we use across profiles. */

/* Multi-line profiles: */
/* 
    Ttml
*/

/* Single-line profiles: */
/* 
    NetflixTtCaptions
*/

const Event = require("../../classes/event.js");
const tcLib = require("../../lib/timecode.js");
const convertToHtml = require("../quill/convertToHtml.js");
const quillClasses = require("../../dict/quillClasses.js");
const findCenter = require("../utility/findCenter.js");
const eol = require("eol");
const htmlEntities = require("html-entities");
const getLongestLine = require("../utility/getLongestLine.js");
const stripTags = require("../quill/stripTags.js");
const convertToPlainText = require("../quill/convertToPlainText.js");
const flexbox = require("../../dict/flexbox.js");

module.exports = {
    /* General Functions */
    frameRateMap: {
        "23.976": "24",
        "24": "24",
        "25": "25",
        "29.97": "30",
        "30": "30",
        "50": "50",
        "59.94": "60",
        "60": "60"
    },
    frameRateMultiplierMap: {
        "23.976": "1000 1001",
        "24": "1 1",
        "25": "1 1",
        "29.97": "1000 1001",
        "30": "1 1",
        "50": "1 1",
        "59.94": "1000 1001",
        "60": "1 1"
    },
    eventStyleMap : {
        "Pop-On" : "pop",
        "Roll-Up" : "rollup",
        "Paint-On" : "paint"
    },
    singleLine: {
        Display : class {
            constructor (options = {
                style: "Pop-On",
                start: false,
                end: false,
                lines: []
            }) {
                this.style = options.style || "Pop-On",
                this.start = options.start || false,
                this.end = options.end || false,
                this.lines = options.lines || []
            }
    
            insertLine(options = {
                text: "",
                html: "",
                original: "",
                extentX: 0,
                extentY: 0,
                originX: 0,
                originY: 0
            }) {
                this.lines.push({
                    text: options.text || "",
                    html: options.html || "",
                    original: options.original || "",
                    extentX: options.extentX || 0,
                    extentY: options.extentY || 0,
                    originX: options.originX || 0,
                    originY: options.originY || 0
                });
            }
        },
        decodeDisplay : function(display, win){
            let ccEvent = new Event();
            let html = display.lines.map(line =>{
                return line.html;
            }).join("\n");
            ccEvent.start = display.start;
            ccEvent.end = display.end;
            
            let positionInfo = this.getPositionInfo(display, win);
            ccEvent.alignment = positionInfo.alignment;
            ccEvent.xPos = positionInfo.xPos;
            ccEvent.yPos = positionInfo.yPos;
            ccEvent.xOffset = positionInfo.xOffset;
            ccEvent.yOffset = positionInfo.yOffset;
    
            ccEvent.text = convertToHtml(html, [quillClasses.align[positionInfo.alignment]]);
            return ccEvent;
        },

        getPositionInfo : function(display, win){
            let xPos, yPos, xOffset, yOffset, alignment = "center", details;
    
            /* extent = size of element */
            /* origin = placement of element */
            details = this.getPosDetails(display.lines);
            alignment = this.getDisplayAlignment(details);
            x = this.getXPos(display.lines, details, alignment, win);
            y = this.getYPos(display.lines, win);
            xPos = x.position;
            xOffset = x.offset;
            yPos = y.position;
            yOffset = y.offset;
    
            return {
                alignment: alignment,
                xPos : xPos,
                xOffset : xOffset,
                yPos : yPos,
                yOffset : yOffset
            }
        },
        getPosDetails : function(lines){
            return lines.map(line =>{
                let alignment = "right";
                let center = findCenter(100, line.extentX);
                if (line.originX > center - 5 && line.originX < center + 5){
                    alignment = "center";
                } else if (line.originX < center) {
                    alignment = "left";
                }
    
                return {
                    start : line.originX,
                    end : parseFloat(line.originX) + parseFloat(line.extentX),
                    length : line.text.length,
                    alignment : alignment
                };
            });
        },
        getDisplayAlignment : function(details){
            if (details.length === 1){
                return "center";
            }

            let xPosSame = details.every(detail => {
                return detail.start === details[0].start;
            });
    
            let endSame = details.every(detail => {
                return detail.end === details[0].end;
            });
    
            let alignmentCenter = details.every(detail => {
                return detail.alignment === "center";
            });
    
            if (xPosSame) {
                return "left";
            } else if (alignmentCenter){
                return "center";
            } else if (endSame) {
                return "right";
            } else {
                return "center";
            }
        },
        getXPos : function(lines, details, alignment, win){
            let xInfo = {
                position : "center",
                offset : 0
            }
    
            let lowestXPos = Math.min.apply(Math, lines.map(function(line) { return line.originX; }));
            let highestEnd = Math.max.apply(Math, details.map(function(detail) { return detail.end; }));
    
            if (alignment === "left"){
                xInfo.position = "start"
                xInfo.offset = (lowestXPos/100) * win.width;
            } else if (alignment === "center"){
                xInfo.position = "center"
                xInfo.offset = 0;
            } else {
                xInfo.position = "end";
                xInfo.offset = ((100-highestEnd)/-100) * win.width;
            }
    
            return xInfo;
        },
        getYPos : function(lines, win){
            let yInfo = {
                position : "end",
                offset : 0
            }
    
            let highestYPos = Math.max.apply(Math, lines.map(function(line) { return line.originY; }));
            let lowestYPos = Math.min.apply(Math, lines.map(function(line) { return line.originY; }));
            
            if (lowestYPos > 60) {
                yInfo.position = "end";
                yInfo.offset = ((100-highestYPos)/-100) * win.height;
            } else if (highestYPos < 40) {
                yInfo.position = "start"
                yInfo.offset = (lowestYPos/100)*win.height;
            } else {
                yInfo.position = "center";
                yInfo.offset = ((lowestYPos - findCenter(100, lines.length*5.33))/100) * win.height;
            }
    
            return yInfo;
        },
        calcExtents : function(text, fontSize = 80){
            /* 80 is the font size (80%). 5.33 is the font height (5.33%) */
            let xExtent = ((stripTags(text).trim().length/32)*fontSize).toFixed(2);
            let yExtent = 5.33;
            return `${xExtent}% ${yExtent}%`; 
            /*e.g 37.5% 5.33% */
        },
        calcOrigin : function(event, text, lineNumber, totalLines, fontSize = 80, win){
            let xOrigin = this.calcXOrigin(event,text,fontSize, win);
            let yOrigin = this.calcYOrigin(event,lineNumber, totalLines, win);
            return `${xOrigin.toFixed(2)}% ${yOrigin.toFixed(2)}%`  /*e.g 37.5% 5.33% */
        },
        calcXOrigin(event,text,fontSize=80, win){
            let xOrigin, 
                longestLine = getLongestLine(convertToPlainText(event.text)), 
                xExtent = ((stripTags(text).length/40)*fontSize).toFixed(2), 
                xExtentOfLongestLine = ((longestLine.length/40)*fontSize).toFixed(2),
                eOffset = (event.xOffset/win.width)*100;
            //console.log(event);
            if (event.xPos === "start"){
                if (event.alignment === "left"){
                    xOrigin = 0;
                } else if (event.alignment === "center"){
                    xOrigin = findCenter(xExtentOfLongestLine, xExtent);
                } else {
                    xOrigin = (xExtentOfLongestLine - xExtent);
                }
            } else if (event.xPos === "center"){                
                xOrigin = findCenter(100, xExtent);
                //console.log(xOrigin, xExtent);
            } else {
                if (event.alignment === "left"){
                    xOrigin = 100 - xExtentOfLongestLine;
                } else if (event.alignment === "center"){
                    xOrigin = 100 - (xExtentOfLongestLine + findCenter(xExtentOfLongestLine, xExtent));
                } else {
                    xOrigin = 100 - (xExtent);
                }
            }
    
            return parseFloat(xOrigin)+eOffset;
        },
        calcYOrigin(event,lineNumber,totalLines, win){
            let yOrigin, eOffset = (event.yOffset/win.height) * 100;
            if (event.yPos === "start"){
                yOrigin = (5.33*lineNumber);
            } else if (event.yPos === "center"){
                yOrigin = findCenter(100, totalLines*5.33) + (lineNumber*5.33);
            } else {
                yOrigin = 100 - ((totalLines-lineNumber)*5.33);
            }
            
            //console.log(eOffset, yOrigin);
            return parseFloat(yOrigin) + eOffset;
        },    
        convertToTtml : function(html){
            return html.replace(/(<em><u>)+/gmi, "<span tts:fontStyle='italic'  tts:textDecoration='underline'>").replace(/(<\/em><\/u>)+/gmi, "</span>").replace(/(<em>)+/gmi, "<span tts:fontStyle='italic'>").replace(/(<\/em>)+/gmi, "</span>").replace(/(<u>)+/gmi, "<span tts:textDecoration='underline'>").replace(/(<\/u>)+/gmi, "</span>").replace(/(<\/span>)+/g, "</span>");
        },
    },
    multiLine: {
        /* Decode Functions */
        decodeSubtitle: function(subtitle, region, frameRate, win, ignorePos = false) {
            //console.log(subtitle, region, frameRate, win, ignorePos);
            let ccEvent = new Event();
            let start = tcLib.parseTcToSec(subtitle["@_begin"], frameRate);
            let end = tcLib.parseTcToSec(subtitle["@_end"], frameRate);
            let html = this.formatText(subtitle["#text"]);
            if (!ignorePos){
                let positionInfo = this.getPositionInfo(subtitle, region, win);
                ccEvent.alignment = positionInfo.alignment;
                ccEvent.xPos = positionInfo.xPos;
                ccEvent.yPos = positionInfo.yPos;
                ccEvent.xOffset = parseInt(positionInfo.xOffset);
                ccEvent.yOffset = parseInt(positionInfo.yOffset);
                ccEvent.text = convertToHtml(html, [quillClasses.align[positionInfo.alignment]]);
            } else {
                ccEvent.text = convertToHtml(html);
            }
            
            ccEvent.start = start;
            ccEvent.end = end;
            //console.log(ccEvent);
            return ccEvent;
        },
        formatText: function(text) {
            if (!text) {
                return "";
            }
            let fText = ""; //Formatted Text;
            let italics = false, underline = false;
            text.split(/<br\/>|<br>|<br \/>/).forEach(textLine => {
                textLine.split(/<\/span>/).forEach(linePart => {
                    underline = linePart.match(/tts:textDecoration=.underline./) !== null;
                    italics = linePart.match(/tts:fontStyle=.italic./g) !== null;

                    if (linePart.split(">")[1]) {
                        italics ? fText += "<em>" : null;
                        underline ? fText += "<u>" : null;
                        fText += linePart.split(">")[1];
                        underline ? fText += "</u>" : null;
                        italics ? fText += "</em>" : null;
                        fText += "\n";
                    } else {
                        fText += linePart + "\n";
                    }
                });
            });

            return htmlEntities.decode(fText.trim());
        },
        getPositionInfo: function(subtitle, regionInfo, win) {
            //console.log(subtitle, regionInfo, win);
            let xPos, yPos, xOffset, yOffset, alignment = "center", extents, origins;

            /* extent = size of element */
            /* origin = placement of element */
            extents = this.getExtents(subtitle, regionInfo);
            origins = this.getOrigins(subtitle, regionInfo);
            alignment = flexbox.alignmentNormalize[this.getAlignment(subtitle, regionInfo)];
            let x = this.getXPos(origins, extents, win);
            let y = this.getYPos(origins, extents, win);
            //console.log(x,y);
            xPos = x.position;
            xOffset = x.offset;
            yPos = y.position;
            yOffset = y.offset;

            return {
                alignment: alignment,
                xPos: xPos,
                xOffset: xOffset,
                yPos: yPos,
                yOffset: yOffset
            }
        },
        getExtents: function(subtitle, regionInfo) {
            if (regionInfo && regionInfo["@_tts:extent"]) {
                return {
                    x: parseFloat(regionInfo["@_tts:extent"].split(" ")[0].replace("%", "")),
                    y: parseFloat(regionInfo["@_tts:extent"].split(" ")[1].replace("%", ""))
                }
            } else if (subtitle["@_tts:extent"]) {
                return {
                    x: parseFloat(subtitle["@_tts:extent"].split(" ")[0].replace("%", "")),
                    y: parseFloat(subtitle["@_tts:extent"].split(" ")[1].replace("%", ""))
                }
            } else {
                return false;
            }
        },
        getOrigins: function(subtitle, regionInfo) {
            if (regionInfo && regionInfo["@_tts:origin"]) {
                return {
                    x: parseFloat(regionInfo["@_tts:origin"].split(" ")[0].replace("%", "")),
                    y: parseFloat(regionInfo["@_tts:origin"].split(" ")[1].replace("%", ""))
                }
            } else if (subtitle["@_tts:origin"]) {
                return {
                    x: parseFloat(subtitle["@_tts:origin"].split(" ")[0].replace("%", "")),
                    y: parseFloat(subtitle["@_tts:origin"].split(" ")[1].replace("%", ""))
                }
            } else {
                return false;
            }
        },
        getAlignment: function(subtitle, regionInfo) {
            if (subtitle["@_tts:textAlign"]) {
                return subtitle["@_tts:textAlign"];
            } else if (regionInfo && regionInfo["@_tts:textAlign"]) {
                return regionInfo["@_tts:textAlign"];
            } else {
                return "center";
            }
        },

        getXPos: function(origins, extents, win) {
            let xInfo = {
                position: "center",
                offset: 0
            }

            if (!origins || !extents || !origins.x || !extents.x) {
                return xInfo;
            }

            if (origins && extents) {
                let center = findCenter(100, extents.x);
                if (origins.x < center + 5 && origins.x > center - 5) {
                    xInfo.position = "center"
                    xInfo.offset = 0;
                } else if (origins.x > center) {
                    xInfo.position = "end"
                    xInfo.offset = ((100 - (origins.x + extents.x))/-100) * win.height;
                } else {
                    xInfo.position = "start";
                    xInfo.offset = (origins.x / 100) * win.height;
                }
            }
    
            return xInfo;
        },

        getYPos: function(origins, extents, win) {
            let yInfo = {
                position: "end",
                offset: 0
            }, percentOffset;
    
            if (!origins || !extents || !origins.y || !extents.y) {
                return yInfo;
            }
    
            if (origins.y > 60) {
                yInfo.position = "end";
                percentOffset = 100 - (origins.y + extents.y);
                if (percentOffset < 0){
                    yInfo.offset = 0;
                } else {
                    yInfo.offset = win.height * (percentOffset/-100);
                }                
            } else if (origins.y < 40) {
                yInfo.position = "start";
                yInfo.offset = (origins.y/100) * win.height;
            } else {
                yInfo.position = "center";
                yInfo.offset = ((origins.y - findCenter(100, extents.y))/100) * win.height;
            }
    
            return yInfo;
        },
        calcXOrigin(text, xPos, xOffset, fontSize = 80, win) {
            let xOrigin = 0, 
                longestLine = getLongestLine(text), 
                xExtent = parseFloat(((longestLine.length / 32) * fontSize).toFixed(2));
            if (xPos === "start") {
                xOrigin = 0;
            } else if (xPos === "center") {
                xOrigin = findCenter(100, xExtent);
            } else {
                xOrigin = 100 - xExtent;
            }
    
            return parseFloat(xOrigin) + ((xOffset/win.width)*100);
        },

        calcYOrigin(text, yPos, yOffset, win) {
            let yOrigin, 
                totalLines = eol.split(text).length, 
                vOffset = ((yOffset/win.height)*100);
            
            if (yPos === "start") {
                yOrigin = 0;
            } else if (yPos === "center") {
                yOrigin = findCenter(100, totalLines * 5.33);
            } else {
                yOrigin = (100 - (totalLines * 5.33));
            }
    
            return parseFloat(yOrigin) + vOffset;
        },

        calcExtents : function(text, fontSize = 80, lineHeight = 5.33){
            let longestLine = getLongestLine(text);
            /* 80 is the font size (80%). 5.33 is the line height (5.33%) */
            let xExtent = (longestLine.length/32)*fontSize;
            let yExtent = lineHeight*eol.split(text).length;
            return `${xExtent.toFixed(2)}% ${yExtent.toFixed(2)}%` 
            /*e.g 37.5% 5.33% */
        },

        calcOrigin : function(text, xPos, xOffset, yPos, yOffset, fontSize = 80, win){
            let xOrigin = this.calcXOrigin(text, xPos, xOffset, fontSize, win);
            let yOrigin = this.calcYOrigin(text, yPos, yOffset, win);
            return `${xOrigin.toFixed(2)}% ${yOrigin.toFixed(2)}%`  /*e.g 37.5% 5.33% */
        },
        convertToTtml : function(html){
            //console.log(html);
            return html.replace(/(<em><u>)+/gmi, "<span tts:fontStyle='italic'  tts:textDecoration='underline'>").replace(/(<\/em><\/u>)+/gmi, "</span>").replace(/(<em>)+/gmi, "<span tts:fontStyle='italic'>").replace(/(<\/em>)+/gmi, "</span>").replace(/(<u>)+/gmi, "<span tts:textDecoration='underline'>").replace(/(<\/u>)+/gmi, "</span>").replace(/(<\/span>)+/g, "</span>").replace(/(?:\r\n|\r|\n)/g, "<br/>");
        },
    }
}