<template>
<!-- Modal -->
<div class="modal fade" id="JobConfigModal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fas fa-tools"></i> Job Configuration
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="small text-info">
          The following job configuration can be used for submitting jobs
          using the Closed Caption Converter web services API. For more
          information please contact support.
        </p>

        <form>
          <div class="form-group">
            <label for="Job Configuration">Job Configuration</label>
            <textarea class="form-control" rows="8" placeholder="Please submit a job in order to generate a configuration file..." readonly v-model="this.$store.state.jobConfig" onclick="select()"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import firebase from "@/firebase/config";
export default {
  name: "ApiKeyModal",
  data: function () {
    return {
      loadingKey: false,
      apiKey: "",
    };
  },
  mounted: function () {
    let self = this;
    var email = firebase.auth().currentUser.email;
    this.loadingKey = true;
    firebase
      .functions()
      .httpsCallable("getApiKey")(email)
      .then((res) => {
        self.apiKey = res.data;
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        self.loadingKey = false;
      });
  },
  methods: {
    generateAPIKey: function () {
      let self = this;
      var email = firebase.auth().currentUser.email;
      this.loadingKey = true;
      firebase
        .functions()
        .httpsCallable("generateApiKey")(email)
        .then((res) => {
          self.apiKey = res.data;
          self.$notify({
            title: "API Key",
            text: "API Key successfully created",
            type: "success" /* warn, error, success */ ,
          });
        })
        .catch(function (error) {
          console.log(error);
          self.$notify({
            title: "API Key",
            text: "Error generating API key. Please contact support.",
            type: "error" /* warn, error, success */ ,
          });
        })
        .finally(function () {
          self.loadingKey = false;
        });
    },
  },
};
</script>
