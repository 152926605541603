import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/main';
Vue.use(Vuex);

const formats = require('@/external/cc-lib/lib/formats.js');
const encode = require('@/external/cc-lib/functions/encode.js');
const decode = require('@/external/cc-lib/functions/decode.js');
const conform = require('@/external/cc-lib/functions/special/conform.js');
const automaticOffset = require('@/external/cc-lib/functions/special/automaticOffset.js');
const dropFrameConvert = require('@/external/cc-lib/functions/special/dropFrameConvert.js');
const autoFormat = require('@/external/cc-lib/functions/eventGroups/autoFormat.js');
const tcLib = require('@/external/cc-lib/lib/timecode.js');
const defaults = require('@/external/cc-lib/lib/defaults.js');
const tcMultiply = require('@/external/cc-lib/functions/special/tcMultiply.js');
const tcOffset = require('@/external/cc-lib/functions/events/tcOffset.js');

/* Node */
const eol = require('eol');

export default new Vuex.Store({
  state: {
    version: '3.2.8',
    multiFile: false,
    fileName: "",
    srcPreview: "Please select a source file to begin.",
    trgPreview: "",
    trgExt: "",
    srcExt: "",
    srcProfile: "",
    trgProfile: "",
    jobConfig: ""
  },
  mutations: {
    convert(state, jobInfo) {
      let eventGroup = decode(jobInfo.fileContents, new defaults.options({
        profile: formats.profileMapping[jobInfo.srcProfile].name,
        formatOptions: jobInfo.decodeOptions,
        frameRate: jobInfo.srcFrameRate,
        dropFrame: jobInfo.srcDropFrame,
        window: {
          height: 480,
          width: 720,
          xOffset: 0,
          yOffset: 0
        }
      }));

      /* Segmentation */
      eventGroup = conform(eventGroup, {
        segments: jobInfo.segments,
        frameRate: jobInfo.srcFrameRate,
        dropFrame: jobInfo.srcDropFrame,
        incode: jobInfo.incode
      });

      /* Offset */
      if (jobInfo.automaticOffset) {
        automaticOffset(eventGroup, jobInfo.srcFrameRate);
      } else if (jobInfo.offset !== "00:00:00:00") {
        eventGroup.events.forEach((event, index, events) => {
          events[index] = tcOffset(event, tcLib.tcToSec(jobInfo.offset, jobInfo.srcFrameRate), jobInfo.offsetType);
        });
      }

      /* Frame Rate Convert */
      if (jobInfo.dropFrameConvert) {
        dropFrameConvert(eventGroup, {
          frameRate: jobInfo.trgFrameRate,
          dropFrame: jobInfo.trgDropFrame
        });
      }

      /* TC Stretch & Shrink */
      if (jobInfo.tcMultiplier && jobInfo.tcMultiplier !== 1) {
        tcMultiply(eventGroup, jobInfo.tcMultiplier);
      }

      /* Auto Format */
      if (jobInfo.autoFormat.enable) {
        eventGroup = autoFormat(eventGroup, jobInfo.autoFormat.maxLines, jobInfo.autoFormat.maxChars);
      }

      let output = encode(eventGroup, new defaults.options({
        profile: formats.profileMapping[jobInfo.trgProfile].name,
        formatOptions: jobInfo.encodeOptions,
        frameRate: jobInfo.trgFrameRate,
        dropFrame: jobInfo.trgDropFrame,
        window: {
          height: 480,
          width: 720,
          xOffset: 0,
          yOffset: 0
        }
      }));

      switch(jobInfo.eol) {
        case 'windows':
            output = eol.crlf(output);
            break;
        case 'macintosh':
            output = eol.cr(output);
            break;
        case 'unix':
            output = eol.lf(output);
            break;    
        default:
            output = eol.auto(output);
      }        

      if (state.multiFile) {
        const fs = require('fs');
        var filePath = jobInfo.trgDir + "/" + jobInfo.fileName + "." + jobInfo.trgExt;

        let defaultEncoding = formats.defaultEncodingMap[formats.profileMapping[jobInfo.trgProfile].name];
        fs.writeFile(filePath, output, defaultEncoding || jobInfo.fileEncoding, function (err) {
          if (err) {
            app.$notify({
              title: 'Job Error',
              type: 'error',
              text: 'File conversion failed. ' + err
            });
            console.log(err);
            return;
          }
        });
      } else {
        state.trgPreview = output;
        state.trgExt = jobInfo.trgExt;
        state.trgProfile = jobInfo.trgProfile;

        let configuration = {
          target_profile: formats.profileMapping[jobInfo.trgProfile].name,
          source_profile: formats.profileMapping[jobInfo.srcProfile].name,
          encoding_options: jobInfo.encodeOptions,
          decoding_options: jobInfo.decodeOptions,
          source_frameRate: jobInfo.srcFrameRate,
          target_frameRate: jobInfo.trgFrameRate,
          source_dropFrame: jobInfo.srcDropFrame,
          target_dropFrame: jobInfo.trgDropFrame,
          incode: jobInfo.incode,
          offset: jobInfo.offset,
          offset_type: jobInfo.offsetType,
          automatic_offset: jobInfo.automaticOffset,
          dropFrame_convert: jobInfo.dropFrameConvert,
          source_encoding: jobInfo.srcFileEncoding,
          target_encoding: jobInfo.trgFileEncoding,
          eol : jobInfo.eol,
          segments: jobInfo.segments,
          tcMultiplier: jobInfo.tcMultiplier,
          auto_format: jobInfo.autoFormat.enable,
          max_lines: jobInfo.autoFormat.maxLines,
          max_characters: jobInfo.autoFormat.maxChars
        };

        state.jobConfig = JSON.stringify(configuration, null, 4);
      }

      app.$notify({
        title: 'Job Complete',
        type: 'success',
        text: 'File conversion completed for [' + jobInfo.fileName + '.' + jobInfo.trgExt + ']'
      });

    },
    updateSrcPreview(state, srcPreview) {
      state.srcPreview = srcPreview.fileContents;
      state.srcExt = srcPreview.ext;
      state.fileName = srcPreview.fileName;
    },
    updateTrgPreview(state, trgPreview) {
      state.trgPreview = trgPreview.text;
      state.trgExt = trgPreview.ext;
      state.trgProfile = trgPreview.profile;
    },
    updateMultiFile(state, multiFile) {
      state.multiFile = multiFile;
    },
    resetState(state) {
      state.multiFile = false;
      state.fileName = "";
      state.srcPreview = "Please select a source file to begin.";
      state.trgPreview = "";
      state.trgExt = "";
      state.srcExt = "";
      state.trgProfile = "";
      state.srcProfile = "";
      state.jobConfig = "";
    }
  },
  actions: {
  },
  modules: {
  }
})
