<template>
<div>
  <ConverterToolbar />
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5">
        <JobInputForm />
      </div>
      <div class="col">
        <SourcePreview />
        <TargetPreview />
      </div>
    </div>
  </div>
  <ApiKeyModal />
  <JobConfigModal />
</div>
</template>

<script>
import ConverterToolbar from '@/components/ConverterToolbar'
import JobInputForm from '@/components/JobInputForm'
import SourcePreview from '@/components/SourcePreview'
import TargetPreview from '@/components/TargetPreview'
import ApiKeyModal from '@/components/ApiKeyModal'
import JobConfigModal from '@/components/JobConfigModal'
import firebase from '@/firebase/config'
export default {
  name: 'home',
  components: {
    ConverterToolbar,
    JobInputForm,
    SourcePreview,
    TargetPreview,
    ApiKeyModal,
    JobConfigModal
  },
  mounted: function () {
    var email = firebase.auth().currentUser.email;
    firebase.functions().httpsCallable('getUserSubscriptionNew')(email).then(res => {
      if (!res.data) {
        alert("Failed to authenticate license. Please contact support at support@closedcaptioncreator.com");
        firebase.auth().signOut();
      }
    }).catch(function (e) {
      console.log(e.message);
      alert("Failed to authenticate license. "+e.message+ "\nPlease contact support at support@closedcaptioncreator.com");
      firebase.auth().signOut();
    });
  }
}
</script>
