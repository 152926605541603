// Initialize Firebase
import firebase from 'firebase/app';  
import 'firebase/auth';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyB37p-OpH-Z-uHUUVPqEfwz-jVWWBNleTg",
  authDomain: "closed-caption-converter-app.firebaseapp.com",
  databaseURL: "https://closed-caption-converter-app.firebaseio.com",
  projectId: "closed-caption-converter-app",
  storageBucket: "closed-caption-converter-app.appspot.com",
  messagingSenderId: "1055726631465",
  appId: "1:1055726631465:web:a6e29138c1290e06417a38"
};

firebase.initializeApp(config);
export default firebase