const uuidv4 = require('uuid').v4;
const eol = require("eol");
const formatXml = require('xml-formatter');
const tcLib = require("../lib/timecode.js");

let frameRateMap = {
    "23.976" : "24", 
    "24" : "24", 
    "25" : "25", 
    "29.97" : "30",
    "30" : "30", 
    "50" : "50", 
    "59.94" : "60", 
    "60" : "60"
}

module.exports = {
    decode: function (input, options) {
        throw new Error("This profile [FCP XML] is not supported for decoding by Closed Caption Converter.");
    },
    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);
        let timebase = frameRateMap[options.frameRate] || "30";
        let ntsc = options.dropFrame ? "true" : "false";
        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<!DOCTYPE xmeml>`);
        output += eol.after(`<xmeml version="4">`);
        output += eol.after(`<project>`);
        output += eol.after(`<name>${encodingOptions["Project Name"] || "Untitled"}<name>`);
        output += eol.after(`<children>`);

        /* Clips */
        eventGroup.events.forEach((event, count) => {
            output += eol.after(`<clip id="masterclip-${count + 1}" explodedTracks="true">`);
            output += eol.after(`<uuid>${event.id}</uuid>`);
            output += eol.after(`<masterclipid>masterclip-${count + 1}</masterclipid>`);
            output += eol.after(`<ismasterclip>TRUE</ismasterclip>`);
            output += eol.after(`<duration>${tcLib.secToFrames(event.end-event.start, options.frameRate)}</duration>`);
            output += eol.after(`<rate>`);
            output += eol.after(`<timebase>${timebase}</timebase>`);
            output += eol.after(`<ntsc>${ntsc}</ntsc>`);
            output += eol.after(`</rate>`);
            output += eol.after(`<name>${count}.${encodingOptions["File Extension"]}</name>`);
            output += eol.after(`<media>`);
            output += eol.after(`<audio>`);
            output += eol.after(`<track>`);
            output += eol.after(`<clipitem id="clipitem-${count + 1}">`);
            output += eol.after(`<masterclipid>masterclip-${count + 1}</masterclipid>`);
            output += eol.after(`<name>${count}.${encodingOptions["File Extension"]}</name>`);
            output += eol.after(`<rate>`);
            output += eol.after(`<timebase>${timebase}</timebase>`);
            output += eol.after(`<ntsc>${ntsc}</ntsc>`);
            output += eol.after(`</rate>`);
            output += eol.after(`<file id="file-${count + 1}">`);
            output += eol.after(`<name>${count}.${encodingOptions["File Extension"]}</name>`);
            output += eol.after(`<pathurl>file://localhost/${count}.${encodingOptions["File Extension"]}</pathurl>`);
            output += eol.after(`<rate>`);
            output += eol.after(`<timebase>${timebase}</timebase>`);
            output += eol.after(`<ntsc>${ntsc}</ntsc>`);
            output += eol.after(`</rate>`);
            output += eol.after(`<duration>${tcLib.secToFrames(event.end-event.start, options.frameRate)}</duration>`);

            output += eol.after(`<timecode>`);
            output += eol.after(`<rate>`);
            output += eol.after(`<timebase>${timebase}</timebase>`);
            output += eol.after(`<ntsc>${ntsc}</ntsc>`);
            output += eol.after(`</rate>`);
            output += eol.after(`<string>00;00;00;00</string>`);
            output += eol.after(`<frame>0</frame>`);
            output += eol.after(`<displayformat>${options.dropFrame ? "DF" : "NDF"}</displayformat>`);
            output += eol.after(`</timecode>`);
            output += eol.after(`<media>
            <audio>
                <samplecharacteristics>
                    <depth>16</depth>
                    <samplerate>48000</samplerate>
                </samplecharacteristics>
                <channelcount>1</channelcount>
                <audiochannel>
                    <sourcechannel>1</sourcechannel>
                </audiochannel>
            </audio>
        </media>`)
            output += eol.after(`</file>`);
            output += eol.after(`<sourcetrack>
            <mediatype>audio</mediatype>
            <trackindex>1</trackindex>
        </sourcetrack>`);
            output += eol.after(`</clipitem>`);
            output += eol.after(`</track>`);
            output += eol.after(`</audio>`);
            output += eol.after(`</media>`);
            output += eol.after(`<logginginfo>
            <description></description>
            <scene></scene>
            <shottake></shottake>
            <lognote></lognote>
            <good></good>
            <originalvideofilename></originalvideofilename>
            <originalaudiofilename></originalaudiofilename>
        </logginginfo>`)
            output += eol.after(`<colorinfo>
            <lut></lut>
            <lut1></lut1>
            <asc_sop></asc_sop>
            <asc_sat></asc_sat>
            <lut2></lut2>
        </colorinfo>`);
            output += eol.after(`<labels>
            <label2>Caribbean</label2>
        </labels>`);
            output += "</clip>"
        });

        /* Sequences */
        output += eol.after(`<sequence id="sequence-1" explodedTracks="true">`);
        output += eol.after(`<uuid>${uuidv4()}</uuid>`);
        output += eol.after(`<duration>${tcLib.secToFrames(eventGroup.events[eventGroup.events.length - 1].end, options.frameRate)}</duration>`);
        output += eol.after(`<rate>`);
        output += eol.after(`<timebase>${timebase}</timebase>`);
        output += eol.after(`<ntsc>${ntsc}</ntsc>`);
        output += eol.after(`</rate>`);
        output += eol.after(`<name>${eventGroup.events[0].id}</name>`)

        output += eol.after(`<media>`);
        output += eol.after(`<video>`);
        output += eol.after(`<format>`);
        output += eol.after(`<samplecharacteristics>`);
        output += eol.after(`<rate>`);
        output += eol.after(`<timebase>${timebase}</timebase>`);
        output += eol.after(`<ntsc>${ntsc}</ntsc>`);
        output += eol.after(`</rate>`);
        output += eol.after(`<codec>
        <name>Apple ProRes 422</name>
        <appspecificdata>
            <appname>Final Cut Pro</appname>
            <appmanufacturer>Apple Inc.</appmanufacturer>
            <appversion>7.0</appversion>
            <data>
                <qtcodec>
                    <codecname>Apple ProRes 422</codecname>
                    <codectypename>Apple ProRes 422</codectypename>
                    <codectypecode>apcn</codectypecode>
                    <codecvendorcode>appl</codecvendorcode>
                    <spatialquality>1024</spatialquality>
                    <temporalquality>0</temporalquality>
                    <keyframerate>0</keyframerate>
                    <datarate>0</datarate>
                </qtcodec>
            </data>
        </appspecificdata>
    </codec>
    <width>854</width>
    <height>480</height>
    <anamorphic>FALSE</anamorphic>
    <pixelaspectratio>square</pixelaspectratio>
    <fielddominance>none</fielddominance>
    <colordepth>24</colordepth>
</samplecharacteristics>
</format>`);

        output += eol.after(`<track>
        <enabled>TRUE</enabled>
        <locked>FALSE</locked>
    </track>
    <track TL.SQTrackShy="0" TL.SQTrackExpandedHeight="25" TL.SQTrackExpanded="0" MZ.TrackTargeted="0">
        <enabled>TRUE</enabled>
        <locked>FALSE</locked>
    </track>
    <track TL.SQTrackShy="0" TL.SQTrackExpandedHeight="25" TL.SQTrackExpanded="0" MZ.TrackTargeted="0">
        <enabled>TRUE</enabled>
        <locked>FALSE</locked>
    </track>
</video>
`);
        output += eol.after(`<audio>`);
        output += eol.after(`<numOutputChannels>2</numOutputChannels>
        <format>
            <samplecharacteristics>
                <depth>16</depth>
                <samplerate>48000</samplerate>
            </samplecharacteristics>
        </format>`);
        output += eol.after(`<outputs>
        <group>
            <index>1</index>
            <numchannels>1</numchannels>
            <downmix>0</downmix>
            <channel>
                <index>1</index>
            </channel>
        </group>
        <group>
            <index>2</index>
            <numchannels>1</numchannels>
            <downmix>0</downmix>
            <channel>
                <index>2</index>
            </channel>
        </group>
    </outputs>`);

        output += eol.after(`<track>`);
        eventGroup.events.forEach((event, count, eventsArray) => {
            /* line  559 of fcp xml*/
            output += eol.after(`<clipitem id="clipitem-${count + 1 + eventsArray.length}" premiereChannelType="mono">
            <masterclipid>masterclip-${count+1}</masterclipid>
            <name>${event.id}.${encodingOptions["File Extension"]}</name>
            <enabled>TRUE</enabled>
            <duration>${tcLib.secToFrames(event.end-event.start, options.frameRate)}</duration>
            <rate>
                <timebase>${timebase}</timebase>
                <ntsc>${ntsc}</ntsc>
            </rate>
            <start>${tcLib.secToFrames(event.start, options.frameRate)}</start>
            <end>${tcLib.secToFrames(event.end, options.frameRate)}</end>
            <in>0</in>
            <out>${tcLib.secToFrames(event.end-event.start, options.frameRate)}</out>
            <pproTicksIn>0</pproTicksIn>
            <pproTicksOut>${tcLib.secToFrames(event.end-event.start, options.frameRate)* 8476876800}</pproTicksOut>
            <file id="file-${count+1}"/>
            <sourcetrack>
                <mediatype>audio</mediatype>
                <trackindex>1</trackindex>
            </sourcetrack>
            <logginginfo>
                <description></description>
                <scene></scene>
                <shottake></shottake>
                <lognote></lognote>
                <good></good>
                <originalvideofilename></originalvideofilename>
                <originalaudiofilename></originalaudiofilename>
            </logginginfo>
            <colorinfo>
                <lut></lut>
                <lut1></lut1>
                <asc_sop></asc_sop>
                <asc_sat></asc_sat>
                <lut2></lut2>
            </colorinfo>
            <labels>
                <label2>Caribbean</label2>
            </labels>
        </clipitem>`)
        });

        output += eol.after(`<rate>`);
        output += eol.after(`<timebase>${timebase}</timebase>`);
        output += eol.after(`<ntsc>${ntsc}</ntsc>`);
        output += eol.after(`</rate>`);
        output += eol.after(`<string>00;00;00;00</string>`);
        output += eol.after(`<frame>0</frame>`);
        output += eol.after(`<displayformat>${options.dropFrame ? "DF" : "NDF"}</displayformat>`);
        output += eol.after(`</timecode>`);
        output += eol.after(`<labels>
        <label2>Forest</label2>
    </labels>`);
        output += eol.after(`<logginginfo>
        <description></description>
        <scene></scene>
        <shottake></shottake>
        <lognote></lognote>
        <good></good>
        <originalvideofilename></originalvideofilename>
        <originalaudiofilename></originalaudiofilename>
    </logginginfo>`)
        output += eol.after(`</sequence>`);
        output += eol.after(`</children>`);
        output += eol.after(`</project>`);
        output += eol.after(`</xmeml>`);
        return formatXml(output);
    },
    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },
}