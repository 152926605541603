const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const formatTags = require("../dict/formatTags.js");
const eol = require("eol");

const ssaFunc = require("../functions/profiles/subStationAlpha.js");

module.exports = {
    decode: function (output, options) {
        let events = [];
        let tcPattern = new RegExp(/\d{1}:\d{2}:\d{2}.\d{1,2}/g);
        
        const fileLines = eol.split(output).filter(fileLine => {
            return fileLine;
        });

        fileLines.forEach(fileLine => {            
            let matches = fileLine.match(tcPattern);
            if (matches && matches.length >= 2){
                let parts = fileLine.split(",");
                parts.splice(0, 9);
                let ccEvent = new Event();
                ccEvent.start = tcLib.centisecToSeconds(matches[0]);
                ccEvent.end = tcLib.centisecToSeconds(matches[1]);
                ccEvent.text = convertToHtml(parts.join(",").replace(/\{...+\}/g,"").replace(/\\N/g,"\n"));
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);
        // console.log(JSON.stringify(encodingOptions, null, 4));

        let output = "",
            borderStyle = encodingOptions['Border Style'] || "3",
            fontFamily = encodingOptions['Font Family'] || "Arial",
            fontSize = encodingOptions['Font Size'],
            fontColor = encodingOptions['Font Color'] || "#FFFFFF",
            opacity = encodingOptions['Opacity'] || 100,
            spacing = encodingOptions['Letter Spacing'] || "0",
            shadow = encodingOptions['Shadow Size'] || "0",
            shadowColor = encodingOptions['Shadow Color'] || "#000000",
            shadowOpacity = encodingOptions['Shadow Opacity'] || 100,
            outline = encodingOptions['Outline Size'] || 1,
            olineColor = encodingOptions['Outline Color'] || "#000000",
            outlineOpacity = encodingOptions['Outline Opacity'] || 100;

        let primaryColor = ssaFunc.encodeColor(fontColor, opacity),
            secondaryColor = ssaFunc.encodeColor(fontColor, opacity),
            outlineColor = ssaFunc.encodeColor(olineColor, outlineOpacity),
            backgroundColor = ssaFunc.encodeColor(shadowColor, shadowOpacity);

        output += eol.after("[Script Info]");
        output += eol.after("; Script generated by Closed Caption Converter | www.closedcaptionconverter.com");
        output += eol.after("ScriptType: v4.00+");
        output += eol.after("Collisions: Normal");
        output += eol.after("PlayDepth: 0");
        output += String(eol.auto);
        output += eol.after("[V4+ Styles]");
        output += eol.after("Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding");
        output += eol.after(`Style: Default, Arial, 24,&H00B4FCFC,&H00B4FCFC,&H00000008,&H80000008,0,0,0,0,100,100,0.00,0.00,1,1.00,2.00,2,30,30,30,0`);
        output += eol.after(`Style: TopLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},7,30,30,30,0`);
        output += eol.after(`Style: TopCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},8,30,30,30,0`);
        output += eol.after(`Style: TopRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},9,30,30,30,0`);
        output += eol.after(`Style: CenterLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},4,30,30,30,0`);
        output += eol.after(`Style: CenterCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},5,30,30,30,0`);
        output += eol.after(`Style: CenterRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},6,30,30,30,0`);
        output += eol.after(`Style: BottomLeft, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},1,30,30,30,0`);
        output += eol.after(`Style: BottomCenter, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},2,30,30,30,0`);
        output += eol.after(`Style: BottomRight, ${fontFamily},${fontSize},${primaryColor},${secondaryColor},${outlineColor},${backgroundColor},0,0,0,0,95,95,${spacing},0.00,${borderStyle},${outline},${shadow},3,30,30,30,0`);

        output += String(eol.auto);
        output += eol.after("[Events]");
        output += eol.after("Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text");

        eventGroup.events.forEach(event => {
            let eventStyle = ssaFunc.styleMap[event.yPos][event.xPos];
            let startTime = tcLib.secToTc(event.start, options.frameRate);
            let endTime = tcLib.secToTc(event.end, options.frameRate);

            startTime = startTime.substring(0, 8) + "." + startTime.substring(9);
            endTime = endTime.substring(0, 8) + "." + endTime.substring(9);

            let marginL = event.xOffset;
            let marginV = event.yOffset;
            let text = ssaFunc.formatText(convertToPlainText(event.text));
            let speaker = event.speaker && event.speaker.name ? event.speaker.name : "";

            output += eol.after(`Dialogue: 0,${startTime},${endTime},${eventStyle},${speaker},${event.xPos === "start" ? marginL : ""},${event.xPos === "end" ? marginL : ""},${marginV},,${text}`);
        });

        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }
}