module.exports = {
    /* Supported Extensions */
    srcExt: [
        "ssa",
        "ass",
        "scc",
        "mcc",
        "srt",
        "webvtt",
        "vtt",
        "sbv",
        "sub",
        "txt",
        "stl",
        "pac",
        "cap",
        "asc",
        "xml",
        "ttml",
        "dfxp",
        "smi",
        "csv",
        "sami",
        "vnl"
    ],
    trgExt: [
        "ssa",
        "ass",
        "scc",
        "mcc",
        "srt",
        "webvtt",
        "vtt",
        "sbv",
        "sub",
        "txt",
        "stl",
        "cap",
        "asc",
        "xml",
        "ttml",
        "dfxp",
        "smi",
        "sami",
        "csv",
        "json",
        "vnl"
    ],
    srcProfiles: {
        "ssa": ["Substation Alpha"],
        "ass": ["Advanced Substation Alpha"],
        "scc": ["Scenarist V1.0"],
        "mcc": ["MacCaption 608/708"],
        "srt": ["SubRip Video Subtitle Script"],
        "vtt": ["WebVTT"],
        "webvtt": ["WebVTT"],
        "sbv": ["SubViewer"],
        "sub": [
            "SubViewer", "Sofni", "Micro DVD", "DVD Architect"
        ],
        "txt": [
            "Avid DS",
            "Adobe Encore",
            "Apple DVD Studio Pro",
            "EZ Title",
            "PowerPixel Format",
            "ProCap Transfer",
            "QuickTime Text",
            "Eclipse"
        ],
        "stl": [
            "EBU STL", "Spruce STL"
        ],
        "pac": ["ScreenSystems Poliscript"],
        "cap": ["Cheetah (binary)"],
        "asc": ["Cheetah (ASCII Text)"],
        "xml": [
            "Timed-Text Markup Language",
            "Distribution Format Exchange Profile (DFXP)",
            "DLP Cinema",
            "Netflix TT Captions",
            "Netflix TT Subtitles",
            "MAGIC TT",
            "EBU TT",
            "SMPTE TTML",
            "IMSC Text Captions",
            "IMSC Text Subtitles"
        ],
        "ttml": [
            "Timed-Text Markup Language",
            "Verizon TTML",
            "SMPTE TTML",
            "IMSC Text Captions",
            "IMSC Text Subtitles"
        ],
        "dfxp": ["Distribution Format Exchange Profile (DFXP)", "Timed-Text Markup Language", "Netflix DFXP"],
        "smi": ["Synchronized Accessible Media Interchange"],
        "csv": ["Comma-Separated Values"],
        "sami": ["Synchronized Accessible Media Interchange"],
        "vnl" : ["Vitac"]
    },
    trgProfiles: {
        "scc": ["Scenarist V1.0"],
        "ssa": ["Substation Alpha"],
        "ass": ["Advanced Substation Alpha"],
        "mcc": ["MacCaption 608/708"],
        "srt": ["SubRip Video Subtitle Script"],
        "vtt": ["WebVTT"],
        "webvtt": ["WebVTT"],
        "sbv": ["SubViewer"],
        "sub": [
            "SubViewer", "Sofni", "Micro DVD", "DVD Architect"
        ],
        "txt": [
            "Avid DS",
            "Adobe Encore",
            "Apple DVD Studio Pro",
            "EZ Title",
            "PowerPixel Format",
            "QuickTime Text",
            "Eclipse"
        ],
        "stl": [
            "EBU STL", "Spruce STL"
        ],
        "asc": ["Cheetah (ASCII Text)"],
        "xml": [
            "Timed-Text Markup Language",
            "Distribution Format Exchange Profile (DFXP)",
            "DLP Cinema",
            "Netflix TT Captions",
            "Netflix TT Subtitles",
            "MAGIC TT",
            "EBU TT",
            "HBO GO TTML",
            "HBO MAX SMPTE",
            "HBO SMPTE TTML",
            "TTML Legacy",
            "SMPTE TTML",
            "FCP XML",
            "IMSC Text Captions",
            "IMSC Text Subtitles"            
        ],
        "ttml": [
            "Timed-Text Markup Language",
            "Verizon TTML",
            "HBO GO TTML",
            "HBO MAX SMPTE",
            "HBO SMPTE TTML",
            "TTML Legacy",
            "SMPTE TTML",
            "IMSC Text Captions",
            "IMSC Text Subtitles"
        ],
        "dfxp": ["Distribution Format Exchange Profile (DFXP)", "Netflix DFXP"],
        "smi": ["Synchronized Accessible Media Interchange"],
        "sami": ["Synchronized Accessible Media Interchange"],
        "csv": ["Comma-Separated Values"],
        "json": ["JSON"],
        "vnl" : ["Vitac"]
    },

    profileMapping: {
        "Vitac" : {
            name : "vitac",
            options : {
                decode: [],
                encode: [
                    {
                        name: "Title",
                        type: "text-input",
                        values: "",
                        selected: "Untitled"
                    },
                ]
            }
        },
        "FCP XML": {
            name: "fcpXml",
            /* Encode/Decode Options */
            options: {
                decode: [],
                encode: [
                    {
                        name: "Project Name",
                        type: "text-input",
                        values: "",
                        selected: "Untitled"
                    },
                    {
                        name: "File Extension",
                        type: "text-input",
                        values: "",
                        selected: "wav"
                    }
                ]
            },
        },
        "QuickTime Text": {
            name: "quickTimeText",
            options: {
                decode: [],
                encode: []
            },
        },
        "Micro DVD": {
            name: "uDvd",
            options: {
                decode: [],
                encode: []
            },
        },
        "HBO GO TTML": {
            name: "hboGoTtml",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "proportionalSansSerif"
                    }, 
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "16"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "transparent"
                    },  

                    {
                        name: "Line Height",
                        type: "text-input",
                        values: "",
                        selected: "125"
                    },
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "ms"
                    }           
                ]
            },
        },
        "HBO MAX SMPTE": {
            name: "hboMaxSmpte",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "monospace"
                    }, 
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "80"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "black"
                    },  
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "smpte"
                    }           
                ]
            },
        },
        "HBO SMPTE TTML": {
            name: "hboSmpteTtml",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "es"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "monospace"
                    }, 
                    {
                        name: "Line Height",
                        type: "text-input",
                        values: "",
                        selected: "125"
                    },
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "8"
                    },
                    {
                        name: "Font Weight",
                        type: "text-input",
                        values: "",
                        selected: "bold"
                    },  
                    {
                        name: "Outline Color",
                        type: "text-input",
                        values: "",
                        selected: "black"
                    },  
                    {
                        name: "Outline Vertical Size",
                        type: "number-input",
                        values: "",
                        selected: "5"
                    },
                    {
                        name: "Outline Horizontal Size",
                        type: "number-input",
                        values: "",
                        selected: "3"
                    },                       
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "ms"
                    }           
                ]
            },
        },
        "TTML Legacy": {
            name: "ttmlLegacy",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "proportionalSansSerif"
                    }, 
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "80"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "transparent"
                    },  

                    {
                        name: "Line Height",
                        type: "text-input",
                        values: "",
                        selected: "125"
                    },
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "ms"
                    }           
                ]
            },
        },
        "IMSC Text Captions" : {
            name : "imscTextCaptions",
            options : {
                decode : [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Aspect Ratio",
                        type: "list",
                        values: ["4:3", "16:9"],
                        selected: "4:3"
                    },
                    {
                        name: "Program Name",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "monospaceSerif"
                    }, 
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "80"
                    },
                    {
                        name: "Line Height",
                        type: "number-input",
                        values: "",
                        selected: "100"
                    },
                    {
                        name: "Font Weight",
                        type: "text-input",
                        values: "",
                        selected: "normal"
                    },
                    {
                        name: "Font Style",
                        type: "text-input",
                        values: "",
                        selected: "normal"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "black"
                    },                      
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "smpte"
                    }           
                ]
            }
        },
        "IMSC Text Subtitles" : {
            name : "imscTextSubtitles",
            options : {
                decode : [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Extent X",
                        type: "number-input",
                        values: "",
                        selected: "720"
                    },
                    {
                        name: "Extent Y",
                        type: "number-input",
                        values: "",
                        selected: "480"
                    },
                    {
                        name: "Aspect Ratio",
                        type: "list",
                        values: ["4:3", "16:9"],
                        selected: "4:3"
                    },
                    {
                        name: "Program Name",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "monospaceSerif"
                    }, 
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "80"
                    },
                    {
                        name: "Line Height",
                        type: "number-input",
                        values: "",
                        selected: "100"
                    },
                    {
                        name: "Font Style",
                        type: "text-input",
                        values: "",
                        selected: "normal"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "black"
                    },                      
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "smpte"
                    }           
                ]
            }
        },
        "SMPTE TTML": {
            name: "smpteTtml",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "monospace"
                    }, 
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: "80"
                    },
                    {
                        name: "Font Weight",
                        type: "text-input",
                        values: "",
                        selected: "normal"
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "black"
                    },                      
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "smpte"
                    }           
                ]
            },
        },
        "Substation Alpha": {
            name: "subStationAlpha",
            options: {
                decode: [],
                encode: []
            },
        },
        "Advanced Substation Alpha": {
            name: "subStationAlpha",
            options: {
                encode: [
                    {
                        name: "Font Family",
                        type: "list",
                        values: ["serif", "sans-serif", "monospace", "cursive", "fantasy", "Arial", "Verdana", "Helvetica", "Tahoma", "Trebuchet MS", "Times New Roman", "Georgia", "Garamond", "Courier New", "Brush Script MT"],
                        selected: "monospace"
                    },
                    {
                        name: "Font Size",
                        type: "number-input",
                        values: "",
                        selected: 24
                    },
                    {
                        name: "Line Height",
                        type: "number-input",
                        values: "",
                        selected: 22
                    },
                    {
                        name: "Spacing",
                        type: "number-input",
                        values: "",
                        selected: 10
                    },
                    {
                        name: "Padding",
                        type: "number-input",
                        values: "",
                        selected: 7
                    },
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "#ffffff"
                    },
                    {
                        name: "Opacity",
                        type: "number-input",
                        values: "",
                        selected: 100
                    },
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "#000000"
                    },
                    {
                        name: "Background Opacity",
                        type: "number-input",
                        values: "",
                        selected: "100"
                    },
                    {
                        name: "Outline Color",
                        type: "text-input",
                        values: "",
                        selected: "#000000"
                    },
                    {
                        name: "Outline Opacity",
                        type: "number-input",
                        values: "",
                        selected: "100"
                    },
                    {
                        name: "Outline Size (px)",
                        type: "number-input",
                        values: "",
                        selected: "0"
                    },
                    {
                        name: "Shadow Size (px)",
                        type: "number-input",
                        values: "",
                        selected: "0"
                    }
                ],
                decode: []
            }
        },
        "Adobe Encore": {
            options: {
                decode: [],
                encode: []
            },
            name: "adobeEncore"
        },
        "Apple DVD Studio Pro": {
            options: {
                decode: [],
                encode: []
            },
            name: "appleDvdStudioPro"
        },
        "Avid DS": {
            options: {
                decode: [],
                encode: []
            },
            name: "avidDs"
        },
        "Cheetah (ASCII Text)": {
            options: {
                decode: [],
                encode: []
            },
            name: "cheetahAscii"
        },
        "Cheetah (binary)": {
            options: {
                decode: [],
                encode: []
            },
            name: "cheetahBin"
        },
        "Distribution Format Exchange Profile (DFXP)": {
            options: {
                decode: [],
                encode: []
            },
            name: "dfxp"
        },
        "Netflix DFXP": {
            options: {
                decode: [],
                encode: []
            },
            name: "netflixDfxp"
        },
        "DLP Cinema": {
            options: {
                decode: [],
                encode: [
                    {
                        name: "Title",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Reel Number",
                        type: "number-input",
                        values: "",
                        selected: "1"
                    },
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Fade Up Time",
                        type: "number-input",
                        values: "",
                        selected: "20"
                    },
                    {
                        name: "Fade Down Time",
                        type: "number-input",
                        values: "",
                        selected: "20"
                    },
                    {
                        name: "Vertical Position Start",
                        type: "number-input",
                        values: "",
                        selected: "10"
                    },
                    {
                        name: "Vertical Spacing",
                        type: "number-input",
                        values: "",
                        selected: "6"
                    },
                ]
            },
            name: "dlpCinema"
        },
        "DVD Architect": {
            options: {
                decode: [],
                encode: []
            },
            name: "dvdArch"
        },
        "EBU STL": {
            options: {
                decode: [],
                encode: [
                    {
                        name: "Display Standard Code",
                        type: "list",
                        values: ["Undefined", "Open subtitling", "Level-1 teletext", "Level-2 teletext"],
                        selected: "Open subtitling"
                    },
                    {
                        name: "Program Title",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Episode Title",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Translated Program Title",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Translated Episode Title",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Translator's Name",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Translator's Contact Details",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Subtitle List Ref Code",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Publisher",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Editor's Name",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Editor's Contact Details",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "GSI Character Set",
                        type: "list",
                        values: ["United States", "Multilingual", "Portuguese", "Canada-French", "Nordic"],
                        selected: "Multilingual"
                    },
                    {
                        name: "Character Code Table",
                        type: "list",
                        values: ["Latin", "Cyrillic", "Arabic", "Greek", "Hebrew"],
                        selected: "Latin"
                    },
                    {
                        name: "SOM",
                        type: "text-input",
                        values: "00:00:00:00",
                        selected: "00:00:00:00"
                    },
                ]
            },
            name: "ebuStl"
        },
        "EBU TT": {
            options: {
                decode: [],
                encode: []
            },
            name: "ebuTt"
        },
        "Eclipse" : {
            options : {
                decode : [],
                encode : []
            },
            name : "eclipse"
        },
        "EZ Title": {
            options: {
                decode: [],
                encode: []
            },
            name: "ezTitle"
        },
        "MacCaption 608/708": {
            options: {
                decode: [
                    {
                        name: "Caption Standard",
                        type: "list",
                        values: ["608", "708"],
                        selected: "608"
                    }
                ],
                encode: [
                    {
                        name: "Channel",
                        type: "list",
                        values: ["ch01", "ch02", "ch03", "ch04"],
                        selected: "ch01"
                    },
                    {
                        name: "MCC Version",
                        type: "list",
                        values: ["2.0", "1.0"],
                        selected: "2.0"
                    }
                ]
            },
            name: "macCaption"
        },
        "MAGIC TT": {
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Title",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Description",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                ]
            },
            name: "magicTt"
        },
        "Netflix TT Captions": {
            options: {
                decode: [],
                encode: []
            },
            name: "netflixTtCaptions"
        },
        "Netflix TT Subtitles": {
            options: {
                decode: [],
                encode: []
            },
            name: "netflixTtSubtitles"
        },

        "PowerPixel Format": {
            options: {
                decode: [],
                encode: []
            },
            name: "powerPixel"
        },
        "ProCap Transfer": {
            options: {
                decode: [],
                encode: []
            },
            name: "proCapTransfer"
        },
        "Synchronized Accessible Media Interchange": {
            options: {
                decode: [],
                encode: []
            },
            name: "sami"
        },
        "Scenarist V1.0": {
            name: "scenerist",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Channel",
                        type: "list",
                        values: ["ch01", "ch02", "ch03", "ch04"],
                        selected: "ch01"
                    },
                    {
                        name: "Line Endings",
                        type: "list",
                        values: ["windows", "unix", "macintosh"],
                        selected: "unix"
                    },
                    {
                        name: "Incode",
                        type: "text-input",
                        values: "",
                        selected: ""
                    },
                ]
            }
        },
        "Spruce STL": {
            options: {
                decode: [],
                encode: []
            },
            name: "spruceStl"
        },
        "ScreenSystems Poliscript": {
            options: {
                decode: [
                    {
                        name: "Code Page",
                        type: "list",
                        values: ["ISO 8859-1 Latin 1",
                            "ISO 8859-2 Latin 2",
                            "ISO 8859-3 Latin 3",
                            "ISO 8859-4 Baltic",
                            "ISO 8859-5 Cyrillic",
                            "ISO 8859-6 Arabic",
                            "ISO 8859-7 Greek"],
                        selected: "ISO 8859-1 Latin 1"
                    },
                    {
                        name: "Ignore Metadata",
                        type: "list",
                        values: [true, false],
                        selected: true
                    }
                ],
                encode: []
            },
            name: "ssPoliscript"
        },
        "SubRip Video Subtitle Script": {
            timecode: "ms",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Encode Formatting",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                ]
            },
            name: "subRip"
        },
        "SubViewer": {
            options: {
                decode: [],
                encode: []
            },
            name: "subViewer"
        },
        "Sofni": {
            options: {
                decode: [],
                encode: []
            },
            name: "sofni"
        },
        "Timed-Text Markup Language": {
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },        
                    {
                        name: "Font Color",
                        type: "text-input",
                        values: "",
                        selected: "white"
                    },     
                    {
                        name: "Background Color",
                        type: "text-input",
                        values: "",
                        selected: "black"
                    },  
                    {
                        name: "Font Family",
                        type: "text-input",
                        values: "",
                        selected: "monospace"
                    }, 
                    {
                        name: "Font Size (%)",
                        type: "number-input",
                        values: "",
                        selected: "80"
                    },
                    {
                        name: "Font Weight",
                        type: "text-input",
                        values: "",
                        selected: "normal"
                    },  
                    {
                        name: "Font Style",
                        type: "text-input",
                        values: "",
                        selected: "normal"
                    },                  
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "smpte"
                    },     
                ]
            },
            name: "ttml"
        },
        "Verizon TTML" : {
            options: {
                decode: [],
                encode: [
                    {
                        name: "Language Code",
                        type: "text-input",
                        values: "",
                        selected: "en"
                    },
                    {
                        name: "Line Height",
                        type: "number-input",
                        values: "",
                        selected: 5.33
                    },
                    {
                        name: "Timecode Format",
                        type: "list",
                        values: ["smpte", "ms"],
                        selected: "smpte"
                    },     
                    {
                        name: "608 Format",
                        type: "list",
                        values: ["yes", "no"],
                        selected: "no"
                    }
                ]
            },
            name: "ttmlVerizon"
        },
        "WebVTT": {
            timecode: "ms",
            options: {
                decode: [],
                encode: [
                    {
                        name: "Encode Id",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Position Template",
                        type: "list",
                        values: ["Default", "YouTube", "MUFI"],
                        selected: "Default"
                    },
                    {
                        name: "Encode Position",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Encode Formatting",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Encode Font Styles",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Encode Metadata Notes",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Metadata Notes",
                        type: "textarea",
                        values: "",
                        selected: ""
                    },
                    {
                        name: "Use HTML Tags",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Override Font Color",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Override Background Color",
                        type: "list",
                        values: [true, false],
                        selected: false
                    },
                    {
                        name: "Font Color",
                        type: "list",
                        values: ["white", "lime", "cyan", "red", "yellow", "magenta", "blue", "black"],
                        selected: "white"
                    },  
                    {
                        name: "Background Color",
                        type: "list",
                        values: ["white", "lime", "cyan", "red", "yellow", "magenta", "blue", "black"],
                        selected: "black"
                    }
                ]
            },
            name: "webVtt"
        },
        "Comma-Separated Values": {
            options: {
                decode: [
                    {
                        name: "Start Time",
                        type: "list",
                        values: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
                        selected: "A"

                    },
                    {
                        name: "End Time",
                        type: "list",
                        values: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
                        selected: "B"
                    },
                    {
                        name: "Text",
                        type: "list",
                        values: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
                        selected: "C"
                    },
                    {
                        name: "Start Time Format",
                        type: "list",
                        values: ["SMPTE Timecode (HH:MM:SS:FF)", "MS Timecode (HH:MM:SS.MSS)", "Seconds (SS)", "Timecode (HH:MM:SS)"],
                        selected: "SMPTE Timecode (HH:MM:SS:FF)"

                    },
                    {
                        name: "End Time Format",
                        type: "list",
                        values: ["SMPTE Timecode (HH:MM:SS:FF)", "MS Timecode (HH:MM:SS.MSS)", "Seconds (SS)", "Timecode (HH:MM:SS)"],
                        selected: "SMPTE Timecode (HH:MM:SS:FF)"

                    },
                ],
                encode: [

                ]
            },
            name: "csv"
        },
        "JSON": {
            options: {
                decode: [],
                encode: []
            },
            name: "json"
        }
    },
    binaryFormats: [
        "cheetahBin", "ebuStl", "ssPoliscript"
    ],
    defaultEncodingMap: {
        "vitac" : "utf-8",
        "subStationAlpha": "utf-8",
        "uDvd": "utf-8",
        "quickTimeText": "utf-8",
        "adobeEncore": "utf-8",
        "appleDvdStudioPro": "utf-8",
        "avidDs": "utf-16le",
        "cheetahBin": "hex",
        "cheetahAscii": "utf-8",
        "eclipse" : "utf-8",
        "dfxp": "utf-8",
        "dlpCinema": "utf-8",
        "dvdArch": "utf-16le",
        "ebuStl": "hex",
        "ebuTt": "utf-8",
        "ezTitle": "utf-8",
        "macCaption": "utf-8",
        "magicTt": "utf-8",
        "netflixTtCaptions": "utf-8",
        "netflixTtSubtitles": "utf-8",
        "netflixDfxp": "utf-8",
        "powerPixel": "utf-8",
        "proCapTransfer": "utf-8",
        "sami": "utf-8",
        "scenerist": "utf-8",
        "sofni": "utf-8",
        "spruceStl": "utf-8",
        "ssPoliscript": "hex",
        "subRip": "utf-8",
        "subViewer": "utf-8",
        "ttml": "utf-8",
        "ttmlVerizon" : "utf-8",
        "imscTextCaptions" : "utf-8",
        "imscTextSubtitles" : "utf-8",
        "hboGoTtml" : "utf-8",
        "hboMaxSmpte" : "utf-8",
        "ttmlLegacy" : "utf-8",
        "hboSmpteTtml" : "utf-8",
        "smpteTtml" : "utf-8",
        "webVtt": "utf-8",
        "json": "utf-8",
        "csv": "utf-8"
    },
    defaultExtMap: {
        "vitac" : "vnl",
        "subStationAlpha": "aas",
        "uDvd": "sub",
        "quickTimeText": "txt",
        "adobeEncore": "txt",
        "appleDvdStudioPro": "txt",
        "eclipse" : "txt",
        "avidDs": "txt",
        "cheetahBin": "cap",
        "cheetahAscii": "asc",
        "dfxp": "dfxp",
        "dlpCinema": "xml",
        "dvdArch": "sub",
        "ebuStl": "stl",
        "ebuTt": "xml",
        "ezTitle": "txt",
        "macCaption": "mcc",
        "magicTt": "xml",
        "netflixTtCaptions": "xml",
        "netflixTtSubtitles": "xml",
        "powerPixel": "txt",
        "proCapTransfer": "txt",
        "sami": "smi",
        "scenerist": "scc",
        "sofni": "sub",
        "spruceStl": "stl",
        "ssPoliscript": "pac",
        "subRip": "srt",
        "subViewer": "sub",
        "ttml": "ttml",
        "ttmlVerizon" : "ttml",
        "hboGoTtml" : "ttml",
        "hboMaxSmpte" : "xml",
        "ttmlLegacy" : "ttml",
        "hboSmpteTtml" : "ttml",
        "smpteTtml" : "ttml",
        "imscTextCaptions" : "ttml",
        "imscTextSubtitles" : "ttml",
        "webVtt": "vtt",
        "json": "json",
        "csv": "csv"
    },

    getSrcProfiles: function () {
        let profiles = [];
        let profileMapping = this.profileMapping;
        for (let [key, values] of Object.entries(this.srcProfiles)) {
            values.forEach(value => {
                profiles.push(profileMapping[value].name);
            })
        }
        return [...new Set(profiles)]
    },
    getTrgProfiles: function () {
        let profiles = [];
        let profileMapping = this.profileMapping;
        for (let [key, values] of Object.entries(this.trgProfiles)) {
            values.forEach(value => {
                profiles.push(profileMapping[value].name);
            });
        }

        return [...new Set(profiles)]
    },

    getProfileTitleByName: function (name) {
        for (let [key, value] of Object.entries(this.profileMapping)) {
            if (this.profileMapping[key].name === name) {
                return key;
            }
        }
        return (new Error(name + " is not a supported format. Please check casing or contact support"));
    },
}
