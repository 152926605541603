module.exports = function insertMissingOutcodes(eventGroup){
    eventGroup.events.forEach(function(event, i, events){
        if (!event.end && events[i+1]){
            events[i].end = events[i+1].start;
        } else if (!event.end){
            events[i].end = event.start + 5;
        }
    });

    return eventGroup;
}