const htmlEntities = require("html-entities");
/* Takes plaintext with no formatting */
module.exports = function htmlEncodePlainText(text) {
    text = text.replace(/<em>/g, "__ITALICZOPEN__");
    text = text.replace(/<\/em>/g, "__ITALICZCLOSE__");
    text = text.replace(/<strong>/g, "__BOLDZOPEN__");
    text = text.replace(/<\/strong>/g, "__BOLDZCLOSE__");
    text = text.replace(/<u>/g, "__UNDERLINEZOPEN__");
    text = text.replace(/<\/u>/g, "__UNDERLINEZCLOSE__");

    text = htmlEntities.encode(text);

    text = text.replace(/__ITALICZOPEN__/g, "<em>");
    text = text.replace(/__ITALICZCLOSE__/g, "</em>");
    text = text.replace(/__BOLDZOPEN__/g, "<strong>");
    text = text.replace(/__BOLDZCLOSE__/g, "</strong>");
    text = text.replace(/__UNDERLINEZOPEN__/g, "<u>");
    text = text.replace(/__UNDERLINEZCLOSE__/g, "</u>");

    return text;
}