const tcLib = require("../lib/timecode.js");
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const eol = require("eol");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const htmlEntities = require('html-entities'); //Encode
const xmlFormat = require('xml-formatter'); //Encode
const replaceLineEndings = require('../functions/quill/replaceLineEndings.js');
module.exports = {
    decode : function(input, options){
        throw new Error("This profile [HBO GO TTML] is not supported for decoding by Closed Caption Converter. Please use Timed-Text Markup Language (ttml)");
    },
    encode : function(eventGroup, options){
        let encodingOptions = getFormatOptions(options.formatOptions);
        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        
        output += eol.after(`<tt xmlns="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttp="http://www.w3.org/ns/ttml#parameter"  ttp:profile="http://www.w3.org/ns/ttml/profile/imsc1/text" xml:lang="${encodingOptions["Language Code"] || "en"}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}" ttp:timeBase="media">`);

        output += eol.after(`<head>`);
        output += eol.after(`<styling>`);

        /* S1 - Style 1 */
        output += eol.after(`<style xml:id="s1" tts:fontSize="${encodingOptions["Font Size"] || "16"}px" tts:fontFamily="${encodingOptions["Font Family"] || "proportionalSansSerif"}" tts:color="${encodingOptions["Font Color"] || "white"}" tts:backgroundColor="${encodingOptions["Background Color"] || "transparent"}" tts:showBackground="whenActive" tts:displayAlign="center" tts:textAlign="center" tts:lineHeight="${encodingOptions["Line Height"] || "100"}%" tts:wrapOption="noWrap"/>`);

        /* S2 - Style 2 */
        output += eol.after(`<style xml:id="s2" tts:fontSize="${encodingOptions["Font Size"] || "16"}px" tts:fontFamily="${encodingOptions["Font Family"] || "proportionalSansSerif"}" tts:fontStyle="italic" tts:color="${encodingOptions["Font Color"] || "white"}" tts:backgroundColor="${encodingOptions["Background Color"] || "transparent"}" tts:showBackground="whenActive" tts:displayAlign="center" tts:textAlign="center" tts:lineHeight="${encodingOptions["Line Height"] || "100"}%" tts:wrapOption="noWrap"/>`);

        output += eol.after(`</styling>`);
        output += eol.after(`<layout>`);
        output += eol.after(`<region xml:id="r1" tts:origin="10.00% 85.00%" tts:extent="80.00% 10.00%"/>`);
        output += eol.after(`<region xml:id="r2" tts:origin="10.00% 15.00%" tts:extent="80.00% 10.00%"/>`);
        output += eol.after(`</layout>`);
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += eol.after(`<div>`);
        
        /* EVENTS GO HERE */
        eventGroup.events.forEach(event => {
            let region = "r1", style = "s1";
            let startTime = encodingOptions["Timecode Format"] === "smpte" ? tcLib.secToTc(event.start, options.frameRate) : tcLib.secToTcMs(event.start);
            let endTime = encodingOptions["Timecode Format"] === "smpte" ? tcLib.secToTc(event.end, options.frameRate) : tcLib.secToTcMs(event.end);
            let eventText = replaceLineEndings(htmlEntities.encode(convertToPlainText(event.text)), "<br/>");

            /* If the top line position is at the top of the screen it goes in region 2 (otherwise we put it in region 1 at the bottom of the screen) */
            if (event.yPos === "start"){
                region = "r2";
            }

            if(event.text.search("<em>") > -1){
                style = "s2";
            }
            
            output += eol.after(`<p begin="${startTime.replace(",", ".")}" end="${endTime.replace(",", ".")}" region="${region}" style="${style}">${eventText}</p>`);

        });

        output += eol.after(`</div>`);
        output += eol.after(`</body>`);
        output += eol.after(`</tt>`);
        return (output);
        //return xmlFormat(output);
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return input;
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup){
            return eventGroup;
        }
    },
}