const frameRates = require('../dict/frameRates.js');
module.exports = {
    options: class {
        constructor (options = {
            profile: "subRip",
            formatOptions: [],
            frameRate: 29.97,
            dropFrame: true,
            incode: "00:00:00:00",
            offset: "00:00:00:00",
            offsetType: "add",
            encoding: "UTF-8",
            window: {
                width: 720,
                height: 480,
                xOffset: 0,
                yOffset: 0
            }
        }) {
            this.profile = options.profile || "subRip",
            this.formatOptions = options.formatOptions || [],
            this.frameRate = options.frameRate || 29.97,
            this.dropFrame = options.dropFrame || frameRates.dropFrameMap[this.frameRate],
            this.incode = options.incode || "00:00:00:00",
            this.offset = options.offset || "00:00:00:00",
            this.offsetType = options.offsetType || "add",
            this.encoding = options.encoding || "UTF-8",
            this.window = options.window || {
                width: 720,
                height: 480,
                xOffset: 0,
                yOffset: 0
            }
        }
    },

    frameRate: 29.97,
    dropFrame: true,
    incode: "00:00:00:00",
    offset: "00:00:00:00",
    offsetType: "add",
    trgExt: "srt",
    trgProfile: "subRip",
    encoding: "UTF-8",
    formatEncodingOptions: [],
    formatDecodingOptions: [],
    window: {
        width: 720,
        height: 480,
        xOffset: 0,
        yOffset: 0
    }
}