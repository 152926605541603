import Vue from 'vue'
import VueRouter from 'vue-router'
import Converter from '@/views/Converter.vue'
import Login from '@/views/Login.vue'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes : [
    {
      path: '/',
      name: 'converter',
      component: Converter,
      meta: {
        protected: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    }
  ]
})

export default router
