const Event = require("../../classes/event.js");
const hexToBinary = require("hex-to-binary");
const uuidv1 = require('uuid').v1;
const moment = require("moment");
const mccLookup = require("../../dict/708.js");
const eol = require("eol");
const convertToHtml = require("../quill/convertToHtml.js");
const quillClasses = require("../../dict/quillClasses.js");
const findCenter = require("../utility/findCenter.js");
const convertToPlainTextCustom = require("../quill/convertToPlainTextCustom.js");
const stripTags = require("../quill/stripTags.js");
const getLongestLine = require("../utility/getLongestLine.js");
const convertToPlainText = require("../quill/convertToPlainText.js");
const getShortestLine = require("../utility/getShortestLine.js");
module.exports = {
    decodeWindowData: function (window) {
        //console.log(JSON.stringify(window,null,4));
        let ccEvent = new Event();
        let positionInfo = this.getPositionInfo(window);
        ccEvent.start = window.start;
        ccEvent.end = window.end;
        ccEvent.alignment = positionInfo.alignment;
        ccEvent.xPos = positionInfo.xPos;
        ccEvent.xOffset = positionInfo.xOffset.toFixed(2);
        ccEvent.yPos = positionInfo.yPos;
        ccEvent.yOffset = positionInfo.yOffset.toFixed(2);
        ccEvent.text = this.formatText(window.lines, ccEvent.alignment);
        //console.log(ccEvent);
        return ccEvent;
    },
    getPositionInfo : function(window){
        let xPos, yPos, xOffset, yOffset, alignment = "center", details;
       
        details = this.getPosDetails(window.lines);
        alignment = this.getWindowAlignment(details);
        x = this.getXPos(window.lines, details, alignment);
        y = this.getYPos(window.lines);
        xPos = x.position;
        xOffset = x.offset;
        yPos = y.position;
        yOffset = y.offset;

        return {
            alignment: alignment,
            xPos : xPos,
            xOffset : xOffset,
            yPos : yPos,
            yOffset : yOffset
        }
    },
    getPosDetails : function(lines){
        return lines.map(line =>{
            let alignment;
            let center = findCenter(32, line.text.length);
            if (line.posX > center - 5 && line.posX < center + 5){
                alignment = "center";
            } else if (line.posX < center) {
                alignment = "left";
            } else {
                alignment = "right";
            }

            return {
                start : line.posX,
                end : parseFloat(line.posX) + parseFloat((line.text.length/32)*100),
                length : line.text.length,
                alignment : alignment
            };
        });
    },
    getWindowAlignment : function(details){
        let xPosSame = details.every(detail => {
            return detail.start === details[0].start;
        });

        let endSame = details.every(detail => {
            return detail.end === details[0].end;
        });

        let alignmentCenter = details.every(detail => {
            return detail.alignment === "center";
        });

        if (xPosSame) {
            return "left";
        } else if (alignmentCenter){
            return "center";
        } else if (endSame) {
            return "right";
        } else {
            return "center";
        }
    },
    /* used when decoding */
    getXPos : function(lines, details, alignment){
        let xInfo = {
            position : "center",
            offset : 0
        }

        let lowestXPos = Math.min.apply(Math, lines.map(function (line) { return line.posX; }));
        let highestEnd = Math.max.apply(Math, details.map(function (detail) { return detail.end; }));

        if (alignment === "left"){
            xInfo.position = "start"
            xInfo.offset = lowestXPos;
        } else if (alignment === "center"){
            xInfo.position = "center"
            xInfo.offset = 0;
        } else {
            xInfo.position = "end";
            xInfo.offset = -100 + highestEnd;
        }

        return xInfo;
    },
    /* used when decoding */
    getYPos : function(lines){
        let yInfo = {
            position : "end",
            offset : 0
        }

        let highestYPos = Math.max.apply(Math, lines.map(function (line) { return line.posY; }));
        let lowestYPos = Math.min.apply(Math, lines.map(function (line) { return line.posY; }));
        
        if (lowestYPos > 60) {
            yInfo.position = "end";
            yInfo.offset = highestYPos - 84.66;
        } else if (highestYPos < 40) {
            yInfo.position = "start"
            yInfo.offset = lowestYPos;
        } else {
            yInfo.position = "center";
            yInfo.offset = lowestYPos - findCenter(100, lines.length*5.33);
        }

        return yInfo;
    },
    formatText : function(lines, alignment = "center"){
        prefix = "";
        suffix = "";
        let text = lines.map(line =>{
            prefix += line.bold ? "<strong>" : "";
            prefix += line.italics ? "<em>" : "";
            prefix += line.underline ? "<u>" : "";
            suffix = suffix + (line.underline ? "</u>" : ""); 
            suffix = suffix + (line.italics ? "</em>" : "");
            suffix = suffix + (line.bold ? "</strong>" : "");
            
            return prefix + line.text + suffix;
        }).join("\n");
        
        return convertToHtml(text, [quillClasses.align[alignment]]);
    },
    decodeAncData: function (ancData) {
        let header = this.decodeHeader(ancData.splice(0, 20).join("")),
            ccData = this.decodeCcSection(ancData, header);

        return {
            header: header,
            ccData: ccData,
        };
    },
    decodeHeader: function (ancData) {
        return {
            ancDataFlag: ancData.substring(0, 4),
            filler: ancData.substring(4, 6),
            cdp_identifier: ancData.substring(6, 10),
            cdp_length: ancData.substring(10, 12),
            cdp_frame_rate: ancData.substring(12, 13),
            reserved01: ancData.substring(13, 14),
            tcFlag: hexToBinary(ancData.substring(14, 15)).substring(0, 1),
            ccFlag: hexToBinary(ancData.substring(14, 15)).substring(1, 2),
            ccActive: hexToBinary(ancData.substring(15, 16)).substring(3, 4),
            statusData: ancData.substring(14, 16),
            seqCounter: ancData.substring(16, 20),
        };
    },
    decodeCcSection: function (ancData, header) {
        if (header.ccFlag === "1") {
            let ccData_identifier = ancData.splice(0, 2).join("");
            let ccCount = parseInt(
                hexToBinary(ancData.splice(0, 2).join("")).substring(3),
                2
            );
            let ccPackets = [];
            for (var i = 0; i < ccCount; i++) {
                ccPackets.push(ancData.splice(0, 6).join(""));
            }

            return {
                ccData_identifier: ccData_identifier,
                ccCount: ccCount,
                ccPackets: ccPackets,
            };
        } else {
            return {};
        }
    },

    frameRateMapping: {
        29.97: {
            frames: 29.97,
            dropFrame: true,
            mccValue: "30DF",
            hexValue: "4",
            cc_count: 20,
            max608Bytes: 4,
            max708Bytes: 36,
        },
        59.94: {
            frames: 59.94,
            dropFrame: true,
            mccValue: "60DF",
            hexValue: "7",
            cc_count: 10,
            max608Bytes: 2,
            max708Bytes: 18,
        },
        23.976: {
            frames: 23.98,
            dropFrame: false,
            mccValue: "24",
            hexValue: "1",
            cc_count: 25,
            max608Bytes: 4,
            max708Bytes: 44,
        },
        24: {
            frames: 24,
            dropFrame: false,
            hexValue: "2",
            cc_count: 25,
            mccValue: "24",
            max608Bytes: 4,
            max708Bytes: 44,
        },
        25: {
            frames: 25,
            dropFrame: false,
            hexValue: "3",
            mccValue: "25",
            cc_count: 24,
            max608Bytes: 4,
            max708Bytes: 44,
        },
        "30DF": {
            frames: 29.97,
            dropFrame: true,
            hexValue: "4",
            mccValue: "30DF",
            cc_count: 20,
            max608Bytes: 4,
            max708Bytes: 36,
        },
        30: {
            frames: 30,
            dropFrame: false,
            hexValue: "5",
            mccValue: "30",
            cc_count: 20,
            max608Bytes: 4,
            max708Bytes: 36,
        },
        50: {
            frames: 50,
            dropFrame: false,
            hexValue: "6",
            cc_count: 12,
            mccValue: "50",
            max608Bytes: 2,
            max708Bytes: 22,
        },
        "60DF": {
            frames: 59.94,
            dropFrame: true,
            hexValue: "7",
            cc_count: 10,
            mccValue: "60DF",
            max608Bytes: 2,
            max708Bytes: 18,
        },
        60: {
            frames: 60,
            dropFrame: false,
            hexValue: "8",
            cc_count: 10,
            mccValue: "60",
            max608Bytes: 2,
            max708Bytes: 18,
        },
    },
    windowStyleMapping: {
        1: "Pop-On",
        2: "Pop-On",
        3: "Pop-On",
        4: "Roll-Up",
        5: "Roll-Up",
        6: "Roll-Up",
        7: "Paint-On",
    },

    alignmentMap: {
        0: "left",
        1: "right",
        2: "center",
        3: "center",
    },

    ccTypeMapping: {
        /* Reference Pg.11 of  ANSI-CTA-708-E-R-2018-Final_pdf.pdf  */
        11111000: "F8" /* Do Nothing - but this is 608 data */,
        11111100: "FC" /* 608 Captions (both Bytes) */,
        11111001: "F9" /* Do Nothing - but this is 608 data */,
        11111101: "FD" /* 608 Field 2 Closed Captioning */,
        11111010: "FA" /* Padding Data */,
        11111110: "FE" /* Both are 708 captions */,
        11111011: "FB" /* Padding Bytes */,
        11111111:
            "FF" /* Start of 708 - first byte is header and second bute is data */,
    },
    replaceShortForms: function (ancStream) {
        let decodedData = "";
        ancStream.split("").forEach((char) => {
            if (this.charToByte[char] !== undefined) {
                decodedData += this.charToByte[char];
            } else {
                decodedData += char;
            }
        });

        return decodedData;
    },
    charToByte: {
        G: "FA0000",
        H: "FA0000FA0000",
        I: "FA0000FA0000FA0000",
        J: "FA0000FA0000FA0000FA0000",
        K: "FA0000FA0000FA0000FA0000FA0000",
        L: "FA0000FA0000FA0000FA0000FA0000FA0000",
        M: "FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
        N: "FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
        O: "FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000FA0000",
        P: "FB8080",
        Q: "FC8080",
        R: "FD8080",
        S: "9669",
        T: "6101",
        U: "E10000",
        Z: "00",
    },
    windowStyleMap : {
        "Pop-On" : {
            "left" : 1,
            "center" : 3,
            "right" :  3,
        },
        "Paint-On" : {
            "left" : 4,
            "center" : 6,
            "right" : 6,
        },
        "Roll-Up" : {
            "left" : 4,
            "center" : 6,
            "right" : 6,
        },
    },
    getFrameRateFromFile: function (input) {
        let frameRateFlag = false,
            frameRate,
            fileLine,
            fileLines = eol.split(input);
        while (!frameRateFlag && fileLines.length > 0) {
            fileLine = fileLines.shift();
            if (
                fileLine.split("=").length === 2 &&
                fileLine.split("=")[0].toLowerCase() === "time code rate"
            ) {
                frameRate = this.frameRateMapping[fileLine.split("=")[1].trim()];
                frameRateFlag = true;
            }
        }

        return frameRate;
    },
    generateMccFileHeader: function (mccVersion, frameRate) {
        return (
            this.mccFileHeader(mccVersion) +
            this.mccFileDescription +
            this.mccFileUuid +
            "\n" +
            this.mccFileCreation +
            "\n" +
            this.mccFileDate +
            "\n" +
            this.mccFileTime +
            "\n" +
            this.mccFileTc(frameRate) +
            "\n"
        );
    },
    mccFileHeader: function (version) {
        return "File Format=MacCaption_MCC V" + version + "\n\n";
    },
    mccFileDescription:
        "///////////////////////////////////////////////////////////////////////////////////\n// Computer Prompting and Captioning Company\n// Ancillary Data Packet Transfer File\n//\n// Permission to generate this format is granted provided that\n//   1. This ANC Transfer file format is used on an as-is basis and no warranty is given, and\n//   2. This entire descriptive information text is included in a generated .mcc file.\n//\n// General file format:\n//   HH:MM:SS:FF(tab)[Hexadecimal ANC data in groups of 2 characters]\n//     Hexadecimal data starts with the Ancillary Data Packet DID (Data ID defined in S291M)\n//       and concludes with the Check Sum following the User Data Words.\n//     Each time code line must contain at most one complete ancillary data packet.\n//     To transfer additional ANC Data successive lines may contain identical time code.\n//     Time Code Rate=[24, 25, 30, 30DF, 50, 60, 60DF]\n//\n//   ANC data bytes may be represented by one ASCII character according to the following schema:\n//     G  FAh 00h 00h\n//     H  2 x (FAh 00h 00h)\n//     I  3 x (FAh 00h 00h)\n//     J  4 x (FAh 00h 00h)\n//     K  5 x (FAh 00h 00h)\n//     L  6 x (FAh 00h 00h)\n//     M  7 x (FAh 00h 00h)\n//     N  8 x (FAh 00h 00h)\n//     O  9 x (FAh 00h 00h)\n//     P  FBh 80h 80h\n//     Q  FCh 80h 80h\n//     R  FDh 80h 80h\n//     S  96h 69h\n//     T  61h 01h\n//     U  E1h 00h 00h 00h\n//     Z  00h\n//\n///////////////////////////////////////////////////////////////////////////////////\n\n",
    mccFileUuid: "UUID=" + uuidv1(),
    mccFileCreation: "Creation Program=Closed Caption Converter V3",
    mccFileDate: "Creation Date=" + moment().format("dddd[,] MMMM DD[,] YYYY"),
    mccFileTime: "Creation Time=" + moment().format("hh:mm:ss"),
    mccFileTc: function (frameRate) {
        return "Time Code Rate=" + this.frameRateMapping[frameRate].mccValue;
    },
    packetCounter: {
        count: 0,
        counts: {
            0: "00",
            1: "01",
            2: "10",
            3: "11"
        },
        getCount: function () {
            var data = this.counts[this.count];
            this.count++;
            if (this.count > 3) {
                this.count = 0;
            }
            return data;
        }
    },
    defineWindow: function (event, window, line, posY) {
        // example codes (0): 98,00,41,00,00,1F,11
        // example codes (1): 99,00,41,00,00,1F,11,92,00,01,90,05,00,61,6E,03
        let defineWindowCmd = [];
        let verticalAnchor = Math.floor(((posY / 100) * 74)).toString(16); /* Vertical Anchor is value between 0-74 */
        defineWindowCmd.push(
            this.getCodeByCmd(mccLookup.cmds, "{DF-" + window + "}")
        );
        if (event.style === "Pop-On") {
            defineWindowCmd.push(
                "00"
            ); /* visible = false, row lock = false, column lock = false, priority = 0, relative position = true */
        } else {
            defineWindowCmd.push(
                "80"
            ); /* visible = true, row lock = false, column lock = false, priority = 0, relative position = true */
        }
        defineWindowCmd.push(verticalAnchor.padStart(2, '0')); /* Anchor vertical */
        defineWindowCmd.push("00"); /* Anchor Horz */
        defineWindowCmd.push("0" + line); /* Anchor Point, Row Count */
        defineWindowCmd.push("1F"); /* Column Count */
        defineWindowCmd.push("11"); /* Window Style + Pen Style */
        return defineWindowCmd;
    },
    setPenLocation: function (columnPercent, lineNumber) {
        // example codes: 92 (SPL),00,01
        let penLocationCmd = [];
        penLocationCmd.push("92");
        penLocationCmd.push("0" + lineNumber);
        penLocationCmd.push(Math.floor(((columnPercent / 100) * 32)).toString(16).padStart(2, "0"));
        return penLocationCmd;
    },
    setPenAttributes: function (ccEvent, encodedCmds) {
        // example codes: 90 (SPA),05,00
        let penAttributesCmd = [];
        penAttributesCmd.push("90");
        penAttributesCmd.push("05");
        penAttributesCmd.push("00");
        return penAttributesCmd;
    },
    encodeText: function (text) {
        let txtCmd = [];
        let self = this;
        let endFlag = 1;
        text.split("").forEach(function (char, index) {
            txtCmd.push(self.getCodeByCmd(mccLookup.cmds, char));
            if (index === endFlag || text.split("").length - 1 == index) {
                txtCmd.push("03");
                endFlag += 2;
            }
        });
        //console.log(text,txtCmd);
        return txtCmd;
    },
    deleteWindows: function (visibleWindows) {
        let deleteWindowCmd = ["8C"],
            param = [];

        for (let i = 0; i < 8; i++) {
            if (visibleWindows.indexOf(i.toString()) > -1) {
                param.push("0");
            } else {
                param.push("1");
            }
        }

        deleteWindowCmd.push(
            this.binToHex(param.reverse().join("")).padStart(2, 0)
        );
        return deleteWindowCmd;
    },
    clearWindows: function (visibleWindows) {
        let clearWindowCmd = ["88"],
            param = [];

        for (let i = 0; i < 8; i++) {
            if (visibleWindows.indexOf(i.toString()) > -1) {
                param.push("1");
            } else {
                param.push("0");
            }
        }

        clearWindowCmd.push(this.binToHex(param.reverse().join("")).padStart(2, 0));
        return clearWindowCmd;
    },
    resetWindows: function () {
        return ["8f"];
    },
    carriageReturn: function () {
        return ["0d"];
    },
    toggleWindows: function (selectedWindow) {
        let toggleWindowCmd = ["8B"],
            param = [];

        for (let i = 0; i < 8; i++) {
            if (selectedWindow.indexOf(i.toString()) > -1) {
                param.push("1");
            } else {
                param.push("0");
            }
        }

        toggleWindowCmd.push(this.binToHex(param.reverse().join("")).padStart(2, 0));
        //console.log(toggleWindowCmd);
        return toggleWindowCmd;
    },
    encodeCcsvcInfoSection: function () {
        return "73D2E02020207E3FFFE1656E67C13FFF";
    },
    encodeCdpFooter: function (sequenceCounter, packet) {
        var data = "74";
        data += sequenceCounter;
        var packetDecoded = "";
        packet
            .substring(6)
            .toUpperCase()
            .split("")
            .forEach((ancChar) => {
                if (this.charToByte[ancChar] != undefined) {
                    packetDecoded += this.charToByte[ancChar];
                } else {
                    packetDecoded += ancChar;
                }
            });

        var checkSum = this.calcChecksum(packetDecoded);
        //console.log(packetDecoded, checkSum);
        data += checkSum;
        data += "BB";
        return data;
    },
    calcChecksum: function (hexstring) {
        /* hex string is from 9669 for 176 chars (inclusive) */
        var hexArray = hexstring.split("");
        var total = 0;
        for (var i = 0; i < hexArray.length; i++) {
            total += parseInt("0x" + hexArray[i] + hexArray[++i]);
        }
        var binary = total.toString(2);
        /* take the last 8 bits */
        binary = binary.substring(binary.length - 8);
        var lastOne = binary.lastIndexOf("1");
        if (lastOne == -1) {
            return "00";
        }
        binary = binary.split("");
        var checkSum = "";
        var found = false;
        for (var k = 0; k < binary.length; k++) {
            if (k == lastOne) {
                checkSum += binary[k];
                found = true;
            } else if (found) {
                checkSum += binary[k];
            } else {
                if (binary[k] == "0") {
                    checkSum += "1";
                } else {
                    checkSum += "0";
                }
            }
        }
        checkSum = parseInt(checkSum, 2).toString(16).toUpperCase();
        if (checkSum.length < 2) {
            return "0" + checkSum;
        } else {
            return checkSum;
        }
    },
    getCodeByCmd: function (codes, cmd) {
        let mccCode = Object.keys(codes).find((code) => codes[code] === cmd);
        if(!mccCode){
            if (cmd==="'" || cmd === "’"){
                return "27";
            } else if (cmd.charCodeAt() === 65533){
                return "27";
            }

            //console.log(cmd, cmd.charCodeAt())
        } 
        
        return mccCode || "20";
    },
    binToHex: function (binaryNumber) {
        return parseInt(binaryNumber, 2).toString(16).toUpperCase();
    },
    encodeCdpHeader: function (frameCount, frameRate) {
        let header = "6101599669";
        header += "59"; //cdp_length
        header += this.frameRateMapping[frameRate.toString()].hexValue;
        header += "F"; //Reserved
        header += "7"; //time_code_present to scv_info_start
        header += "7"; //svc_info_change to Reserved;
        header += (frameCount.toString(16).padStart(4, 0)).slice(-4);
        return header;
    },
    generateCcPackets: function (ccCount, sccData, dtvData) {
        let ccPacketData = {
            prefix: ["72"],
            header: [],
            708: [],
            608: [],
            padding: []
        }

        let endFlag = false;
        let packetInfo;
        let packetCount = 0;

        ccPacketData.prefix.push(parseInt("111" + ccCount.toString(2), 2).toString(16)); //e.g. 72F4

        for (packetCount; packetCount < ccCount; packetCount++) {
            if (packetCount === 0) {
                packetInfo = "FC";
                if (sccData.length > 0) {
                    if (sccData[0].length === 4) {
                        packetInfo += sccData.shift();
                    } else {
                        if (sccData[1] && sccData[1].length === 2) {
                            packetInfo += sccData.shift();
                            packetInfo += sccData.shift();
                        } else {
                            packetInfo += sccData.shift() + "80";
                        }
                    }
                } else {
                    packetInfo += "8080";
                }

                ccPacketData[608].push(packetInfo);
            } else if (packetCount === 1) {
                packetInfo = "FD8080";
                ccPacketData[608].push(packetInfo);
            } else if (endFlag || packetCount > 10) {
                packetInfo = "FA0000";
                ccPacketData.padding.push(packetInfo);
            } else if (packetCount === 2 && dtvData.length > 0) {
                packetInfo = "FF";

                ccPacketData.header.push(packetInfo);
            } else if (packetCount === 3 && dtvData.length > 0) {
                packetInfo = "FE";
                if (dtvData[0] === "98" || dtvData[0] === "99") {
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetInfo += "FE";
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetInfo += "FE";
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetInfo += "FE";
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetInfo += "FE";
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetInfo += "FE";
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetInfo += "FE";
                    packetInfo += dtvData.shift();
                    packetInfo += "00";
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    packetCount = 9;
                    endFlag = true;
                } else if (dtvData[0].toLowerCase() === "8c" || dtvData[0] == "88") {
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    endFlag = true;
                } else {
                    if (dtvData[0] === "03") {
                        packetInfo += dtvData.shift();
                        packetInfo += "00";
                        ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                        endFlag = true;
                    } else if (dtvData[1] && dtvData[1] === "03") {
                        packetInfo += dtvData.shift();
                        packetInfo += dtvData.shift();
                        ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                        endFlag = true;
                    } else {
                        packetInfo += dtvData.shift();
                        packetInfo += dtvData.shift() || "00";
                        ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));
                    }
                }
            } else if (dtvData.length > 0) {
                packetInfo = "FE";
                if (dtvData[0] === "03") {
                    packetInfo += dtvData.shift();
                    packetInfo += "00";
                    endFlag = true;
                } else if (dtvData[1] && dtvData[1] === "03") {

                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift();
                    endFlag = true;
                } else {
                    packetInfo += dtvData.shift();
                    packetInfo += dtvData.shift() || "00";
                }

                ccPacketData[708].push(packetInfo.substr(packetInfo.length - 6));

            } else {
                packetInfo = "FA0000";
                ccPacketData.padding.push(packetInfo);
            }
        }
        //console.log(ccPacketData);
        if (ccPacketData.header.length > 0) {
            let sequenceNumber = this.packetCounter.getCount() + (ccPacketData[708].length + 1).toString(2).padStart(6, "0");
            sequenceNumber = parseInt(sequenceNumber, 2).toString(16).toUpperCase().padStart(2, '0');

            let packetDataSize = "0010" + (((ccPacketData[708].length * 2) - 0).toString(2).padStart(4, "0"));
            packetDataSize = parseInt(packetDataSize, 2).toString(16).toUpperCase().padStart(2, '0');
            ccPacketData.header = ccPacketData.header[0] + sequenceNumber + packetDataSize;
            return (ccPacketData.prefix.join("") + ccPacketData[608].join("") + ccPacketData.header + ccPacketData[708].join("") + ccPacketData.padding.join("")).toUpperCase();
        } else {
            return (ccPacketData.prefix.join("") + ccPacketData[608].join("") + ccPacketData.padding.join("")).toUpperCase();
        }
    },

    encodeVancData: function (frameCount, frameRate, ccCount, sccData, dtvData) {
        let cdpHeader = this.encodeCdpHeader(frameCount, frameRate);
        let svcInfoSection = this.encodeCcsvcInfoSection();
        let ccDataSection = this.generateCcPackets(ccCount, sccData, dtvData);
        let cdpFooter = this.encodeCdpFooter(
            cdpHeader.substr(cdpHeader.length - 4),
            cdpHeader +
            ccDataSection +
            svcInfoSection +
            "74" +
            cdpHeader.substr(cdpHeader.length - 4)
        );

        return (
            cdpHeader +
            ccDataSection +
            svcInfoSection +
            cdpFooter
        ).toUpperCase();
    },
    encodeWindow : function(event, win){
        let window = {
            start : event.start,
            end : event.end,
            style : event.style,
            lines : [],
            xPos : 0,
            yPos : 0,
            alignment : event.alignment
        }

        let plainTextCustom = convertToPlainTextCustom(event.text);
        eol.split(plainTextCustom).forEach((line, index, lines) => {
            let xPos = this.calcXPos(event, stripTags(line), win);
            let yPos = this.calcYPos(event, index, lines.length, win);
            window.lines.push({
                text : stripTags(line),
                bold : /<strong>/.test(line),
                italics : /<em>/.test(line),
                underline : /<u>/.test(line),
                posX : xPos,
                posY : yPos
            });
        });

        return window;
    },
    calcXPos : function(event, text, win){
        let xPos;
        let longestLine = getLongestLine(convertToPlainText(event.text));
        let xOffset = (event.xOffset/win.width) * 100;
        if (event.xPos === "start"){
            xPos = 0;
        } else if (event.xPos === "center"){
            xPos = (findCenter(32, text.length)/32) * 100;
        } else {
            xPos = ((32-text.length) / 32) * 100;
        }
        
        return xPos+xOffset;
    },
    calcYPos : function(event, index, totalLines, win){
        let yPos, yOffset = (event.yOffset/win.height) * 100, lOffset = index*6.66;
        if (event.yPos === "start"){
            yPos = lOffset;
        } else if (event.yPos === "center"){
            yPos = ((findCenter(15, totalLines)/15) * 100) + lOffset;
        } else {
            yPos = 100 - ((totalLines-index)*6.66);
        }

        return yPos;
    }
};
