const Event = require("../../classes/event.js");
const orderByTime = require("../eventGroups/orderByTime.js");
const tcLib = require("../../lib/timecode.js");
module.exports = function conform(eventGroup, options){
    if (options.segments.length === 0){
        return eventGroup;
    }

    //console.log(JSON.stringify(eventGroup.events, null, 4));

    let filteredEvents = [], segmentGroups = [], prevEndTime  = 0, jobOffset = 0, minStartTime, maxStartTime, incodeOffset = 0;

    let firstFileSegment = options.segments.find(segment => {return segment.source.toLowerCase() === "file"});
    /* Determine the incode offset */
    if (options.incode === "auto"){
        eventGroup = orderByTime(eventGroup);
        let startOfFirstEvent = eventGroup.events[0].start;
        let incodeSecRounded = Math.round(startOfFirstEvent/3600) * 3600;

        if (firstFileSegment){
            incodeOffset = tcLib.tcToSec(firstFileSegment.startTime, options.frameRate) - incodeSecRounded;
        }
        
    } else if (options.incode){
        if (firstFileSegment){
            incodeOffset = tcLib.tcToSec(firstFileSegment.startTime, options.frameRate) - tcLib.tcToSec(options.incode, options.frameRate);
        }
    }

    options.segments.forEach(segment =>{
        if (segment.source.toLowerCase() === "file") {
            let segmentGroup = {
                posOffset: jobOffset,
                negOffset: 0,
                events: []
            };

            minStartTime = tcLib.tcToSec(segment.startTime, options.frameRate);
            maxStartTime = tcLib.tcToSec(segment.endTime, options.frameRate);

            if (prevEndTime) {
                if (segmentGroups.length > 0){
                    segmentGroup.negOffset = (minStartTime - prevEndTime) + (segmentGroups[segmentGroups.length-1].negOffset);
                } else {
                    segmentGroup.negOffset = minStartTime - prevEndTime;
                }
            }

            prevEndTime = maxStartTime;
            let copyEvents = JSON.parse(JSON.stringify(eventGroup.events));
            segmentGroup.events = copyEvents.filter(event => {   
                if ((event.start >= minStartTime || event.end > minStartTime) && event.start <= maxStartTime){
                    if (event.end > maxStartTime && event.start < maxStartTime){
                        event.end = maxStartTime;
                    } else if (event.start < minStartTime && event.end > minStartTime){
                        event.start = minStartTime;
                    }

                    return true;
                }
            });

            segmentGroups.push(segmentGroup);

        } else if (segment.source.toLowerCase() === "black") {
            jobOffset += tcLib.tcToSec(segment.duration, options.frameRate);
        } else {
            console.log("Segment source ["+segment.source+"] not recognized. Ignoring segment");
        }
    });

    segmentGroups.forEach(group=>{
        group.events.forEach(function(event, i, events){
            events[i].start += group.posOffset;
            events[i].end += group.posOffset;
            events[i].start -= group.negOffset;
            events[i].end -= group.negOffset;
            events[i].start -= incodeOffset;
            events[i].end -= incodeOffset;

            events[i].start = events[i].start.toFixed(2); 
            events[i].end = events[i].end.toFixed(2); 
            filteredEvents.push(event);
        });
    });
    
    /* Remove duplicates and update the end time of the event */
    for (let count = filteredEvents.length-1; count > 0; count--){
        if (filteredEvents[count].id === filteredEvents[count-1].id){
            filteredEvents[count-1].end = filteredEvents[count].end;
            filteredEvents.splice(count,1);
        }
    }
    
    eventGroup.events = filteredEvents;
    //console.log(JSON.stringify(eventGroup.events, null, 4));
    return eventGroup;
}