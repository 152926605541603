const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const flexbox = require("../dict/flexbox.js");
const vttFunc = require('../functions/profiles/webVtt.js');

module.exports = {
    decode: function (input, options) {
        let events = [];
        let subtitleBlocks = input.split("\n\n");
        subtitleBlocks.forEach(subtitleBlock => {
            let tcFlag = false;
            let alignment = "center";
            let posX = false;
            let posY = false;

            let ccEvent = new Event();

            let blockLines = subtitleBlock.split("\n").filter(blockLine => {
                return blockLine.trim();
            });

            if (blockLines.length > 1) {
                blockLines.forEach(blockLine => {
                    if (!tcFlag && blockLine.split(" --> ").length > 1) {
                        tcFlag = true;
                        let lineInfo = blockLine.split(" ");
                        let tcIn = "00:" + lineInfo[0];
                        let tcOut = "00:" + lineInfo[2];

                        ccEvent.start = tcLib.tcMsToSec(tcIn.substring(tcIn.length - 12, tcIn.length));
                        ccEvent.end = tcLib.tcMsToSec(tcOut.substring(tcOut.length - 12, tcOut.length));

                        /* Check to see if the last event has the same timecode as the current event. If so... copy the text from the last event to the current event and remove it from the events list */
                        if (events.length > 0 && (ccEvent.start === events[events.length - 1].start || ccEvent.end === events[events.length - 1].end)) {
                            ccEvent.text = events[events.length - 1].text;
                            events.pop();
                        }

                        /* Check for extra metadata */
                        if (lineInfo.length > 3) {
                            lineInfo.forEach(info => {
                                if (/align:/g.test(info)) {
                                    let alignmentMetadata = info.split(":")[1].replace(",", "");
                                    ccEvent.alignment = flexbox.flexMap.horizontal[alignmentMetadata] || "center";
                                    ccEvent.xPos = flexbox.flexMap.horizontal[alignmentMetadata] || "center";
                                } else if (/position:/g.test(info)) {
                                    let positionMetadata = info.split(":")[1].split(",")[0].replace(/%|,/gmi, "");
                                    if (!isNaN(parseInt(positionMetadata))) {
                                        let pos = parseInt(positionMetadata);
                                        if (pos < 35) {
                                            ccEvent.yPos = "start";
                                        } else if (pos < 65) {
                                            ccEvent.yPos = "center";
                                        } else {
                                            ccEvent.yPos = "end"
                                        }
                                    }
                                } /* else if (/line:/g.test(info)) {
                                    posY = parseFloat(info.split(":")[1].replace(/%|,/gmi, ""));
                                } */
                            });
                        }


                    } else if (tcFlag) {
                        ccEvent.text += blockLine + "\n";
                    }
                });
            }

            if (tcFlag) {
                ccEvent.text = convertToHtml(ccEvent.text.trim());
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions), formatting, fontStyleCues = false, overrideFontColor = false, overrideBackgroundColor = false, htmlTags = false, encodeId = false, fontColor = "white", backgroundColor = "black";

        if (encodingOptions["Encode Id"] && encodingOptions["Encode Id"] !== "false") {
            encodeId = encodingOptions["Encode Id"];
        }

        if (encodingOptions["Use HTML Tags"] && encodingOptions["Use HTML Tags"] !== "false") {
            htmlTags = encodingOptions["Use HTML Tags"];
        }

        if (encodingOptions["Override Font Color"] && encodingOptions["Override Font Color"] !== "false") {
            overrideFontColor = encodingOptions["Override Font Color"];
        }

        if (encodingOptions["Override Background Color"] && encodingOptions["Override Background Color"] !== "false") {
            overrideBackgroundColor = encodingOptions["Override Background Color"];
        }

        if (encodingOptions["Font Color"]) {
            fontColor = encodingOptions["Font Color"];
        }

        if (encodingOptions["Background Color"]) {
            backgroundColor = encodingOptions["Background Color"];
        }

        let output = "WEBVTT\n\n";

        if (encodingOptions["Encode Font Styles"] && encodingOptions["Encode Font Styles"] !== "false") {
            fontStyleCues = true;
            output += vttFunc.fontStyleCues + "\n\n";
        }

        if (encodingOptions["Encode Metadata Notes"] && encodingOptions["Metadata Notes"]) {
            output += "NOTE\n";
            output += encodingOptions["Metadata Notes"] + "\n\n";
        }

        eventGroup.events.forEach(function (event, eventIndex) {
            let eventText;
            let plainText = convertToPlainText(event.text);
            let numberOfLines = eol.split(plainText).length;
            if (encodeId) {
                output += `${eventIndex + 1}` + "\n";
            }

            output += tcLib.secToTcMs(event.start).replace(",", ".") + " --> " + tcLib.secToTcMs(event.end).replace(",", ".");

            if (encodingOptions["Encode Position"] && encodingOptions["Encode Position"] != "false") {
                let line = vttFunc.calcLineValue(event, numberOfLines, options.window);
                let alignment = "center";

                if (event.alignment === "left") {
                    alignment = "right"
                } else if (event.alignment === "right") {
                    alignment = "left"
                }

                switch (encodingOptions["Position Template"]) {
                    case "YouTube":
                        /* eg. line:50% align:middle size:35% | line = y position, align = x position (left, middle, right)*/
                        output += ` line:${line}% align:${alignment} size:35%`;
                        break;
                    case "MUFI":
                        /* eg. line:78% position:50% align:middle*/
                        output += ` line:${line}% position:50% align:${alignment === 'center' ? 'middle' : alignment}`;
                        break;
                    default:
                        output += " align:" + event.alignment + ", line:" + line + "%";
                }
            }

            if (encodingOptions["Encode Formatting"] && encodingOptions["Encode Formatting"] !== "false") {
                eventText = convertToPlainTextCustom(event.text, "\n", false, "b", "i", "u");
            } else {
                eventText = convertToPlainTextCustom(event.text, "\n", true);
            }

            eventText.split("\n").filter(lineText => {return lineText;}).forEach(line =>{
                if (fontStyleCues && (overrideFontColor || overrideBackgroundColor)) {
                    output += htmlTags ? "\n<font" : "\n<c";
                    if (overrideFontColor) {
                        output += htmlTags ? ` color="${fontColor}"` : "." + fontColor;
                    }
    
                    if (overrideBackgroundColor) {
                        output += htmlTags ? ` background-color="${backgroundColor}"` : ".bg_" + backgroundColor;
                    }
    
                    output += ">" + line + (htmlTags ? "</font>" : "</c>");
                } else if (fontStyleCues && event.color !== "#FFFFFF" && vttFunc.colorMapping[event.color]) {
                    output += "\n<c." + vttFunc.colorMapping[event.color] + ">";
                    output += ">" + line + "</c>";
                } else {
                    output += "\n" + line;
                }
            });

            output += "\n\n";
        });

        return output.trim();
    },

    preProcess: {
        encode: function (eventGroup) {
            /* All */
            return removeInvalidEvents(eventGroup);

        },

        decode: function (input) {
            return (eol.lf(input).trim()).replace(/'(\n){3,}'/gim, "\n\n");
        }
    },

    postProcess: {
        encode: function (output) {
            return output.replace(new RegExp('(\n){3,}', 'gim'), "\n\n");
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}