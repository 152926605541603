module.exports = {
    decToHex : function(value){
        return Math.floor((value/100)*255).toString(16).padStart(2, "0");
    },
    encodeColor : function(hexColor, opacityValue){
        let alpha = this.decToHex((100-opacityValue));
        hexColor = hexColor.replace("#", "");
        let bgr = hexColor.substring(4) + hexColor.substring(2,4) + hexColor.substring(0,2);
        return `&H${alpha.toUpperCase()}${bgr.toUpperCase()}`;
    },
    encodeOutline : function(paddingValue){
        return paddingValue; 
    },
    calcLeftOffset : function(offset){
        return offset;
    },
    calcVertOffset : function(offset){
        return offset;
    },
    formatText : function(text){
        return text.replace(/\r?\n/g, "\\N");
    },
    styleMap : {
    /* Vert */
        start : {
            /* Horz */
            start : "TopLeft",
            end : "TopRight",
            center : "TopCenter"
        },
        end : {
            start : "BottomLeft",
            end : "BottomRight",
            center : "BottomCenter"

        },
        center : {
            start : "CenterLeft",
            end : "CenterRight",
            center : "CenterCenter"
        }
    }    
}