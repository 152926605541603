const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const eol = require("eol");
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const ttmlFunc = require("../functions/profiles/ttmlGeneral.js");
const htmlEncodePlainText = require("../functions/utility/htmlEncodePlainText.js");

module.exports = {
    decode: function (input, options) {
        let events = [], regions = [], region;
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"]
        });
        
        //console.log(options);
        //console.log(JSON.stringify(fileJson, null, 4));

        /* Put the region data into an array */
        if (fileJson.tt.head.layout && fileJson.tt.head.layout.region) {
            if (Array.isArray(fileJson.tt.head.layout.region)) {
                regions = fileJson.tt.head.layout.region;
            } else {
                regions = [fileJson.tt.head.layout.region];
            }
        }

        if (Array.isArray(fileJson.tt.body.div)) {
            fileJson.tt.body.div.forEach(paragraphGroup => {
                if (Array.isArray(paragraphGroup.p)) {
                    paragraphGroup.p.forEach(paragraph => {
                        if (paragraph["@_region"]) {
                            region = regions.find(regionEl => {
                                return regionEl["@_xml:id"] === paragraph["@_region"]
                            });
                        } else {
                            region = false;
                        }
                        
                        events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
                    });
                } else {
                    if (paragraphGroup.p["@_region"]) {
                        region = regions.find(regionEl => {
                            return regionEl["@_xml:id"] === paragraphGroup.p["@_region"]
                        });
                    } else {
                        region = false;
                    }

                    events.push(ttmlFunc.multiLine.decodeSubtitle(paragraphGroup.p, region, options.frameRate, options.window));
                }
            });
        } else {
            if (Array.isArray(fileJson.tt.body.div.p)){
                fileJson.tt.body.div.p.forEach(paragraph => {
                    if (paragraph["@_region"]) {
                        region = regions.find(regionEl => {
                            return regionEl["@_xml:id"] === paragraph["@_region"]
                        });
                    } else {
                        region = false;
                    }
                    events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
                });
            } else {
                let paragraph = fileJson.tt.body.div.p;
                if (paragraph["@_region"]) {
                    region = regions.find(regionEl => {
                        return regionEl["@_xml:id"] === paragraph["@_region"]
                    });
                } else {
                    region = false;
                }

                events.push(ttmlFunc.multiLine.decodeSubtitle(paragraph, region, options.frameRate, options.window));
            }            
        }

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);
        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<tt xmlns="http://www.w3.org/ns/ttml" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:ttp="http://www.w3.org/ns/ttml#parameter"  ttp:profile="http://www.w3.org/ns/ttml/profile/imsc1/text" xml:lang="${encodingOptions["Language Code"] || "en"}" ttp:frameRate="${ttmlFunc.frameRateMap[options.frameRate]}" ttp:frameRateMultiplier="${ttmlFunc.frameRateMultiplierMap[options.frameRate]}" ttp:timeBase="media">`);
        output += eol.after(`<head>`);
        output += eol.after(`<metadata>
        <ttm:desc>Closed Caption Converter - TTML Export</ttm:desc>
        <smpte:information xmlns:m608="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" origin="http://www.smpte-ra.org/schemas/2052-1/2010/smpte-tt#cea608" mode="Preserved" m608:channel="CC1" m608:programName="Untitled" m608:captionService="F1C1CC"/>
    </metadata>`);
        output += eol.after(`<style xml:id="basic" tts:color="${encodingOptions["Font Color"] || "white"}" tts:backgroundColor="${encodingOptions["Background Color"] || "black"}" tts:fontFamily="${encodingOptions["Font Family"] || "monospace"}" tts:fontSize="${encodingOptions["Font Size (%)"]||80}%" tts:fontWeight="${encodingOptions["Font Weight"]||"normal"}" tts:fontStyle="${encodingOptions["Font Style"] || "normal"}"/>`);
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += eol.after(`<div>`);

        /* EVENTS */
        eventGroup.events.forEach((event, index, events) => {
            let start = encodingOptions["Timecode Format"] === "smpte" ? tcLib.secToTc(event.start, options.frameRate) : tcLib.secToTcMs(event.start);
            let end = encodingOptions["Timecode Format"] === "smpte" ? tcLib.secToTc(event.end, options.frameRate) : tcLib.secToTcMs(event.end);
            //console.log(event.text);
            let plainTextCustom = convertToPlainTextCustom(event.text);
            let plainText = convertToPlainText(event.text);
            let extents = ttmlFunc.multiLine.calcExtents(plainText, encodingOptions["Font Size (%)"]);
            let origins = ttmlFunc.multiLine.calcOrigin(plainText, event.xPos, event.xOffset, event.yPos, event.yOffset, encodingOptions["Font Size (%)"], options.window);
            let text = htmlEncodePlainText(plainTextCustom);
            //console.log(text);
            output += eol.after(`<p begin="${start}" end="${end}" style="basic" tts:origin='${origins}' tts:extent='${extents}'>${ttmlFunc.multiLine.convertToTtml(text)}</p>`);
        });

        output += eol.after(`</div>`);
        output += eol.after(`</body>`);
        output += `</tt>`;
        //console.log(output);
        return xmlFormat(eol.auto(output));
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input);
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}