<template>
  <div id="loginForm">
    <div id="loginFormWrapper">
      <div class="col-md-4 offset-md-4 align-self-center" id="loginContent">
        <div id="loginContentWrapper" >
          <div class="text-center mb-4">
            <img src="@/assets/logo.png" width="120px">
          </div>
          
          <h5>Welcome to Closed Caption Converter</h5>
          <p>Please login to begin</p>
          <hr class="loginHr"/>
          <div id="firebaseui-auth-container"></div>
          <hr/>
          <p class="small text-muted">support@closedcaptioncreator.com</p>
        </div>
        
      </div>      
    </div>
  </div>
</template>

<script>
  import firebase from '@/firebase/config'
  import * as firebaseui from 'firebaseui'
  export default {
    name: 'Login',
    mounted() {      
      let self = this;
      let uiConfig = {
        'credentialHelper': firebaseui.auth.CredentialHelper.NONE,
        signInOptions: [{
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false
        }],
        callbacks: {
          signInSuccessWithAuthResult() {
            localStorage.setItem('authenticated', true);
            self.$router.push({ name: 'converter' });
          }
        },

      }

      let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", uiConfig);
    },

  }
</script>
<style> 
  #loginContent {
    padding-top: 25vh;
    padding: 0;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .firebaseui-title {
    color: #EEE;
    font-size: 18px;
  }

  #loginForm {
    padding-top:20vh;
    width: 100vw;
    height: 100vh;
    background-color: #084d47;
  }

  #loginContentWrapper {
    
    background-color: rgb(32, 32, 32);
    padding: 5%;
  }

  #loginContentWrapper h5{
    color: rgb(173, 173, 173);
  }

  .loginHr {
    background-color: rgb(71, 71, 71);
  }

  .firebaseui-button {
    border-radius: 91px 91px 91px 91px;
    -moz-border-radius: 91px 91px 91px 91px;
    -webkit-border-radius: 91px 91px 91px 91px;
    border: 1px solid #022c47;
    background-color: rgb(57, 132, 151);
    color: #efefef;
    font-size: 1.5em;
    width: 100%;
    margin-top: 1%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .firebaseui-text-input-error {
    font-size: 1.0em;
    color: #bd2c2c;
    font-weight: bold;
    padding: 1%;
  }

  .firebaseui-link {
    color: #bd2c2c;
    font-weight: bold;
    padding: 1%;
  }

  .firebaseui-link:hover {
    color: #e21717;
    font-weight: bold;
    
  }

  .firebaseui-input {
    border-radius: 5px;
    margin-left: 1%;
    padding: 1%;
    min-width: 100%;
    border: none;
  }

  .firebaseui-input-invalid {
    border-radius: 5px;
    margin-left: 1%;
    padding: 1%;
    min-width: 100%;
    border: none;
  }

  .firebaseui-id-password {
    border-radius: 5px;
    margin-left: 1%;
    padding: 1%;
    min-width: 100%;
    border: none;
  }
</style>