const orderByTime = require("../eventGroups/orderByTime.js");
const tcOffset = require("../events/tcOffset.js");
const tcLib = require("../../lib/timecode.js");
module.exports = function automaticOffset(eventGroup, frameRate){
    eventGroup = orderByTime(eventGroup);
    let tcStart = tcLib.secToTc(eventGroup.events[0].start, frameRate);
    let hours = tcStart.split(":")[0];
    let offsetAmount = 0;
    
    if (hours === "00"){
        return eventGroup;
    } else {
        offsetAmount = tcLib.tcToSec(hours + ":00:00:00", frameRate);
        eventGroup.events.forEach((event, index, events)=>{
            events[index] = tcOffset(event, offsetAmount, "subtract");
        });

        return eventGroup;
    }
}