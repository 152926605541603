<template>
<form id="SourcePreviewForm">
    <div class="form-group">
        <label>Source Preview</label>
        <textarea disabled class="form-control" id="srcPreviewText" rows="10" v-model="srcPreviewText"></textarea>
    </div>
</form>
</template>

<script>
export default {
    name: "SourcePreview",
    computed : {
        srcPreviewText : function(){
            return this.$store.state.srcPreview || "Please select a source file to begin.";
        }
    }
}
</script>
<style>
#SourcePreviewForm {
    padding-right: 1%;
}
</style>