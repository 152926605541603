const uuidv4 = require('uuid').v4;
module.exports = class EventGroup {
    constructor (options = {
        name: "Untitled", /* Event group name */
        type: "subtitle", /* subtitle, transcription, translation, or audio description */
        events: [],       
        language: "en-US", /* event group language */
        ad : {
            gender : "",
            language : "",
            voice : ""
        }, //AD Settings
        rtl : false /* Right To Left Support */       
    }) {
        this.id = uuidv4();
        this.type = options.type || "subtitle";
        this.name = options.name || "Untitled";
        this.events = options.events || [];
        this.language = options.language || "en-US";
        this.ad = options.ad || {
            gender : "MALE",
            language : "en-US",
            voice : "en-US-Wavenet-B"
        };
        this.rtl = options.rtl;
    }
}