module.exports = function applyWindowOffsets(eventGroup, win, type = "add") {
    eventGroup.events.forEach((event,index, events) =>{
        if (type === "subtract"){
            /* X */
            if (event.xPos === "end"){
                events[index].xOffset += win.xOffset;
            } else if (event.xPos === "start") {
                events[index].xOffset -= win.xOffset;
            }

            /* Y */
            if (event.yPos === "end"){
                events[index].yOffset += win.yOffset;
            } else if (event.yPos === "start") {
                events[index].yOffset -= win.yOffset;
            }
        } else {
            /* X */
            if (event.xPos === "end"){
                events[index].xOffset -= win.xOffset;
            } else if (event.xPos === "start") {
                events[index].xOffset += win.xOffset;
            }
            
            /* Y */
            if (event.yPos === "end"){
                events[index].yOffset -= win.yOffset;
            } else if (event.yPos === "start") {
                events[index].yOffset += win.yOffset;
            }            
        }
    });

    return eventGroup;
}