const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const eol = require("eol");
const uuidv4 = require('uuid').v4;
const htmlEntities = require('html-entities'); //Encode
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const dlpFunc = require("../functions/profiles/dlpCinema.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainTextCustom = require("../functions/quill/convertToPlainTextCustom.js");
const htmlEncodePlainText = require("../functions/utility/htmlEncodePlainText.js");
const flexbox = require("../dict/flexbox.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const quillClasses = require("../dict/quillClasses.js");
module.exports = {
    decode: function (input, options) {
        let events = [];
        let fileJson = xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["Text"]
        });
        
        fileJson.DCSubtitle.Font.Subtitle.forEach(event =>{
            let ccEvent = new Event();
            ccEvent.start = dlpFunc.calcTc(event["@_TimeIn"]);
            ccEvent.end = dlpFunc.calcTc(event["@_TimeOut"]);

            //console.log(event);

            if (Array.isArray(event["Text"])){
                event["Text"].forEach(textLine =>{
                    ccEvent.text += htmlEntities.decode(textLine["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>") + "\n";
                });
                ccEvent.alignment = event["Text"][0]["@_HAlign"];
                ccEvent.xPos = flexbox.alignmentMap[event["Text"][0]["@_HAlign"]];
                ccEvent.yPox = flexbox.alignmentMap[event["Text"][0]["@_VAlign"]];
            } else {
                ccEvent.text = htmlEntities.decode(event["Text"]["#text"]).replace(/<Font Italic=.yes.>|<Font Bold=.yes.>|<Font Underline=.yes.>/gmi, '<em>').replace("</Font>", "</em>");
                ccEvent.alignment = event["Text"]["@_HAlign"];
                ccEvent.xPos = flexbox.alignmentMap[event["Text"]["@_HAlign"]];
                ccEvent.yPox = flexbox.alignmentMap[event["Text"]["@_VAlign"]];
            }
            
            ccEvent.text = convertToHtml(ccEvent.text, [quillClasses.align[ccEvent.alignment]]);
            events.push(ccEvent);
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let encodingOptions = getFormatOptions(options.formatOptions);

        let fadeUpTime = encodingOptions["Fade Up Time"];
        let fadeDownTime = encodingOptions["Fade Down Time"];
        let verticalStart = encodingOptions["Vertical Position Start"];
        let verticalSpacing = encodingOptions["Vertical Spacing"] || 6;

        if (fadeUpTime === undefined){
            fadeUpTime = 20;
        }

        if (fadeDownTime === undefined){
            fadeDownTime = 20;
        }

        if (verticalStart === undefined){
            verticalStart = 10;
        } else {
            verticalStart = parseFloat(verticalStart);
        }

        verticalSpacing = parseFloat(verticalSpacing);

        let output = eol.after(`<?xml version="1.0" encoding="UTF-8"?>`);
        output += eol.after(`<!-- *** Created by Closed Caption Converter 3 *** -->`);
        output += eol.after(`<DCSubtitle Version="1.0">`);
        output += eol.after(`<SubtitleID>${uuidv4()}</SubtitleID>`);
        output += eol.after(`<MovieTitle>${encodingOptions["Title"] || ''}</MovieTitle>`);
        output += eol.after(`<ReelNumber>${encodingOptions["Reel Number"] || '1'}</ReelNumber>`);
        output += eol.after(`<Language>${encodingOptions["Language Code"] || 'en'}</Language>`);
        output += eol.after(`<LoadFont Id="Arial" URI="arial.ttf"/>`);
        output += eol.after(`<Font Id="Arial" Color="FFFFFFFF" Effect="border" EffectColor="FF000000" Size="42" Weight="normal">`);
        eventGroup.events.forEach((event, id) =>{
            let start = tcLib.secToTicksSec(event.start);
            let end = tcLib.secToTicksSec(event.end);
            let text = htmlEncodePlainText(convertToPlainTextCustom(event.text));
            //console.log(text+"\n");
            output += eol.after(`<Subtitle SpotNumber="${(id+1).toString().padStart(4,"0")}" TimeIn="${start}" TimeOut="${end}" FadeUpTime="${fadeUpTime}" FadeDownTime="${fadeDownTime}">`);
            text.split("\n").forEach((line,count, lines) =>{
                let vAlign = flexbox.flexMap.vertical[event.yPos];
                let hAlign = flexbox.flexMap.horizontal[event.xPos];
                let hPos = "0.0";
                let vPos;

                if (event.yPos === "start"){
                    vPos = (count*verticalSpacing) + verticalStart;
                } else if (event.yPos === "center"){
                    vPos = (count*verticalSpacing) + verticalStart;
                } else {
                    vPos = lines.length === 1 ? verticalStart : (((lines.length-1)*verticalSpacing)+verticalStart) - count*verticalSpacing; 
                }

                output += eol.after(`<Text HAlign="${hAlign}" HPosition="${hPos}" VAlign="${vAlign}" VPosition="${vPos.toFixed(1)}">${line.replace(/<em>|<strong>|<u>/gmi, '<Font Italic="yes">').replace(/<\/em>|<\/strong>|<\/u>/gmi, "</Font>")}</Text>`);
            })
            output += eol.after(`</Subtitle>`);
        });

        output += eol.after("</Font>");
        output += eol.after("</DCSubtitle>");


        return output;
        //return xmlFormat(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input;
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    }
}