const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
module.exports = {
    decode : function(input, options){
        throw new Error ("JSON source files are not supported");
    },

    encode : function(eventGroup, options){
        let events = []; 
        eventGroup.events.forEach(event  =>{            
            events.push({
                startSec : event.start,
                endSec : event.end,
                startSmpte : tcLib.secToTc(event.start, options.frameRate),
                endSmpte: tcLib.secToTc(event.end, options.frameRate),
                frameRate : options.frameRate,
                text: convertToPlainText(event.text)
            });
        });

        return JSON.stringify(events, null, 4);
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return input;
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        encode : function(eventGroup){
            return events;
        }
    },

}