const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const getFormatOptions = require("../functions/helpers/getFormatOptions.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const flexbox = require("../dict/flexbox.js");
const eol = require("eol");
const quillClasses = require("../dict/quillClasses.js");

module.exports = {
    decode: function (input, options) {
        let events = [], ccEvent, horzAlign = "center", vertAlign = "bottom", xOffset = 0, yOffset = 0, tcIn, tcOut, text;
    
        const tcRegex = /\d{2}:\d{2}:\d{2}\:\d{2}/g;
        const fileLines = eol.split(input).filter(fileLine => {return fileLine});

        fileLines.forEach(fileLine =>{
            let lineParts = fileLine.split(" ");
            if (lineParts[0] === "$HorzAlign"){
                horzAlign = flexbox.alignmentMap[lineParts[2].toLowerCase()];
            } else if (lineParts[0] === "$XOffset"){
                xOffset = (parseFloat(lineParts[2])/480)*options.window.width;
            } else if (lineParts[0] === "$VertAlign"){
                vertAlign = flexbox.alignmentMap[lineParts[2].toLowerCase()];                
            } else if (lineParts[0] === "$YOffset"){
                xOffset = (parseFloat(lineParts[2])/720)*options.window.height;
            } else if (tcRegex.test(fileLine)){
                let matches = fileLine.match(tcRegex);
                tcIn = matches[0];
                tcOut = matches[1];
                let textLine = fileLine.split(",");
                textLine.shift();
                textLine.shift();
                text = textLine.join(",").replace(/\^[BUI]/g, "").replace(/\|/, "\n");

                ccEvent = new Event();
                ccEvent.start = tcLib.tcToSec(tcIn, options.frameRate);
                ccEvent.end = tcLib.tcToSec(tcOut, options.frameRate);
                ccEvent.text = convertToHtml(text, [quillClasses.align[horzAlign]]);
                ccEvent.xPos = horzAlign;
                ccEvent.yPos = vertAlign;
                ccEvent.xOffset = xOffset;
                ccEvent.yOffset = yOffset;
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let output = eol.after(`$FontName           = Courier New
$FontSize           = 9

$Bold               = FALSE
$Underlined         = FALSE
$Italic             = FALSE

$HorzAlign          = Center
$VertAlign          = Bottom
$XOffset            = 0
$YOffset            = -48


//---------------------------------------------------------------
// Uncomment the following lines to set color contrast settings
//---------------------------------------------------------------
// $TextContrast       = 15
// $Outline1Contrast   = 15
// $Outline2Contrast   = 15
// $BackgroundContrast = 0
`);

        let horzAlign = "center", vertAlign = "bottom";
        eventGroup.events.forEach(event => {
            if (flexbox.flexMap.horizontal[event.xPos] !== horzAlign){
                horzAlign = flexbox.flexMap[event.xPos];
                output += eol.after(`$HorzAlign = ${horzAlign[0].toUpperCase() + horzAlign.substring(1)}`);                
            }

            if (flexbox.flexMap.vertical[event.yPos] !== vertAlign){
                vertAlign = flexbox.flexMap[event.yPos];
                output += eol.after(`$VertAlign = ${vertAlign[0].toUpperCase() + vertAlign.substring(1)}`);
            }

            let text = event.text.replace(/<strong>/gmi, "^B").replace(/<em>/gmi, "^I").replace(/<u>/gmi, "^U");
            output += (eol.after(`${tcLib.secToTc(event.start, options.frameRate)},${tcLib.secToTc(event.end, options.frameRate)},${convertToPlainText(text, "|").replace(/\|$/gmi,"")}`));
        });



        return output;
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return input.trim();
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },
}