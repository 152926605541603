const Event = require('../../classes/event.js');
const convertToPlainText = require('../quill/convertToPlainText.js');
const convertToHtml = require('../quill/convertToHtml.js');
const eol = require('eol');

// Test the event to see if it needs to be formatted
function formatEventTest(plaintext, maxLines, maxChars){
    let result = false;
    let eventLines = eol.split(plaintext);
    if (eventLines.length > maxLines){
        result = true;
    }

    eventLines.forEach(line =>{
        if (line.length > maxChars){
            result = true;
        }
    });

    return result;
}

// Format the event and return an array of events in case we need to create additional events
function formatEvent(originalEvent, maxLines, maxChars){
    let events = [], paragraph = [], sentence = "";
    let currentTime = originalEvent.start;
    let end = originalEvent.end;
    let duration = end-currentTime;
    let words = convertToPlainText(originalEvent.text, " ").trim().split(" ");
    let wps = duration/words.length;

    while (words.length > 0){
        let word = words.shift();
        if (sentence.length + word.length + 1 > maxChars){
            if (paragraph.length + 1 > maxLines){
                events.push(new Event({
                    text : convertToHtml(paragraph.join("\n")),
                    start : currentTime,
                    end : currentTime += paragraph.join(" ").split(" ").length*wps,
                }));

                paragraph = [sentence];
                sentence = word;
            } else {
                paragraph.push(sentence);
                sentence = word;
            }
        } else {
            sentence += " " + word;
        }
    }

    if (paragraph.length + 1 > maxLines){
        events.push(new Event({
            text : convertToHtml(paragraph.join("\n")),
            start : currentTime,
            end : currentTime += paragraph.join(" ").split(" ").length*wps,
        }));

        paragraph = [];
    } 
    
    paragraph.push(sentence);
    events.push(new Event({
        text : convertToHtml(paragraph.join("\n")),
        start : currentTime,
        end : end,
    }));

    return events;
}

module.exports = function autoFormat(eventGroup, maxLines, maxChars, selectedEvents = null){
    let copyOfEvents = JSON.parse(JSON.stringify(eventGroup.events));
    eventGroup.events = [];
    copyOfEvents.forEach((ccEvent, count) =>{ 
        if ((!selectedEvents || selectedEvents.indexOf(count) > -1) && formatEventTest(convertToPlainText(ccEvent.text), maxLines, maxChars)){
            eventGroup.events = [...eventGroup.events, ...formatEvent(ccEvent, maxLines, maxChars)];
        } else {
            eventGroup.events.push(ccEvent);
        }
    });
    
    return eventGroup;
}