<template>
<form id="TargetPreviewForm">
  <div class="form-group">
    <label>Target Preview</label>
    <textarea disabled class="form-control" id="trgPreviewText" rows="10" v-model="trgPreviewText"></textarea>
  </div>
  <div class="btn-group" role="group" aria-label="Basic example">
    <button :disabled="this.$store.state.multiFile || this.$store.state.trgExt===''" type="button" class="btn btn-info" @click="saveTrgPreview"><i class="far fa-save"></i> Save</button>
    <button type="button" class="btn btn-secondary" @click="copyTrgPreview"><i class="far fa-copy"></i> Copy</button>
    <button type="button" class="btn btn-dark ml-2" data-toggle="modal" data-target="#JobConfigModal"><i class="fas fa-eye"></i> Job Configuration</button>
    <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#ApiKeyModal"><i class="fas fa-key"></i> API Keys</button>
  </div>
  <button type="button" class="btn btn-sm btn-link btn-dark float-right" @click="logout"><i class="fas fa-sign-out-alt"></i> Logout</button>
  <div class="mt-3">
    <p class="text-muted small mb-0"><i class="far fa-envelope"></i> support@closedcaptioncreator.com</p>
    
  </div>

</form>
</template>

<script>
const fileSaver = require('file-saver');
const eol = require('eol');
import formats from "@/external/cc-lib/lib/formats.js";
import firebase from '@/firebase/config'
export default {
  name: "TargetPreview",
  computed: {
    trgPreviewText: function () {
      return this.$store.state.trgPreview || "";
    },
    appEnv: function () {
      return process.env.VUE_APP_ENV
    }
  },
  methods: {
    getUserSub: function () {
      var email = firebase.auth().currentUser.email;
      firebase.functions().httpsCallable('getUserSubscriptionNew')(email).then(res => {
        console.log(res);
      }).catch(function (error) {
        console.log(error);
      });
    },
    logout: function () {
      firebase.auth().signOut().then(() => {
        this.$router.replace({
          name: "Login"
        });
      })
    },
    copyTrgPreview: function () {
      this.$clipboard(this.trgPreviewText);
      this.$notify({
        title: 'Copy Successful',
        text: 'Target preview copied to clipboard',
        type: 'success' /* warn, error, success */
      });
    },
    saveTrgPreview: function () {
      let fileName = this.$store.state.fileName;
      let ext = this.$store.state.trgExt;
      let fileNamePlaceHolder = fileName + "." + ext;
      let fileContents = this.$store.state.trgPreview;
      let defaultEncoding = formats.defaultEncodingMap[formats.profileMapping[this.$store.state.trgProfile].name];
      let self = this;

      if (this.appEnv === "desktop") {
        console.log("Running desktop version");
        console.log("Saving file using Electron Dialogue");
        const {
          remote
        } = require('electron'), fs = require('fs'), dialog = remote.dialog, WIN = remote.getCurrentWindow();
        let options = {
          title: "Save Caption File",
          buttonLabel: "Save File",
          defaultPath: fileNamePlaceHolder,
          filters: [{
              name: 'Custom File Type',
              extensions: [ext]
            },
            {
              name: 'All Files',
              extensions: ['*']
            }
          ]
        }
        dialog.showSaveDialog(WIN, options).then(directory => {
          console.log(directory.filePath);

          fs.writeFile(directory.filePath, fileContents, defaultEncoding, function (err) {
            if (err) {
              self.$notify({
                title: 'File Error',
                text: 'Error saving file',
                type: 'error' /* warn, error, success */
              });
              throw err;
            }
            self.$notify({
              title: 'File Saved',
              text: 'File successfully saved',
              type: 'success' /* warn, error, success */
            });
          });

        });

      } else {
        if (defaultEncoding === "hex") {
          var byteArray = new Uint8Array(fileContents.match(/.{2}/g).map(e => parseInt(e, 16)));
          var blob = new Blob([byteArray], {
            type: "application/octet-stream"
          });
        } else if (defaultEncoding === "utf-16le") {
          var blob = new Blob([this.$store.state.trgPreview], {
            type: "text/plain;charset=utf-16le"
          });
        } else {
          var blob = new Blob([this.$store.state.trgPreview], {
            type: "text/plain;charset=utf-8"
          });
        }

        fileSaver.saveAs(blob, fileNamePlaceHolder, {
          autoBom: true
        });
      }
    }
  }
}
</script>

<style>
#TargetPreviewForm {
  padding-right: 1%;
}
</style>
