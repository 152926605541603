const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlainText = require("../functions/quill/convertToPlainText.js");
const eol = require("eol");
const htmlEntities = require('html-entities'); //Encode
const xmlFormat = require('xml-formatter'); //Encode
const xmlToJson = require('fast-xml-parser'); //Decode
const flexbox = require("../dict/flexbox.js");
const replaceLineEndings = require("../functions/quill/replaceLineEndings.js");

module.exports = {
    decode: function (input, options) {
        let events = [];
        xmlToJson.parse(input, {
            ignoreAttributes: false,
            stopNodes: ["p"]
        }).tt.body.div.p.forEach(paragraph => {
            ccEvent = new Event();
            ccEvent.start = tcLib.tcMsToSec(paragraph['@_begin']);
            ccEvent.end = tcLib.tcMsToSec(paragraph['@_end']);
            ccEvent.text = convertToHtml(htmlEntities.decode(paragraph["#text"].replace(/<br \/>|<br\/>|<br>/gmi, "\n")));

            events.push(ccEvent);
        });

        return events;
    },

    encode: function (eventGroup, options) {
        let output = eol.after(`<?xml version="1.0" encoding="utf-8"?>`);
        output += eol.after(`<tt xmlns="http://www.w3.org/ns/ttml" xmlns:ttm="http://www.w3.org/ns/ttml#metadata" xmlns:tts="http://www.w3.org/ns/ttml#styling" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">`);
        output += eol.after(`<head>`);
        output += eol.after(`<metadata>
        <ttm:title>Untitled</ttm:title>
      </metadata>`);
        output += eol.after(`<styling>
        <style tts:fontStyle="normal" tts:fontWeight="normal" xml:id="s1" tts:color="white" tts:fontFamily="Arial" tts:fontSize="100%"></style>
      </styling>`);
        output += eol.after(`<layout>
      <region tts:extent="80% 40%" tts:origin="10% 10%" tts:displayAlign="before" tts:textAlign="center" xml:id="top" />
      <region tts:extent="80% 40%" tts:origin="10% 30%" tts:displayAlign="after" tts:textAlign="center" xml:id="center" />
      <region tts:extent="80% 40%" tts:origin="10% 50%" tts:displayAlign="after" tts:textAlign="center" xml:id="bottom" />
    </layout>`);
        output += eol.after(`</head>`);
        output += eol.after(`<body>`);
        output += eol.after(`<div style="s1" xml:id="d1">`);
        
        eventGroup.events.forEach((event, index) => {
            let id = index + 1;
            let start = tcLib.secToTcMs(event.start);
            let end = tcLib.secToTcMs(event.end);
            let text = replaceLineEndings(htmlEntities.encode(convertToPlainText(event.text)), "<br/>");
            let region = flexbox.flexMap.vertical[event.yPos];
            output += eol.after(`<p xml:id="p${id}" begin="${start}" end="${end}" region="${region}">${text}</p>`);
        });

        output += eol.after(`</div>`);
        output += eol.after(`</body>`);
        output += eol.after(`</tt>`);


        return xmlFormat(output);
    },

    preProcess: {
        encode: function (eventGroup) {
            return removeInvalidEvents(eventGroup);
        },

        decode: function (input) {
            return eol.lf(input);
        }
    },

    postProcess: {
        encode: function (output) {
            return output;
        },

        decode: function (eventGroup) {
            return eventGroup;
        }
    },

}