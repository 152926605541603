const Event = require("../classes/event.js");
const tcLib = require("../lib/timecode.js");
const eol = require("eol");
const removeInvalidEvents = require("../functions/eventGroups/removeInvalidEvents.js");
const insertMissingOutcodes = require("../functions/eventGroups/insertMissingOutcodes.js");
const convertToHtml = require("../functions/quill/convertToHtml.js");
const convertToPlain = require("../functions/quill/convertToPlainText.js");
module.exports = {
    decode : function(input, options){
        let ccEvent, events = [], blocks = input.split("\n\n");
        blocks.forEach(block =>{
            let eventFlag = false;
            let endFlag = false;
            let blockLines = block.split("\n");
            blockLines.forEach(blockLine => {
                if (blockLine.includes("** Caption Number")) {
                    eventFlag = true;
                    ccEvent = new Event();
                } else if (eventFlag){
                    let blockLineLength = blockLine.split(" ").length;
                    if (blockLine.includes("*T") && blockLineLength > 1){
                        let tc = blockLine.split(" ")[1];
                        ccEvent.start = tcLib.tcToSec(tc, options.frameRate);
                    } else if (blockLine.includes("*E") && blockLineLength > 1){
                        let tc= blockLine.split(" ")[1];
                        ccEvent.end = tcLib.tcToSec(tc, options.frameRate); 
                        endFlag = true;
                    } else if (blockLine.charAt(0) === "*"){
                        /* Metadata that is ignored (for now)*/
                    } else {
                        if (blockLine.endsWith("\E")){
                            blockLine = blockLine.slice(0, -2);
                        }
                        ccEvent.text += blockLine.replace(/^\\[A-Z]{2,4}\\/, "") + "\n";
                    }
                }
            }); 

            if (eventFlag){
                if (!endFlag){
                    ccEvent.end = false;
                }

                ccEvent.text = convertToHtml(ccEvent.text);
                events.push(ccEvent);
            }
        });

        return events;
    },

    encode : function(eventGroup, options){
        let output = "";
        if (options.dropFrame){
            output += "*DropFrame\n";
        }

        output += "*WIDTH 32\n\n";

        eventGroup.events.forEach(function(event, i){
            output += "** Caption Number " + (i+1) + "\n";
            output += "*PopOn\n";
            output += "*T " + tcLib.secToTc(event.start, options.frameRate) + "\n";
            output += "*E " + tcLib.secToTc(event.end, options.frameRate) + "\n";
            output += "*BottomUp\n";
            output += "*Cf16\n";
            output += convertToPlain(event.text) + "/E\n\n";
        });

        return output.trim();
    },

    preProcess : {
        encode : function(eventGroup){
            return removeInvalidEvents(eventGroup);
        },

        decode : function(input){
            return eol.lf(input.trim());
        }
    },

    postProcess : {
        encode : function(output){
            return output;
        },

        decode : function(eventGroup, options){
            return insertMissingOutcodes(eventGroup);
        }
    },

}