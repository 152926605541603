const convertToPlainText = require("../quill/convertToPlainText.js");
const convertToHtml = require("../quill/convertToHtml.js");

/* Takes event text as html */
module.exports = function autoFormatSimple(eventText) {
    let text = convertToPlainText(eventText, " ").trim();
    let paragraphs = [];
    let sentense = "";

    text.split(" ").forEach((word, i, words) => {
        if (sentense.length + word.length + 1 > 32) {
            paragraphs.push(sentense.trim());
            sentense = word;
        } else if (words.length === i + 1) {
            sentense += " " + word;
            paragraphs.push(sentense.trim());
            sentense = "";
        } else if (sentense.length > words.slice(i).join().length && words.length > 1) {
            paragraphs.push(sentense.trim());
            sentense = word;
        } else {
            sentense += " " + word;
        }
    });
    
    if (sentense.length > 0){
        paragraphs.push(sentense.trim());
    }

    return convertToHtml(paragraphs.join("\n"));
}