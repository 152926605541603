const findCenter = require("../utility/findCenter.js");
module.exports = {
    calcLineValue : function(event, numberOfLines, win){
        let line = 100;
        if (event.yPos === "start"){
            line = (event.yOffset/win.height) * 100;
        } else if (event.yPos === "center"){
            line = findCenter(100, numberOfLines*5.5) + ((event.yOffset/win.height) * 100);
        } else {
            line = 100 - (numberOfLines*5.5) + ((event.yOffset/win.height) * 100);
        }

        return line.toFixed(2);
    },
    alignmentMap: {
        "left": "left",
        "start": "left",
        "middle": "center",
        "center": "center",
        "right": "right",
        "end": "right"
    },
    colorMapping: {
        "#000000": "black", //black
        "#FF0000": "red", //red
        "#008000": "lime", //lime
        "#FFFF00": "yellow", //yellow
        "#0000FF": "blue", //blue
        "#FF00FF": "magenta", //megenta
        "#00FFFF": "cyan", //cyan
        "#FFFFFF": "white"  //white
    },
    fontStyleCues: `
STYLE
::cue(.black) {
    color: #000000;
}
::cue(.red) {
    color: #FF0000;
}
::cue(.lime) {
    color: #008000;
}
::cue(.yellow) {
    color: #FFFF00;
}
::cue(.blue) {
    color: #0000FF;
}
::cue(.magenta) {
    color: #FF00FF;
}
::cue(.cyan) {
    color: #00FFFF;
}
::cue(.white) {
    color: #FFFFFF;
}
::cue(.bg_black) {
    background-color: #000000;
}
::cue(.bg_red) {
    background-color: #FF0000;
}
::cue(.bg_lime) {
    background-color: #008000;
}
::cue(.bg_yellow) {
    background-color: #FFFF00;
}
::cue(.bg_blue) {
    background-color: #0000FF;
}
::cue(.bg_magenta) {
    background-color: #FF00FF;
}
::cue(.bg_cyan) {
    background-color: #00FFFF;
}
::cue(.bg_white) {
    background-color: #FFFFFF;
}`,


}