const cptable = require('codepage');

module.exports = { 
    codePageMapping : {
        "ISO 8859-1 Latin 1" : 28591,
        "ISO 8859-2 Latin 2" : 28592,
        "ISO 8859-3 Latin 3" : 28593,
        "ISO 8859-4 Baltic" : 28594,
        "ISO 8859-5 Cyrillic" : 28595,
        "ISO 8859-6 Arabic" : 28596,
        "ISO 8859-7 Greek" : 28597,

    },
    // Decode from Hex
    decodeChar: function (charCode, codePage) {
        try {
            let char = cptable[codePage].dec[parseInt(charCode, 16)];
            return char;
        } catch (e) {
            console.log(e.message);
            return("");
        }
    },
    /* Takes array of  hex values and returns a smpteTC: [ "e8", "03", "64", "00" ] -> 10:00:01:00*/
    decodeTc: function (hexTc) {
        let hhmm,
            ssff,
            tc,
            hh,
            mm,
            ss,
            ff;
        hhmm = parseInt(hexTc[1] + hexTc[0], 16).toString().padStart(4, "0");
        ssff = parseInt(hexTc[3] + hexTc[2], 16).toString().padStart(4, "0");
        hh = hhmm.substring(0, 2);
        mm = hhmm.substring(2, 4);
        ss = ssff.substring(0, 2);
        ff = ssff.substring(2, 4);
        tc = hh + ":" + mm + ":" + ss + ":" + ff;
        return(tc);
    },
    codePages: [
        "37",
        "437",
        "500",
        "620",
        "708",
        "720",
        "737",
        "775",
        "808",
        "850",
        "852",
        "855",
        "857",
        "858",
        "860",
        "861",
        "862",
        "863",
        "864",
        "865",
        "866",
        "869",
        "870",
        "872",
        "874",
        "875",
        "895",
        "932",
        "936",
        "949",
        "950",
        "1010",
        "1026",
        "1047",
        "1132",
        "1140",
        "1141",
        "1142",
        "1143",
        "1144",
        "1145",
        "1146",
        "1147",
        "1148",
        "1149",
        "1200",
        "1201",
        "1250",
        "1251",
        "1252",
        "1253",
        "1254",
        "1255",
        "1256",
        "1257",
        "1258",
        "1361",
        "10000",
        "10001",
        "10002",
        "10003",
        "10004",
        "10005",
        "10006",
        "10007",
        "10008",
        "10010",
        "10017",
        "10021",
        "10029",
        "10079",
        "10081",
        "10082",
        "12000",
        "12001",
        "20000",
        "20001",
        "20002",
        "20003",
        "20004",
        "20005",
        "20105",
        "20106",
        "20107",
        "20108",
        "20127",
        "20261",
        "20269",
        "20273",
        "20277",
        "20278",
        "20280",
        "20284",
        "20285",
        "20290",
        "20297",
        "20420",
        "20423",
        "20424",
        "20833",
        "20838",
        "20866",
        "20871",
        "20880",
        "20905",
        "20924",
        "20932",
        "20936",
        "20949",
        "21025",
        "21027",
        "21866",
        "28591",
        "28592",
        "28593",
        "28594",
        "28595",
        "28596",
        "28597",
        "28598",
        "28599",
        "28600",
        "28601",
        "28603",
        "28604",
        "28605",
        "28606",
        "29001",
        "38598",
        "47451",
        "50220",
        "50221",
        "50222",
        "50225",
        "50227",
        "51932",
        "51936",
        "51949",
        "52936",
        "54936",
        "57002",
        "57003",
        "57004",
        "57005",
        "57006",
        "57007",
        "57008",
        "57009",
        "57010",
        "57011",
        "65000",
        "6500"
    ],
    specialLatinChars : {
        "e041" : "Ã",
        "e04e" : "Ñ",
        "e04f" : "Õ",
        "e061" : "ã",
        "e06e" : "ñ",
        "e06f" : "õ",
        "e161" : "å",
        "e141" : "Å",
        "618a" : "ā",
        "418a" : "Ā",
        "458a" : "Ē",
        "658a" : "ē",
        "498a" : "Ī",
        "698a" : "ī",
        "4f8a" : "Ō",
        "6f8a" : "ō",
        "558a" : "Ū",
        "758a" : "ū",
        "E54f" : "Ö",
        "E56f" : "ö",
        "e541" : "Ä",
        "e561" : "ä",
        "e555" : "Ü",
        "e575" : "ü",
        "e241" : "Á",
        "e249" : "Í",
        "e255" : "Ú",
        "e259" : "Ý",
        "e261" : "á",
        "e265" : "é",
        "e269" : "í",
        "e245" : "É",
        "e275" : "ú",
        "e279" : "ý",
        "e361" : "à",
        "e365" : "è",
        "e36f" : "ò",
        "e345" : "È",
        "e349" : "Ì",
        "e34f" : "Ò",
        "e369" : "ì",
        "e443" : "Ĉ",
        "e447" : "Ĝ",
        "e448" : "Ĥ",
        "e44A" : "Ĵ",
        "e453" : "Ŝ",
        "eA55" : "Ǔ",
        "e463" : "ĉ",
        "e467" : "ĝ",
        "e468" : "ĥ",
        "e46A" : "ĵ",
        "e473" : "ŝ",
        "eA75" : "ǔ",
        "e341" : "À",
        "e441" : "Â",
        "e461" : "â",
        "e643" : "Ç",
        "e663" : "ç",
        "e445" : "Ê",
        "e465" : "ê",
        "e545" : "Ë",
        "e565" : "ë",
        "e449" : "Î",
        "e469" : "î",
        "e549" : "Ï",
        "e569" : "ï",
        "e44F" : "Ô",
        "e46F" : "ô",
        "e355" : "Ù",
        "e375" : "ù",
        "e455" : "Û",
        "e475" : "û",
        "e559" : "Ÿ",
        "e579" : "ÿ",
        "eb41" : "Ą",
        "eb61" : "ą",
        "e243" : "Ć",
        "e263" : "ć",
        "eB45" : "Ę",
        "eB65" : "ę",
        "e24e" : "Ń",
        "e26e" : "ń",
        "e24f" : "Ó",
        "e26f" : "ó",
        "e253" : "Ś",
        "e273" : "ś",
        "e25a" : "Ź",
        "e27a" : "ź",
        "e85a" : "Ż",
        "e87a" : "ż",
        "e653" : "Ş",
        "e673" : "ş",
        "eA67" : "ǧ",
        "eA47" : "Ǧ",
        "e849" : "İ",
        "E75A" : "Ž",
        "E753" : "Š",
        "E743" : "Č",
        "E77A" : "ž",
        "E773" : "š",
        "E763" : "č",
        "4CE2" : "Ľ",
        "74E2" : "ť",
        "64E2" : "ď",
        "6CE2" : "ľ",
        "E020" : "˜",
        "E045" : "Ẽ",
        "E049" : "Ĩ",
        "E055" : "Ũ",
        "E056" : "Ṽ",
        "E059" : "Ỹ",
        "E065" : "ẽ",
        "E069" : "ĩ",
        "E075" : "ũ",
        "E076" : "ṽ",
        "E079" : "ỹ",
        "E120" : "˚",
        "E155" : "Ů",
        "E175" : "ů",
        "E177" : "ẘ",
        "E179" : "ẙ",
        "E220" : "´",
        "E247" : "Ǵ",
        "E24B" : "Ḱ",
        "E24C" : "Ĺ",
        "E24D" : "Ḿ",
        "E250" : "Ṕ",
        "E252" : "Ŕ",
        "E257" : "Ẃ",
        "E25C" : "Ǽ",
        "E25D" : "Ǿ",
        "E267" : "ǵ",
        "E26B" : "ḱ",
        "E26C" : "ĺ",
        "E26D" : "ḿ",
        "E270" : "ṕ",
        "E272" : "ŕ",
        "E277" : "ẃ",
        "E27C" : "ǽ",
        "E27D" : "ǿ",
        "E320" : "`",
        "E34E" : "Ǹ",
        "E357" : "Ẁ",
        "E359" : "Ỳ",
        "E36E" : "ǹ",
        "E377" : "ẁ",
        "E379" : "ỳ",
        "E420" : "^",
        "E457" : "Ŵ",
        "E459" : "Ŷ",
        "E45A" : "Ẑ",
        "E477" : "ŵ",
        "E479" : "ŷ",
        "E47A" : "ẑ",
        "E520" : "¨",
        "E548" : "Ḧ",
        "E557" : "Ẅ",
        "E558" : "Ẍ",
        "E568" : "ḧ",
        "E574" : "ẗ",
        "E577" : "ẅ",
        "E578" : "ẍ",
        "E620" : "¸",
        "E644" : "Ḑ",
        "E645" : "Ȩ",
        "E647" : "Ģ",
        "E648" : "Ḩ",
        "E64B" : "Ķ",
        "E64C" : "Ļ",
        "E64E" : "Ņ",
        "E652" : "Ŗ",
        "E654" : "Ţ",
        "E664" : "ḑ",
        "E665" : "ȩ",
        "E667" : "ģ",
        "E668" : "ḩ",
        "E66B" : "ķ",
        "E66C" : "ļ",
        "E66E" : "ņ",
        "E672" : "ŗ",
        "E674" : "ţ",
        "E720" : "ˇ",
        "E741" : "Ă",
        "E744" : "Ď",
        "E745" : "Ě",
        "E747" : "Ǧ",
        "E748" : "Ȟ",
        "E749" : "Ǐ",
        "E74B" : "Ǩ",
        "E74E" : "Ň",
        "E74F" : "Ǒ",
        "E752" : "Ř",
        "E754" : "Ť",
        "E755" : "Ǔ",
        "E761" : "ă",
        "E765" : "ě",
        "E768" : "ȟ",
        "E769" : "ǐ",
        "E76A" : "ǰ",
        "E76B" : "ǩ",
        "E76E" : "ň",
        "E76F" : "ǒ",
        "E772" : "ř",
        "E775" : "ǔ",
        "E820" : "˙",
        "E841" : "Ȧ",
        "E842" : "Ḃ",
        "E843" : "Ċ",
        "E844" : "Ḋ",
        "E845" : "Ė",
        "E846" : "Ḟ",
        "E847" : "Ġ",
        "E848" : "Ḣ",
        "E84D" : "Ṁ",
        "E84E" : "Ṅ",
        "E84F" : "Ȯ",
        "E850" : "Ṗ",
        "E852" : "Ṙ",
        "E853" : "Ṡ",
        "E854" : "Ṫ",
        "E857" : "Ẇ",
        "E858" : "Ẋ",
        "E859" : "Ẏ",
        "E861" : "ȧ",
        "E862" : "ḃ",
        "E863" : "ċ",
        "E864" : "ḋ",
        "E865" : "ė",
        "E866" : "ḟ",
        "E867" : "ġ",
        "E868" : "ḣ",
        "E86D" : "ṁ",
        "E86E" : "ṅ",
        "E86F" : "ȯ",
        "E870" : "ṗ",
        "E872" : "ṙ",
        "E873" : "ṡ",
        "E874" : "ṫ",
        "E877" : "ẇ",
        "E878" : "ẋ",
        "E879" : "ẏ",
        "E920" : "¯",
        "E941" : "Ā",
        "E945" : "Ē",
        "E947" : "Ḡ",
        "E949" : "Ī",
        "E94F" : "Ō",
        "E955" : "Ū",
        "E959" : "Ȳ",
        "E95C" : "Ǣ",
        "E961" : "ā",
        "E965" : "ē",
        "E967" : "ḡ",
        "E969" : "ī",
        "E96F" : "ō",
        "E975" : "ū",
        "E979" : "ȳ",
        "E97C" : "ǣ",
        "EA20" : "ˇ",
        "EA41" : "Ă",
        "EA45" : "Ě",
        "EA49" : "Ǐ",
        "EA4F" : "Ǒ",
        "EA61" : "ă",
        "EA65" : "ě",
        "EA69" : "ǐ",
        "EA6F" : "ǒ",
        "EB20" : "˛",
        "EB49" : "Į",
        "EB4F" : "Ǫ",
        "EB55" : "Ų",
        "EB69" : "į",
        "EB6F" : "ǫ",
        "EB75" : "ų",
        "EC20" : "˝",
        "EC4F" : "Ő",
        "EC55" : "Ű",
        "EC6F" : "ő",
        "EC75" : "ű",
    }
}
