const EventGroup = require("../classes/eventGroup.js");
const decoder = require("../profiles/index.js");
const defaults = require("../lib/defaults.js");
const applyWindowOffsets = require("./special/applyWindowOffsets.js");
module.exports = function(input, options = defaults) {
    let eventGroup = new EventGroup();
    if (decoder[options.profile]){
        input = decoder[options.profile].preProcess.decode(input, options);
        eventGroup.events = decoder[options.profile].decode(input, options);
        eventGroup = decoder[options.profile].postProcess.decode(eventGroup, options);
        return applyWindowOffsets(eventGroup, options.window, "subtract");
    } else {
        throw new Error(`Source profile [${options.profile}] does not exist. Please ensure name matches a supported decoding profile. Profile names are case-sensitive.`);
    }
    
}
