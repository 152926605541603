const sccLib = require('../../profiles/scenerist.js');
const charSet = require('../../dict/608.js');
module.exports = function sccDecode(input) {
    let output = sccLib.preProcess.decode(input);
    let fileLines = output.split("\n");
    let decodedContent = "";

    fileLines.forEach(fileLine => {
        if (fileLine.split("\t")[0].match(/^\d\d:\d\d:\d\d;\d\d$|^\d\d:\d\d:\d\d:\d\d$/g) != null) {
            decodedContent += ("\n\n" + fileLine.split("\t")[0] + "\n");
            let ancData = fileLine.split("\t")[1].split(" ");
            let ancDataBuffer = "";
            for (let i = 0; i < ancData.length; i++) {
                if (charSet.all[ancData[i]] != undefined) {
                    ancDataBuffer += charSet.all[ancData[i]];
                } else {
                    let sccCodes = [ancData[i].substring(0, 2), ancData[i].substring(2, 4)];
                    sccCodes.forEach(sccCode => {
                        if (charSet.all[sccCode] != undefined) {
                            ancDataBuffer += charSet.all[sccCode];
                        }
                    });
                }
            }
            decodedContent += (ancDataBuffer);
        } else {
            decodedContent += (fileLine);
        }
    });

    return decodedContent;
}